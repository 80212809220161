import React from 'react';
import { Button, Form, message, Modal, Radio, Result, Space, Typography } from 'antd';
import axios from '../../../request/axios';

class ResetPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      form_submitting: false,
      reset_finish: false,
      password_type: null,
      new_password: null,
      visible: false
    }
  }

  openModal = (user) => {
    this.setState({
      user: user,
      form_submitting: false,
      reset_finish: false,
      password_type: null,
      new_password: null,
      visible: true
    });
  }

  onFinish = (value) => {
    this.setState({form_submitting: true});
    value.id = this.state.user.id;
    axios.post(`/customer-users/reset-password`, value).then((res) => {
      let {code, data} = res.data;
      this.setState({
        reset_finish: code === 0,
        password_type: data ? data.password_type : '',
        new_password: data ? data.new_password : ''
      });
    }).finally(() => {
      this.setState({
        form_submitting: false
      });
    });
  };

  copyPassword = (value) => {
    let oInput = document.createElement('input');
    oInput.value = value;
    document.body.appendChild(oInput);
    oInput.select();
    document.execCommand('Copy');
    message.success('复制成功');
    oInput.remove()
  };

  closeModal = () => {
    this.setState({visible: false});
  }

  render() {
    if (!this.state.user)
      return <></>
    return <>
      <Modal
        title='重置密码'
        
        destroyOnClose
        visible={this.state.visible}
        footer={null}
        onCancel={this.closeModal}
        width={400}
        keyboard
      >
        {this.state.reset_finish
          ? <Result
            status='success'
            title='重置成功'
            subTitle={
              <div>已成功将用户“{this.state.user.username}”的{this.state.password_type === 'login_password' ? '登录密码' : '交易密码'}重置为<Typography.Text
                mark code>{this.state.new_password}</Typography.Text>，请妥善保管。</div>}
            extra={[
              <Button type='primary' onClick={this.copyPassword.bind(this, this.state.new_password)}>
                复制密码
              </Button>,
              <Button onClick={this.closeModal}>关闭</Button>
            ]}
          />
          : <Form ref={(form) => {
            this.formRef = form;
          }} onFinish={this.onFinish} labelCol={{span: 6}} wrapperCol={{span: 18}}>
            <Form.Item
              label='用户名'
            >
              {this.state.user.username}
            </Form.Item>
            <Form.Item
              label='密码类型'
              name='password_type'
              rules={[
                {required: true}
              ]}
            >
              <Radio.Group>
                <Radio value='login_password'>登录密码</Radio>
                <Radio value='deal_password'>交易密码</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 12
              }}
            >
              <Space>
                <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                  确认重置
                </Button>
                <Button onClick={this.closeModal.bind(this)}>
                  取消
                </Button>
              </Space>
            </Form.Item>
          </Form>
        }
      </Modal>
    </>
  }
}

export default ResetPasswordModal;