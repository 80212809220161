import React from 'react';
import {Button, Card, message as antMessage, Modal, Space} from 'antd';
import axios from '../../request/axios';
import YouyuUtils from '../../utils/YouyuUtils';
import EditTianmaoShopModal from './EditTianmaoShopModal';
import EditJingdongShopModal from './EditJingdongShopModal';
import shopLogo from './components/ShopLogo';

class CreateShopModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_taobao_auth_url: false,
      visible: false
    }
  }

  openModal = () => {
    this.setState({
      visible: true
    });
  }

  closeModal = () => {
    this.setState({id: null, visible: false});
  }

  handleAddTaobaoShop = () => {
    const self = this;
    Modal.confirm({
      title: '需要前往淘宝授权',
      ...YouyuUtils.confirmModalDefaultProps,
      okText: '前往授权',
      okButtonProps: {loading: this.state.loading_taobao_auth_url},
      onOk() {
        self.setState({loading_taobao_auth_url: true});
        axios.get('customer-shops/taobao-auth-url').then(res => {
          const {code, message, data} = res.data;
          if (!code) {
            window.open(data.url, '_blank');
            self.showAuthFinishModal();
          } else {
            antMessage.error(message);
          }
        }).finally(() => {
          self.setState({loading_taobao_auth_url: false});
        });
      }
    });
  }

  showAuthFinishModal = () => {
    const self = this;
    Modal.confirm({
      title: '是否已经授权？',
      ...YouyuUtils.confirmModalDefaultProps,
      okText: '是的',
      cancelText: '没有',
      onOk() {
        self.props.onChange?.();
        self.closeModal();
      }
    });
  }

  render() {
    const footer = <Space>
      <Button onClick={this.closeModal}>关闭</Button>
    </Space>

    const cardClass = 'cursor-pointer text-center';
    const cardStyle = {padding: 10};
    const imgClass = 'w-20';
    const titleClass = 'mt-1';

    return <>
      <Modal
        title='选择店铺类型'
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={600}
        keyboard
      >
        <div className='text-center'>
          <Space align='center' size={15}>
            <Card hoverable className={cardClass} bodyStyle={cardStyle} onClick={this.handleAddTaobaoShop}>
              <img className={imgClass} src={shopLogo.taobao} alt='淘宝'/>
              <div className={titleClass}>淘宝网</div>
            </Card>
            <Card hoverable className={cardClass} bodyStyle={cardStyle}>
              <img className={imgClass} src={shopLogo.pinduoduo} alt='拼多多'/>
              <div className={titleClass}>拼多多</div>
            </Card>
            <Card hoverable className={cardClass} bodyStyle={cardStyle}>
              <img className={imgClass} src={shopLogo.tianmao} alt='天猫'
                   onClick={() => this.editTianmaoShopModal.openModal()}/>
              <div className={titleClass}>天猫</div>
            </Card>
            <Card hoverable className={cardClass} bodyStyle={cardStyle}>
              <img className={imgClass} src={shopLogo.jingdong} alt='京东'
                   onClick={() => this.editJingdongShopModal.openModal()}/>
              <div className={titleClass}>京东</div>
            </Card>
          </Space>
        </div>
        <EditTianmaoShopModal ref={ref => this.editTianmaoShopModal = ref} onChange={() => {
          this.props.onChange?.();
          this.closeModal();
        }}/>
        <EditJingdongShopModal ref={ref => this.editJingdongShopModal = ref} onChange={() => {
          this.props.onChange?.();
          this.closeModal();
        }}/>
      </Modal>
    </>
  }
}

export default CreateShopModal;