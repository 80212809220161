import React from 'react';
import axios from '../request/axios';
import { Button, Cascader, Divider, Form, Input, Modal, Pagination, Select, Space, Table, Typography } from 'antd';
import ProductTypeTag from './ProductTypeTag';
import Money from './Money';
import ModelConstants from '../constants/ModelConstants';

const {Link} = Typography;

class ProductSelectorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value_display: [],
      products: [],
      loading: false,
      pagination: {page: 1, page_size: 15},
      visible: false
    };
  }

  onChange = (value) => {
    this.setState({cascader_value: value});
    this.props.onChange?.(value[1]);
  }

  openModal = () => {
    this.setState({visible: true});
    this.loadData();
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  handleInputClear = (e) => {
    if (!e.target.value) this.loadData();
  }

  handlePaginationChange = (page, page_size) => {
    this.setState({
      pagination: {
        page: page,
        page_size: page_size
      }
    }, this.loadData.bind(this, false));
  }

  handleSelect = (product) => {
    this.props.onSelect?.(product);
    this.closeModal();
  }

  loadData = (resetPagination = true) => {
    let {pagination} = this.state;
    if (resetPagination) {
      pagination.page = 1;
      this.setState({pagination});
    }
    this.setState({
      loading: true
    });
    let query_params = {...this.formRef.getFieldsValue(true), ...pagination, ...this.props.search_params};
    axios.get('ui-assists/query-products', {params: query_params}).then((res) => {
      const {data} = res.data;
      this.setState({
        loading: false,
        products: data.data,
        total: data.total,
        selected_row_keys: []
      });
    });
  };

  render() {
    const operations = <div className='text-right'>
      <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                  onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                  total={this.state.total} pageSizeOptions={[15, 25, 50, 100]}/>
    </div>;

    return <Modal
      title='选择商品'
      footer={null}
      visible={this.state.visible}
      onCancel={this.closeModal}
      forceRender
      width={1000}
      keyboard
      zIndex={1002}
    >
      <div className='query-form-container' style={{padding: '20px 0 4px 24px'}}>
        <Form className='query-form' ref={(form) => {
          this.formRef = form;
        }} onFinish={this.loadData}>
          <div className='query-form__inputs_container'>
            <Form.Item label='商品查询' name='key'>
              <Input placeholder='商品编号/商品名称' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                     onChange={this.handleInputClear}/>
            </Form.Item>
            <Button type='primary' htmlType='submit'>筛选</Button>
          </div>
        </Form>
      </div>
      <Table className='mt-4' rowKey='id' dataSource={this.state.products} loading={this.state.loading}
             size='small' pagination={false} scroll={{scrollToFirstRowOnChange: true}} rowClassName='cursor-pointer'
             onRow={record => {
               return {
                 onClick: event => {this.handleSelect(record)},
               };
             }}
      >
        <Table.Column title='商品' dataIndex='name' key='name' style={{minHeight: 250}} render={(value, record) =>
          <>
            <div><ProductTypeTag value={record.type}/>&nbsp;
              {value}（{record.id}）
            </div>
          </>
        }/>
        <Table.Column title='商品面值' dataIndex='face_value' key='face_value' align='right' width={120}
                      render={(value, record) => <Money value={value}/>}/>
        <Table.Column title='进货价格' dataIndex='price' key='price' align='right' width={120}
                      render={(value, record) => <Money value={value}/>}/>
        <Table.Column title='操作' dataIndex='action' key='action' align='right' width={100} render={(value, record) =>
          <>
            <Link onClick={() => this.handleSelect(record)}>选择</Link>
          </>
        }/>
      </Table>
      <div className='mt-4'>{operations}</div>
    </Modal>
  }
}

export default ProductSelectorModal;