import React from 'react';
import { Card, Checkbox, Space, Typography } from 'antd';
import axios from '../../../request/axios';
import Text from 'antd/lib/typography/Text';

class CustomerUserPermissionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      checked: [],
      all_permission_code: []
    };
  }

  componentDidMount() {
    axios.get('customer-users/permissions/all').then((res) => {
      const {data} = res.data;
      this.setState({
        permissions: data
      });

      let all_permission_code = [];
      this.state.permissions.forEach((cat) => {
        cat.permissions.forEach((p) => {
          all_permission_code.push(p.code)
        });
      });
      this.setState({
        all_permission_code: all_permission_code
      });
    })
  }

  componentWillReceiveProps(props) {
    this.setState({
      checked: props.permissions
    });
  }

  onChange(checkedValues) {
    this.props.onChange?.(checkedValues);
  }

  selectAll() {
    this.onChange(this.state.all_permission_code);
  }

  selectInverse() {
    let codes = [];
    this.state.all_permission_code.forEach((code) => {
      if(this.state.checked.indexOf(code) === -1) {
        codes.push(code);
      }
    });
    this.onChange(codes);
  }

  render() {
    return (
      <Checkbox.Group value={this.state.checked} onChange={this.onChange.bind(this)}>
        {this.state.permissions.map((cat) => {
          return <Card key={cat.code} type='inner' size='small' title={cat.name} style={{marginTop: 8}}>
            {
              cat.permissions.map((p) => {
                return <Checkbox key={p.code} value={p.code}
                                 style={{marginLeft: 8, marginTop: 4, minWidth: 110}}>
                  {p.risk_level === '2'
                    ? <Text type='danger' strong>{p.name}</Text>
                    : <Text>{p.name}</Text>}
                </Checkbox>;
              })
            }
          </Card>
        })}
        <div style={{margin: "8px 0 0 4px"}}>
          <Space>
            <Typography.Link onClick={this.selectAll.bind(this)}>全选</Typography.Link>
            <Typography.Link onClick={this.selectInverse.bind(this)}>反选</Typography.Link>
          </Space>
        </div>
      </Checkbox.Group>
    );
  }
}

export default CustomerUserPermissionSelector;