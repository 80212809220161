import {
  Button,
  Card,
  Form,
  message,
  Space,
} from 'antd';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';

class ExportTodayCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_export: false,
    };
  }

  export = () => {
    this.setState({loading_export: true});
    axios.get('/private/export/cards', {
      responseType: 'blob' // 设置响应类型为blob
    }).then((res) => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const file_name = `卡密${year}-${month}-${day}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    }).finally(() => {
      this.setState({
        loading_export: false
      });
    });
  }

  render() {
    return (
      <Card title='导出当天卡密'>
        <div className='query-form-container'>
          <Form className='query-form' ref={ref => this.formRef = ref}>
            <Space>
              <Button onClick={this.export} loading={this.state.loading_export}>导出当天卡密</Button>
            </Space>
          </Form>
        </div>
      </Card>
    );
  }
}

export default ExportTodayCards;
