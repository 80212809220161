import ModelConstants from '../../../constants/ModelConstants';

let orderStatusStyle = {
  statusWrapperStyle: (status) => {
    let statusWrapperStyle = {};
    if (status === ModelConstants.ORDER_STATUS.DEALING) {
      statusWrapperStyle.backgroundColor = '#e6f7ff';
    } else if (status === ModelConstants.ORDER_STATUS.SUCCESS) {
      statusWrapperStyle.backgroundColor = '#f6ffed';
    } else if (status === ModelConstants.ORDER_STATUS.FAIL) {
      statusWrapperStyle.backgroundColor = '#fff1f0';
    } else if (status === ModelConstants.ORDER_STATUS.UNCONFIRMED) {
      statusWrapperStyle.backgroundColor = '#f9f0ff';
    }
    return statusWrapperStyle;
  },

  statusTitleStyle: (status) => {
    let statusTitleStyle = {
      fontWeight: 'bold',
      margin: 0
    };
    if (status === ModelConstants.ORDER_STATUS.DEALING) {
      statusTitleStyle.color = '#096dd9';
    } else if (status === ModelConstants.ORDER_STATUS.SUCCESS) {
      statusTitleStyle.color = '#389e0d';
    } else if (status === ModelConstants.ORDER_STATUS.FAIL) {
      statusTitleStyle.color = '#cf1322';
    } else if (status === ModelConstants.ORDER_STATUS.UNCONFIRMED) {
      statusTitleStyle.color = '#531dab';
    }
    return statusTitleStyle;
  }
}

export default orderStatusStyle;