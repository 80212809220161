import React from 'react';
import { Button, Checkbox, Divider, Form, Input, message as antMessage, Modal, Tabs, Typography } from 'antd';
import styles from './AuthLayout.module.less';
import axios from '../../request/axios';
import { withRouter } from 'react-router-dom';
import AgreementModal from './AgreementModal';

const {Text, Link} = Typography;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_method: 'password',
      form_submitting: false,
      sending_sms_code: false,
      accept_agreement: false
    }
  }

  onTabChange = (key) => {
    this.setState({login_method: key});
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    axios.post('auth/register', values).then((res) => {
      const {code, message, data} = res.data;
      if (!code) {
        let modal_content;
        if (window.operation_settings.message_after_registered && window.operation_settings.message_after_registered !== '') {
          modal_content = window.operation_settings.message_after_registered;
        } else {
          modal_content = '恭喜您注册成功，现在可以登录了';
        }
        Modal.success({
          content: modal_content,
          onOk: () => {
            this.props.history.push('/login');
          }
        });
      } else {
        antMessage.error(message);
      }
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  }

  render() {
    return <div className={[styles.contentBox, styles.registerContentBox].join(' ')}>
      <div className={styles.formHeader}>注册</div>
      <div className={styles.formWrapper}>
        <Form className='auth-form' ref={ref => this.formRef = ref} layout='vertical'
              onFinish={this.onFinish} loading={this.state.form_submitting} requiredMark={false}>
          <Form.Item
            name='name'
            label='商户名称'
            rules={[{required: true}, {min: 3, validateTrigger: 'onSubmit'}]}
          >
            <Input size='large' className={styles.input} placeholder='请输入商户名称'/>
          </Form.Item>

          <Form.Item
            name='username'
            label='用户名'
            rules={[{required: true}, {min: 5, validateTrigger: 'onSubmit'}]}
          >
            <Input size='large' className={styles.input} placeholder='请输入用户名'/>

          </Form.Item>

          <Form.Item
            name='login_password'
            label='登录密码'
            rules={[{required: true}, {min: 8, validateTrigger: 'onSubmit'}]}
          >
            <Input.Password size='large' className={styles.input} placeholder='请输入登录密码'/>
          </Form.Item>

          <Form.Item
            name='deal_password'
            label='交易密码'
            rules={[{required: true}, {min: 8, validateTrigger: 'onSubmit'}]}
          >
            <Input.Password size='large' className={styles.input} placeholder='请输入交易密码'/>
          </Form.Item>

          <Form.Item style={{marginBottom: 4}}>
            <Checkbox checked={this.state.accept_agreement}
                      onClick={() => antMessage.warn('请您阅读《销售系统用户注册协议》')}>我已阅读并同意</Checkbox>
            <Link style={{verticalAlign: 'middle', marginLeft: -10}}
                  onClick={() => this.agreementModal.openModal()}>《销售系统用户注册协议》</Link>
          </Form.Item>

          <Form.Item style={{marginBottom: 8}}>
            <Button htmlType='submit' type='primary' size='large' block disabled={!this.state.accept_agreement}
                    loading={this.state.form_submitting}>立即注册</Button>
          </Form.Item>
          <div className='flex justify-end'>
            <Link type='secondary' onClick={() => this.props.history.push('/')}>已有账号，直接登录></Link>
          </div>
        </Form>
      </div>
      <AgreementModal ref={ref => this.agreementModal = ref}
                      onAccept={() => this.setState({accept_agreement: true})}
                      onNotAccept={() => this.setState({accept_agreement: false})}/>
    </div>
  }
}

export default withRouter(Register);