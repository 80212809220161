import { Typography } from 'antd';

const {Text} = Typography;

const Money = (props) => {
  let {value} = props;
  if (value === null || value === undefined) {
    return <span><Text type='secondary'>-</Text></span>;
  }

  value = Number.parseFloat(props.value);

  if (isNaN(value)) {
    return <span><Text type='secondary'>-</Text></span>;
  }

  value = parseFloat(value.toFixed(4));

  value = value.toString();

  const pointIndex = value.indexOf('.');
  if (pointIndex === -1)
    value = value + '.00';
  else if ((value.length - pointIndex) === 2)
    value = value + '0';

  const tmp_arr = value.split('.');
  let intPart = tmp_arr[0];
  let decimalPart = tmp_arr[1];

  let clz = 'font-bold';
  if (value >= 0)
    clz += ' text-money ';
  else {
    clz += ' text-negative-money';
  }

  if (props.signed && intPart > 0) {
    intPart = '+' + intPart;
  }

  return <span className={clz + ' ' + props.className} style={props.style}>
    {props.prefix}
    {intPart}
    {
      !props.remove_float &&
      <span>
        .
        {
          props.small_decimal
            ? <span style={{fontSize: '0.85em'}}>{decimalPart}</span>
            : decimalPart
        }
      </span>
    }
    {props.postfix}
  </span>
}

Money.defaultProps =
  {
    remove_float: false
  }

export default Money;