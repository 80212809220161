import { Button, Form, Input, InputNumber, message, Row, Space, Spin, Typography } from 'antd';
import React from 'react';
import axios from '../../request/axios';

const {Text} = Typography;

class TianmaoShopSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_loading: false,
      form_submitting: false,
      tianmao_buyer_purchase_frequency_minutes: null,
      tianmao_buyer_purchase_frequency_times: null,
      tianmao_buyer_purchase_frequency_total_money: null,
      tianmao_charge_account_limit_money_start: null,
      tianmao_charge_account_limit_money_end: null,
      tianmao_charge_account_limit_minutes: null,
      tianmao_charge_account_limit_buyer_count: null,
      tianmao_buyer_limit_money_start: null,
      tianmao_buyer_limit_money_end: null,
      tianmao_buyer_limit_minutes: null,
      tianmao_buyer_limit_charge_account_count: null,
      tianmao_charge_account_purchase_frequency_minutes: null,
      tianmao_charge_account_purchase_frequency_times: null,
      tianmao_charge_account_purchase_frequency_total_money: null,
    };
  }

  componentDidMount() {
    this.setState({form_loading: true});
    axios.get('customer-shop-settings').then(res => {
      const {data} = res.data;
      this.setState({
        tianmao_buyer_purchase_frequency_minutes: data.tianmao_buyer_purchase_frequency_minutes,
        tianmao_buyer_purchase_frequency_times: data.tianmao_buyer_purchase_frequency_times,
        tianmao_buyer_purchase_frequency_total_money:data.tianmao_buyer_purchase_frequency_total_money,
        tianmao_charge_account_limit_money_start: data.tianmao_charge_account_limit_money_start,
        tianmao_charge_account_limit_money_end: data.tianmao_charge_account_limit_money_end,
        tianmao_charge_account_limit_minutes: data.tianmao_charge_account_limit_minutes,
        tianmao_charge_account_limit_buyer_count: data.tianmao_charge_account_limit_buyer_count,
        tianmao_buyer_limit_money_start: data.tianmao_buyer_limit_money_start,
        tianmao_buyer_limit_money_end: data.tianmao_buyer_limit_money_end,
        tianmao_buyer_limit_minutes: data.tianmao_buyer_limit_minutes,
        tianmao_buyer_limit_charge_account_count: data.tianmao_buyer_limit_charge_account_count,
        tianmao_charge_account_purchase_frequency_minutes: data.tianmao_charge_account_purchase_frequency_minutes,
        tianmao_charge_account_purchase_frequency_times: data.tianmao_charge_account_purchase_frequency_times,
        tianmao_charge_account_purchase_frequency_total_money: data.tianmao_charge_account_purchase_frequency_total_money
      });

      data.tianmao_buyer_nick_black_list = data.tianmao_buyer_nick_black_list?.join('\n');
      data.tianmao_buyer_nick_white_list = data.tianmao_buyer_nick_white_list?.join('\n');
      data.tianmao_tsc_white_list = data.tianmao_tsc_white_list?.join('\n');
      data.tianmao_order_end_black_list = data.tianmao_order_end_black_list?.join('\n');
      data.tianmao_buyer_ip_black_list = data.tianmao_buyer_ip_black_list?.join('\n');
      data.tianmao_charge_account_black_list = data.tianmao_charge_account_black_list?.join('\n');
      data.tianmao_charge_account_white_list = data.tianmao_charge_account_white_list?.join('\n');

      this.formRef && this.formRef.setFieldsValue(data);
    }).finally(() => {
      this.setState({form_loading: false});
    });
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});

    values.tianmao_buyer_purchase_frequency_minutes = this.state.tianmao_buyer_purchase_frequency_minutes;
    values.tianmao_buyer_purchase_frequency_times = this.state.tianmao_buyer_purchase_frequency_times;
    values.tianmao_buyer_purchase_frequency_total_money = this.state.tianmao_buyer_purchase_frequency_total_money;

    values.tianmao_charge_account_limit_money_start = this.state.tianmao_charge_account_limit_money_start;
    values.tianmao_charge_account_limit_money_end = this.state.tianmao_charge_account_limit_money_end;
    values.tianmao_charge_account_limit_minutes = this.state.tianmao_charge_account_limit_minutes;
    values.tianmao_charge_account_limit_buyer_count = this.state.tianmao_charge_account_limit_buyer_count;

    values.tianmao_buyer_limit_money_start = this.state.tianmao_buyer_limit_money_start;
    values.tianmao_buyer_limit_money_end = this.state.tianmao_buyer_limit_money_end;
    values.tianmao_buyer_limit_minutes = this.state.tianmao_buyer_limit_minutes;
    values.tianmao_buyer_limit_charge_account_count = this.state.tianmao_buyer_limit_charge_account_count;

    values.tianmao_charge_account_purchase_frequency_minutes = this.state.tianmao_charge_account_purchase_frequency_minutes;
    values.tianmao_charge_account_purchase_frequency_times = this.state.tianmao_charge_account_purchase_frequency_times;
    values.tianmao_charge_account_purchase_frequency_total_money = this.state.tianmao_charge_account_purchase_frequency_total_money;

    axios.post('customer-shop-settings/tianmao', values).then(() => {
      message.success('保存成功');
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  };

  render() {
    return (
      <Spin spinning={this.state.form_loading}>
        <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>

          <Form.Item
              label='买家交易频率'
              extra='对同一买家的购买频率限制，如不需要可不填'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <Text>同一买家每</Text> &nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_buyer_purchase_frequency_minutes}
                             onChange={value => this.setState({tianmao_buyer_purchase_frequency_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多购买</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_buyer_purchase_frequency_times}
                             onChange={value => this.setState({tianmao_buyer_purchase_frequency_times: value})}/>&nbsp;&nbsp;
                <Text>笔订单，最多购买合计</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 100}} value={this.state.tianmao_buyer_purchase_frequency_total_money}
                             onChange={value => this.setState({tianmao_buyer_purchase_frequency_total_money: value})}/>&nbsp;&nbsp;
                <Text>金额订单</Text>
              </Row>
            </Input.Group>
          </Form.Item>

          <Form.Item
              label='充值账号交易频率'
              extra='对同一充值账号的购买频率限制，如不需要可不填'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <Text>同一充值账号每</Text> &nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_charge_account_purchase_frequency_minutes}
                             onChange={value => this.setState({tianmao_charge_account_purchase_frequency_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多购买</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_charge_account_purchase_frequency_times}
                             onChange={value => this.setState({tianmao_charge_account_purchase_frequency_times: value})}/>&nbsp;&nbsp;
                <Text>笔订单，最多购买合计</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 100}} value={this.state.tianmao_charge_account_purchase_frequency_total_money}
                             onChange={value => this.setState({tianmao_charge_account_purchase_frequency_total_money: value})}/>&nbsp;&nbsp;
                <Text>金额订单</Text>
              </Row>
            </Input.Group>
          </Form.Item>

          <Form.Item
              label='充值账号限制'
              extra='对同一充值账号，下单买家的数量限制，如不需要可不填'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <Text>同一充值账号交易金额为</Text> &nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_charge_account_limit_money_start}
                             onChange={value => this.setState({tianmao_charge_account_limit_money_start: value})}/>-
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_charge_account_limit_money_end}
                             onChange={value => this.setState({tianmao_charge_account_limit_money_end: value})}/>&nbsp;&nbsp;
                <Text>范围内，</Text>
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_charge_account_limit_minutes}
                             onChange={value => this.setState({tianmao_charge_account_limit_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多支持</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_charge_account_limit_buyer_count}
                             onChange={value => this.setState({tianmao_charge_account_limit_buyer_count: value})}/>&nbsp;&nbsp;
                <Text>个买家下单</Text>
              </Row>
            </Input.Group>
          </Form.Item>

          <Form.Item
              label='买家限制'
              extra='对同一买家，下单充值账号的数量限制，如不需要可不填'
          >
            <Input.Group>
              <Row gutter={8} align='middle'>
                <Text>同一买家交易金额为</Text> &nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_buyer_limit_money_start}
                             onChange={value => this.setState({tianmao_buyer_limit_money_start: value})}/>-
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_buyer_limit_money_end}
                             onChange={value => this.setState({tianmao_buyer_limit_money_end: value})}/>&nbsp;&nbsp;
                <Text>范围内，</Text>
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_buyer_limit_minutes}
                             onChange={value => this.setState({tianmao_buyer_limit_minutes: value})}/>&nbsp;&nbsp;
                <Text>分钟内，最多支持</Text>&nbsp;&nbsp;
                <InputNumber min={1} style={{width: 70}} value={this.state.tianmao_buyer_limit_charge_account_count}
                             onChange={value => this.setState({tianmao_buyer_limit_charge_account_count: value})}/>&nbsp;&nbsp;
                <Text>个充值账号下单</Text>
              </Row>
            </Input.Group>
          </Form.Item>
          <Form.Item
              name='tianmao_buyer_nick_white_list'
              label='买家ID白名单'
              extra='一行一个买家ID，处于白名单的买家，不受上面交易频率和限制的影响'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>
          <Form.Item
              name='tianmao_tsc_white_list'
              label='天猫TSC白名单'
              extra='一行一个天猫TSC，处于白名单的天猫TSC，不受上面交易频率和限制的影响'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>
          <Form.Item
            name='tianmao_buyer_nick_black_list'
            label='买家ID黑名单'
            extra='一行一个买家ID，处于黑名单的买家，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='tianmao_order_end_black_list'
            label='天猫订单结尾黑名单'
            extra='一行一个天猫订单结尾(6位)，处于黑名单的订单结尾，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='tianmao_buyer_ip_black_list'
            label='买家IP黑名单'
            extra='一行一个IP，处于黑名单的买家，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='tianmao_charge_account_black_list'
            label='充值账号黑名单'
            extra='一行一个充值账号，处于黑名单的充值账号，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
              name='tianmao_charge_account_white_list'
              label='充值账号白名单'
              extra='一行一个充值账号，如果存在白名单，且充值账号不在白名单内，订单失败处理，白名单为空所有账号都可以充值'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 10
            }}
          >
            <Space>
              <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                保存
              </Button>
              <Button onClick={this.handleReturn}>
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

export default TianmaoShopSettings;