import taobao from '../../../asserts/imgs/shop-logo/taobao.png';
import pinduoduo from '../../../asserts/imgs/shop-logo/pinduoduo.webp';
import tianmao from '../../../asserts/imgs/shop-logo/tianmao.webp';
import jingdong from '../../../asserts/imgs/shop-logo/jingdong.webp';

const ShopLogo = {
  taobao: taobao,
  pinduoduo: pinduoduo,
  tianmao: tianmao,
  jingdong: jingdong
};

export default ShopLogo;