import React from 'react';
import { Button, Checkbox, Form, Input, message as antMessage, Modal, Tabs, Typography } from 'antd';
import styles from './AuthLayout.module.less';
import axios from '../../request/axios';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import userAuth from '../../auth/UserAuth';

const {Text, Link} = Typography;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_method: 'password',
      form_submitting: false,
      sending_sms_code: false
    }
  }

  onTabChange = (key) => {
    this.setState({login_method: key});
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    const url = this.state.login_method === 'password' ? 'auth/login/password' : 'auth/login/sms';
    axios.post(url, values).then((res) => {
      const {code, message, data} = res.data;
      if (!code) {
        antMessage.success('登录成功');
        userAuth.saveAuth(data.user, data.token);
        this.props.history.push('/dashboard');
      } else {
        antMessage.error(message);
      }
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  }

  sendSms = () => {
    const phoneNumber = this.formRef.getFieldValue('phone_number');
    this.formRef.validateFields(['phone_number']).then(() => {
      this.setState({sending_sms_code: true});
      axios.post('auth/login/send-sms-code', {phone_number: phoneNumber}).then((res) => {
        const {code, message, data} = res.data;
        antMessage.error(message);
      }).finally(() => {
        this.setState({sending_sms_code: false});
      });
    })
  }

  render() {
    const formCommonPart = <>
      <Form.Item style={{marginBottom: 12}}>
        <Button htmlType='submit' type='primary' size='large' block loading={this.state.form_submitting}>登录</Button>
      </Form.Item>
      <div className='flex justify-between'>
        <Link type='secondary' onClick={() => Modal.info({content: '请联系客服，经核实身份后为您重置密码'})}>忘记密码?</Link>
        <Link type='secondary' onClick={() => this.props.history.push('/register')}>立即注册</Link>
      </div>
    </>

    return <div className={[styles.contentBox, styles.loginContentBox].join(' ')}>
      <Tabs className='login-tabs' centered tabBarGutter={65} key={this.state.tab_key}
            onChange={this.onTabChange}>
        <Tabs.TabPane tab='密码登录' key='password'>
          <div className={styles.formWrapper}>
            <Form className='auth-form' ref={ref => this.formRef = ref} layout='vertical'
                  onFinish={this.onFinish} loading={this.state.form_submitting} requiredMark={false}>
              <Form.Item
                name='username'
                label='用户名'
                rules={[{required: true}]}
              >
                <Input className={styles.input} placeholder='请输入用户名'/>
              </Form.Item>

              <Form.Item
                name='password'
                label='密码'
                rules={[{required: true}]}
              >
                <Input.Password className={styles.input} placeholder='请输入密码'/>
              </Form.Item>

              <Form.Item
                name='code'
                label='动态码'
                style={{marginBottom: 24}}
              >
                <Input className={styles.input} placeholder='未绑定可不填'/>
              </Form.Item>

              {formCommonPart}
            </Form>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab='短信登录' key='sms'>
          <div className={styles.formWrapper}>
            <Form className='auth-form' ref={ref => this.formRef = ref} layout='vertical'
                  onFinish={this.onFinish} loading={this.state.form_submitting} requiredMark={false}>
              <Form.Item
                name='phone_number'
                label='手机号'
                rules={[{required: true}, {
                  validateTrigger: 'onSubmit',
                  pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, message: '请输入正确的手机号'
                }]}
              >
                <Input size='large' className={styles.input} placeholder='请输入手机号'/>
              </Form.Item>

              <Form.Item
                name='sms_code'
                label='验证码'
                rules={[{required: true}]}
              >
                <div className='flex justify-between items-center'>
                  <Input size='large' className={styles.input} style={{width: 170}} placeholder='请输入短信验证码'/>
                  <Button size='large' className={styles.sendSmsButton}
                    // loading={this.state.sending_sms_code}
                          onClick={this.sendSms}>
                    {
                      this.state.sending_sms_code ? <><LoadingOutlined/> 发送中</> : '获取验证码'
                    }
                  </Button>
                </div>
              </Form.Item>

              <Form.Item>
                <Checkbox><Text style={{fontSize: 15}} strong>记住账号</Text></Checkbox>
              </Form.Item>

              {formCommonPart}
            </Form>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  }
}

export default withRouter(Login);