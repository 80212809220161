import { Button, Modal } from 'antd';
import React from 'react';

class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  openModal = () => {
    this.setState({visible: true});
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  render() {
    return <Modal
      title={this.props.title}
      footer={<Button type='primary' onClick={this.closeModal}>知道了</Button>}
      visible={this.state.visible}
      onCancel={this.closeModal}
      bodyStyle={{minHeight: 80, maxHeight: 700, overflowY: 'auto'}}
    >
      <div style={{whiteSpace: 'pre-line'}}>{this.props.content}</div>
    </Modal>
  }
}

export default InfoModal;