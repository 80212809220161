import { Button, Card, Divider, Form, Input, Pagination, Select, Space, Table, Typography } from 'antd';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';
import OrderDetailsModal from '../order/OrderDetailsModal';
import DateRangePicker from '../../components/DateRangePicker';
import ModelConstants from '../../constants/ModelConstants';
import OrderComplaintStatusTag from '../../components/OrderComplaintStatusTag';
import OrderComplaintDetailsModal from './OrderComplaintDetailsModal';
import moment from 'moment';
import ApplyRefundDesc from './components/ApplyRefundDesc';

const {Paragraph, Text, Link} = Typography;

class OrderComplaints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      records: [],
      product_status: 0,
      pagination: {page: 1, page_size: 15},
      total: 0
    };
  }

  componentDidMount() {
    this.loadData();
  }

  handleInputClear = (e) => {
    if (!e.target.value) this.loadData();
  }

  handleTabChange = (key) => {
    this.setState({product_status: key}, this.loadData);
  }

  handlePaginationChange = (page, page_size) => {
    this.setState({
      pagination: {
        page: page,
        page_size: page_size
      }
    }, this.loadData);
  }

  loadData = () => {
    this.setState({
      loading: true
    });
    let values = this.formRef.getFieldsValue();
    let query_params = {...values, ...this.state.pagination};
    axios.get('order-complaints', {params: query_params}).then((res) => {
      const {data} = res.data;
      this.setState({
        loading: false,
        records: data.data,
        total: data.total
      });
    });
  };

  resetForm = () => {
    this.formRef && this.formRef.resetFields();
    this.loadData();
  }

  render() {
    // 表格操作
    const operations = <div className='text-right'>
      <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                  onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                  total={this.state.total} pageSizeOptions={[15, 25, 50, 100]}/>
    </div>;

    return (
      <Card title='售后记录'>
        <div className='query-form-container'>
          <Form className='query-form' ref={ref => this.formRef = ref} onFinish={this.loadData}
                initialValues={{date_range: DateRangePicker.today()}}>
            <div className='query-form__inputs_container'>
              <Form.Item label='订单编号' name='id'>
                <Input placeholder='订单编号' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleClearQueryField}/>
              </Form.Item>
              <Form.Item label='处理状态' name='status'>
                <Select placeholder='选择处理状态' style={{width: 160}} onChange={this.loadData} allowClear>
                  <Select.Option value={ModelConstants.ORDER_COMPLAINT_STATUS.UNHANDLED}>待受理</Select.Option>
                  <Select.Option value={ModelConstants.ORDER_COMPLAINT_STATUS.IN_HANDLE}>受理中</Select.Option>
                  <Select.Option value={ModelConstants.ORDER_COMPLAINT_STATUS.FINISH}>已处理</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className='query-form__operations'>
              <Space>
                <Button type='primary' htmlType='submit'>筛选</Button>
                <Link onClick={this.resetForm}>重置筛选条件</Link>
              </Space>
            </div>
          </Form>
        </div>
        <Table className='mt-4' rowKey='id' dataSource={this.state.records} loading={this.state.loading}
               pagination={false}>
          <Table.Column title='订单号' dataIndex='order_id' key='order_id' align='center'
                        render={(value) => <span className='select-all'>{value}</span>}/>
          <Table.Column title='售后原因' dataIndex='reason' key='reason' align='left'/>
          <Table.Column title='申请退款' dataIndex='apply_refund_type' key='apply_refund_type' align='center'
                        render={(value, record) => <ApplyRefundDesc apply_refund_type={value}
                                                                    apply_part_refund_amount={record.apply_part_refund_amount}/>
                        }/>
          <Table.Column title='投诉时间' dataIndex='created_at' key='created_at' align='center'
                        render={(value) => moment(value).format('Y-MM-DD hh:mm')}/>
          <Table.Column title='状态' dataIndex='status' key='status' align='center'
                        render={(value) => <OrderComplaintStatusTag value={value}/>}/>
          <Table.Column title='操作' dataIndex='action' key='action' align='right' width={200} render={(value, record) =>
            <>
              <Link onClick={() => this.orderDetailsModal.openModal(record.order_id)}>订单详情</Link>
              <Divider type='vertical'/>
              <Link onClick={() => this.orderComplaintDetailsModal.openModal(record.order_id)}>售后详情</Link>
            </>
          }/>
        </Table>
        <div className='mt-4'>{operations}</div>
        <OrderDetailsModal ref={ref => this.orderDetailsModal = ref}/>
        <OrderComplaintDetailsModal onChange={this.loadData} ref={ref => this.orderComplaintDetailsModal = ref}/>
      </Card>
    );
  }
}

export default OrderComplaints;