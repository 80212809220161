import { Card } from 'antd';
import React from 'react';
import UpdateLoginPassword from './UpdateLoginPassword';
import UpdateDealPassword from "./UpdateDealPassword";
import ApiKey from "./ApiKey";
import BindSecretCode from "./BindSecretCode";
import userAuth from '../../auth/UserAuth';
import ModelConstants from '../../constants/ModelConstants';
import SecurityRule from "./SecurityRule";

class CustomerSecurity extends React.Component {

  handleTabChange = key => {
    this.props.history.push('/security/' + key)
  };

  render() {
    let tabs = [
      {
        key: 'login_psw',
        tab: '修改登录密码'
      },
      {
        key: 'deal_psw',
        tab: '修改交易密码'
      },
    ];

    if(userAuth.user().type === ModelConstants.USER_TYPE.MAIN) {
      tabs.push({
        key: 'api_key',
        tab: '接口密钥'
      });
    }

    tabs.push({
      key: 'secret_code',
      tab: '绑定身份验证器'
    });

    if(userAuth.user().type === ModelConstants.USER_TYPE.MAIN) {
      tabs.push({
        key: 'security',
        tab: '安全设置'
      });
    }

    const contentList = {
      login_psw: <UpdateLoginPassword />,
      deal_psw: <UpdateDealPassword />,
      api_key: <ApiKey />,
      secret_code: <BindSecretCode />,
      security: <SecurityRule />,
      settings: '待开发',
      white_ip: '待开发'
    };

    return (
      <Card activeTabKey={this.props.match.params.tab} tabList={tabs} className='narrow' onTabChange={this.handleTabChange}>
        {
          contentList[this.props.match.params.tab.replace('-', '_')]
        }
      </Card>
    );
  }
}

export default CustomerSecurity;
