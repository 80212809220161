import {Button, Form, Input, message as antMessage, Radio, Space, Spin, Typography} from 'antd';
import React from 'react';
import axios from '../../request/axios';

const {Text} = Typography;

class SecurityRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            has_bind: false,
            secret: null,
            form_submitting: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        axios.get('/security/security-rules-settings').then(res => {
            const {data} = res.data;
            this.formRef && this.formRef.setFieldsValue(data);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    onFinish = (values) => {
        this.setState({form_submitting: true});
        axios.post('/security/security-rules-settings', values).then(res => {
            const {code, message, data} = res.data;
            if (!code) {
                antMessage.success('修改成功');
                this.loadData();
            } else {
                antMessage.error(message);
            }
        }).finally(() => {
            this.setState({form_submitting: false});
        });
    };

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form ref={ref => this.formRef = ref} labelCol={{span: 6}} wrapperCol={{span: 14}}
                      onFinish={this.onFinish}>

                    <Form.Item name='use_deal_password_when_purchase' label='购买商品时'>
                        <Radio.Group>
                            <Radio value={true}>需输入交易密码</Radio>
                            <Radio value={false}><Text type='danger'>不需要输入交易密码（谨慎选择）</Text></Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      label='交易密码'
                      name='deal_password'
                      rules={[
                          {required: true}
                      ]}
                    >
                        <Input.Password style={{width: 200}}/>
                    </Form.Item>

                    <Form.Item wrapperCol={{
                        offset: 6,
                        span: 10
                    }}>
                        <Space>
                            <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                                确认修改
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Spin>
        );
    }
}

export default SecurityRule;
