import React from 'react';
import {
  Button,
  Descriptions,
  Form, Image,
  Input,
  List,
  message,
  Modal,
  Skeleton,
  Space,
  Steps,
  Typography
} from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import axios from '../../request/axios';
import Money from '../../components/Money';
import MultiImgUpload from '../../components/MultiImgUpload';
import ProductTypeTag from '../../components/ProductTypeTag';
import ApplyRefundDesc from './components/ApplyRefundDesc';
import moment from 'moment';
import YouyuUtils from '../../utils/YouyuUtils';
import kfAvatar from './asserts/kf-avatar.png';
import userAvatar from './asserts/user-avatar.png';

const {Link, Text} = Typography;
const {Item} = Descriptions;

const init_state = {
  order_id: null,
  order_complaint: null,
  order: null,
  messages: [],
  visible: false,
  loading_message: false,
  message_submitting: false,
  cancel_submitting: false
};

class OrderComplaintDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = init_state;
  }

  openModal = (order_id) => {
    if (this.formRef)
      this.formRef && this.formRef.resetFields();

    this.setState({...init_state, order_id, visible: true});
    axios.get('order-complaints/' + order_id).then((res) => {
      let {data} = res.data;
      const {order_complaint, order, messages} = data;
      this.setState({order_complaint, order, messages});
    });
  }

  sendMessage = (values) => {
    if (!this.state.message_submitting) {
      values.order_id = this.state.order_id;
      this.setState({message_submitting: true});
      axios.post('order-complaints/send-message', values).then((res) => {
        const {data} = res.data;
        message.success('发送成功');
        this.formRef && this.formRef.resetFields();
        this.props.onChange?.();
        this.setState({messages: data.messages});
      }).finally(() => {
        this.setState({message_submitting: false});
      });
    }
  }

  cancel = () => {
    const self = this;
    Modal.confirm({
      title: '确认要撤销该售后申请吗？',
      ...YouyuUtils.confirmModalDefaultProps,
      onOk() {
        self.setState({cancel_submitting: true});
        axios.post('order-complaints/cancel', {order_id: self.state.order_id}).then((res) => {
          if (!res.data.code) {
            message.success('撤销成功');
            self.props.onChange?.(self.state.order_id);
            self.closeModal();
          } else {
            message.error(res.data.message);
          }
        }).finally(() => {
          self.setState({cancel_submitting: false});
        });
      }
    });
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  render() {
    const {order_complaint, order, messages} = this.state;

    const footer = <Space>
      <Button onClick={this.closeModal}>关闭</Button>
      <Button type='primary' loading={this.state.cancel_submitting} onClick={this.cancel}>撤销售后</Button>
    </Space>;

    return <>
      <Modal
        title={'售后详情'}
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={650}
        keyboard
        bodyStyle={{height: 600, overflowY: 'auto'}}
      >
        <div className='mx-10'>
          <Steps current={1}>
            <Steps.Step key='1' title='申请售后'/>
            <Steps.Step key='2' title='客服受理'/>
            <Steps.Step key='3' title='处理结果'/>
          </Steps>
        </div>
        {
          order ? <>
              <div className='mx-10'>
                <Descriptions className='mt-6 mb-3' column={1} bordered size='small'
                              labelStyle={{width: 200, textAlign: 'center'}}>
                  <Item label='订单号' contentStyle={{width: 300}}><Text copyable>{order_complaint?.order_id}</Text></Item>
                  <Item label='商品'><ProductTypeTag
                    value={order?.product_type}/> {order?.product_name} ({order?.product_id})</Item>
                  <Item label='单价|数量|总价' contentStyle={{width: 300}}>
                    <Money value={order?.product_price} postfix=' | '/>
                    <Money remove_float value={order?.quantity} postfix=' | '/>
                    <Money value={order?.total_price}/>
                  </Item>
                  <Item label='售后原因' contentStyle={{width: 300}}>
                    {order_complaint?.reason}
                  </Item>
                  <Item label='申请退款' contentStyle={{width: 300}}>
                    <ApplyRefundDesc apply_refund_type={order_complaint?.apply_refund_type}
                                     apply_part_refund_amount={order_complaint?.apply_part_refund_amount}/>
                  </Item>
                  <Item label='已退金额' contentStyle={{width: 300}}>
                    <Money value={order?.refund_amount}/>
                  </Item>
                </Descriptions>
              </div>

              {
                messages &&
                <div className='mx-10'>
                  <Text>沟通记录：</Text>
                  <div className='mt-2'>
                    <List
                      itemLayout='horizontal'
                      dataSource={messages}
                      bordered
                      renderItem={item => (
                        <List.Item>
                          {
                            <div className='flex w-full'>
                              <div style={{width: 45}} className='flex-grow-0 flex-shrink-0 text-center'>
                                <img src={item.type === '1' ? userAvatar : kfAvatar} alt='头像'
                                     style={{width: 40, height: 40, marginLeft: (item.type === '1' ? 3 : 0)}}/>
                              </div>
                              <div className='flex flex-col ml-3 w-full'>
                                <div className='flex justify-between'>
                                  <Text strong>{item.type === '1' ? '我' : '客服'}</Text>
                                  <div>
                                    <Text type='secondary' style={{fontSize: '0.875em'}}>
                                      <ClockCircleOutlined/>&nbsp;
                                      {moment(item.created_at).format('Y-MM-DD HH:mm:ss')}
                                    </Text>
                                  </div>
                                </div>
                                <div className='mt-0.5'>
                                  <Text>{item.message}</Text>
                                  {
                                    item.img_url_list &&
                                    <div className='mt-1'>
                                      {
                                        item.img_url_list.map((img_url, index) => {
                                          return <span key={index} className='mr-2'><Image
                                            width={50}
                                            src={img_url}
                                            alt='售后图片'
                                          /></span>
                                        })
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          }

                        </List.Item>
                      )}
                      footer={<>
                        <div className='mt-4'>
                          <Form className='form-compact' ref={ref => this.formRef = ref}
                                layout='vertical' onFinish={this.sendMessage} requiredMark={false}>
                            <Form.Item
                              name='message'
                              label='补充说明'
                              rules={[{required: true}]}
                              style={{marginBottom: 0}}
                            >
                              <Input.TextArea className='mt-2' maxLength={1000} showCount onPressEnter={e => {
                                if (e.ctrlKey) this.formRef.submit();
                              }} autoSize={{minRows: 3, maxRows: 6}} placeholder='ctrl + ↵ 快速发送'/>
                            </Form.Item>
                            <Form.Item
                              name='img_url_list'
                              initialValue={[]}
                            >
                              <MultiImgUpload/>
                            </Form.Item>
                            <div className='text-right'>
                              <Button type='primary' htmlType='submit'
                                      loading={this.state.message_submitting || this.state.loading_message}>
                                发送
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </>}
                    />
                  </div>
                </div>
              }
            </>
            : <div style={{width: 400, margin: '24px auto 24px', height: 400}}><Skeleton active paragraph={{rows: 10}}/>
            </div>
        }
      </Modal>
    </>
  }

}

export default OrderComplaintDetailsModal;