import {
    Alert,
    Button,
    Card,
    Checkbox,
    Divider,
    Form,
    Input, List,
    message,
    Modal,
    Pagination,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {Tabs} from 'zent';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';
import ProductStatusTag from '../../components/ProductStatusTag';
import ProductTypeTag from '../../components/ProductTypeTag';
import Money from '../../components/Money';
import YouyuUtils from '../../utils/YouyuUtils';
import ProductCategorySelector from '../../components/ProductCategorySelector';
import ModelConstants from '../../constants/ModelConstants';

const {Option} = Select;

const {Paragraph, Text, Link} = Typography;

class TaobaoMappings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            products: [],
            product_status: 0,
            pagination: {page: 1, page_size: 15}
        };
    }

    componentDidMount() {
        this.loadData();
    }

    handleInputClear = (e) => {
        if (!e.target.value) this.loadData();
    }

    handlePaginationChange = (page, page_size) => {
        this.setState({
            pagination: {
                page: page,
                page_size: page_size
            }
        }, this.loadData.bind(this, false));
    }

    loadData = (resetPagination = true) => {
        let {pagination} = this.state;
        if (resetPagination) {
            pagination.page = 1;
            this.setState({pagination});
        }
        this.setState({
            loading: true
        });
        let query_params = {
            ...this.formRef.getFieldsValue(true), ...pagination,
            // type: ModelConstants.PRODUCT_TYPE.CHARGE
        };
        query_params.status = this.state.product_status;
        axios.get('customer-shop-mappings/taobao-products', {params: query_params}).then((res) => {
            const {data} = res.data;
            this.setState({
                loading: false,
                products: data.data,
                total: data.total,
                selected_row_keys: []
            });
        });
    };

    resetForm = () => {
        this.formRef && this.formRef.resetFields();
        this.loadData();
    }

    render() {
        // 表格操作
        const operations = <div className='text-right'>
            <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                        onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                        total={this.state.total} pageSizeOptions={[15, 25, 50, 100]}/>
        </div>;

        return (
            <div>
                <div>
                    <Alert
                        message='如何设置商家编码？'
                        description={<Text>
                            将淘宝商品的商家编码设置为下表中的<span style={{color: 'red'}}>编码+淘宝宝贝面值</span>，即可关联并自动按照面值换算。<br/>
                            如平台中“1元Q币”商品的淘宝商家编码为“1032_1108_”，淘宝店铺中“10元Q币”宝贝可以填写“1032_1108_10”关联该商品，下单后自动按面值换算后，将购买数量乘以10从平台进货。
                            对于淘宝的多规格商品，将商家规格编码按上述方式设置即可。
                        </Text>} type='info'/>
                </div>
                <div className='query-form-container mt-4'>
                    <Form className='query-form' ref={(form) => {
                        this.formRef = form;
                    }} onFinish={this.loadData}>
                        <div className='query-form__inputs_container'>
                            <Form.Item label='商品查询' name='key'>
                                <Input placeholder='商品编号/商品名称' style={{width: 160}}
                                       onPaste={() => setTimeout(this.loadData, 10)}
                                       onChange={this.handleInputClear}/>
                            </Form.Item>
                            <Form.Item label='商品分类' name='category_id'>
                                <ProductCategorySelector placeholder='选择商品分类' style={{width: 180}}
                                                         onChange={this.loadData} allowClear/>
                            </Form.Item>
                        </div>
                        <div className='query-form__operations'>
                            <Space>
                                <Button type='primary' htmlType='submit'>筛选</Button>
                                <Link onClick={this.resetForm}>重置筛选条件</Link>
                            </Space>
                        </div>
                    </Form>
                </div>
                <Table className='mt-4' rowKey='id' dataSource={this.state.products} loading={this.state.loading}
                       size='small' pagination={false} scroll={{scrollToFirstRowOnChange: true}}
                       rowExpandable={record => record.extra_params != null && record.extra_params.length > 0}
                       expandedRowRender={record => <div style={{paddingLeft: 16}}>
                           <Table dataSource={record.extra_params} rowKey={item => item.id} showHeader={false} size='small'
                               pagination={false}>
                               <Table.Column title='淘宝商家编码' dataIndex='taobao_customer_id' key='taobao_customer_id'
                                             render={value => <Text copyable>{value}</Text>} width={280}/>
                               <Table.Column title='充值参数' dataIndex='name' key='name' render={(value, record) =>
                                   <>&nbsp;&nbsp;&nbsp;&nbsp;└───── &nbsp;
                                       <Text type='secondary'>[充值参数：{value}]</Text>
                                   </>
                               }/>
                           </Table>
                       </div>
                       }>
                    <Table.Column title='淘宝商家编码' dataIndex='taobao_customer_id' key='taobao_customer_id'
                                  align='left' render={(value) => <Text copyable>{value}</Text>} width={280}/>
                    <Table.Column title='平台商品' dataIndex='name' key='name' style={{minHeight: 250}}
                                  render={(value, record) =>
                                      <><ProductTypeTag value={record.type}/>&nbsp;{value}（{record.id}）</>
                                  }/>
                    <Table.Column title='平台商品面值' dataIndex='face_value' key='face_value' align='right' width={120}
                                  render={(value, record) => !record.param_name && <Money value={value}/>}/>
                    <Table.Column title='进货价格' dataIndex='price' key='price' align='right' width={120}
                                  render={(value, record) => !record.param_name && <Money value={value}/>}/>
                </Table>
                <div className='mt-4'>{operations}</div>
            </div>
        );
    }
}

export default TaobaoMappings;