import { Card } from 'antd';
import React from 'react';
import TaobaoShopSettings from './TaobaoShopSettings';
import TianmaoShopSettings from './TianmaoShopSettings';
import JingdongShopSettings from './JingdongShopSettings';
import TaobaoBuyerBlacks from "./TaobaoBuyerBlacks";
import TaobaoGetUid from "./TaobaoGetUid";

class CustomerShopSettings extends React.Component {

  handleTabChange = key => {
    this.props.history.push('/customer-shop-settings/' + key)
  };

  render() {
    const tabs = [
      {
        key: 'taobao',
        tab: '淘宝交易设置'
      },
      {
        key: 'tianmao',
        tab: '天猫交易设置'
      },
      {
        key: 'jingdong',
        tab: '京东交易设置'
      },
      {
        key: 'taobao_buyer_black',
        tab: '淘宝天猫买家共享黑名单'
      },
      {
        key: 'taobao_get_uid',
        tab: '淘宝昵称转UID'
      }
    ];

    const contentList = {
      taobao: <TaobaoShopSettings />,
      tianmao: <TianmaoShopSettings />,
      jingdong: <JingdongShopSettings />,
      taobao_buyer_black: <TaobaoBuyerBlacks />,
      taobao_get_uid: <TaobaoGetUid />,
    };

    return (
      <Card activeTabKey={this.props.match.params.tab} tabList={tabs} className='narrow' onTabChange={this.handleTabChange}>
        {
          contentList[this.props.match.params.tab.replace('-', '_')]
        }
      </Card>
    );
  }
}

export default CustomerShopSettings;