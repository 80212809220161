import {Button, Card, Form, Input, message as antMessage, Modal, Radio, Select, Space, Spin, Typography} from 'antd';
import React from 'react';
import axios from '../../request/axios';
import PasswordFormItem from "../../components/PasswordFormItem";

const {Link, Text} = Typography;
const {confirm} = Modal;

class UpdateLoginPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form_submitting: false
    };
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    axios.post('security/update-login-password', values).then(res => {
      const {code, message, data} = res.data;
      if (!code) {
        antMessage.success('修改成功');
        this.formRef && this.formRef.resetFields();
      } else {
        antMessage.error(message);
      }
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form ref={ref => this.formRef = ref} labelCol={{span: 6}} wrapperCol={{span: 14}} onFinish={this.onFinish}>

          <PasswordFormItem label='登录密码' name='login_password' style={{width: 200}} has_old={true} required={true}/>

          <Form.Item wrapperCol={{
            offset: 6,
            span: 10
          }}>
            <Space>
              <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                确认修改
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

export default UpdateLoginPassword;
