import { Tag } from 'antd';

const OrderSourceTypeTag = (props) => {
  const value = props.value;
  if(value === '1')
    return <Tag color='blue'>站内</Tag>;
  else if(value === '2')
    return <Tag color='geekblue'>接口</Tag>;
  else if(value === '3')
    return <Tag color='orange'>淘宝</Tag>;
  else if(value === '4')
    return <Tag style={{width: 64}} color='blue'>拼多多</Tag>;
  else
    return <></>
}
export default OrderSourceTypeTag;