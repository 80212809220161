import React from 'react';
import { Alert, Button, message, Rate, Table, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import ProductTypeTag from '../../../components/ProductTypeTag';
import Money from '../../../components/Money';
import { ArrowLeftOutlined } from '@ant-design/icons';
import axios from '../../../request/axios';

const {Link, Paragraph, Text} = Typography;

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      table_title: null,
      products: null
    }
  }

  componentDidMount() {
    this.loadData(this.props.datasource, this.props.category, this.props.keyword);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const should_update = this.props.datasource !== nextProps.datasource || this.props.category !== nextProps.category || this.props.keyword !== nextProps.keyword;
    if (should_update)
      this.loadData(nextProps.datasource, nextProps.category, nextProps.keyword);
  }

  loadData = (datasource, category, keyword) => {
    let url = null;
    let table_title = null;
    if (datasource === 'in_category' && category != null) {
      url = 'products?category_id=' + category.id;
      table_title = () => <Text><Link
        onClick={() => this.props.history.push('/purchase/categories')}><ArrowLeftOutlined/> 返回
      </Link>&nbsp; 已选择分类“{category.name}”</Text>;
    } else if (datasource === 'search' && keyword) {
      url = 'products?keyword=' + keyword
      table_title = () => <Text><Link
        onClick={() => this.props.history.push('/purchase/categories')}><ArrowLeftOutlined/> 返回
      </Link>&nbsp; 搜索关键字“{keyword}”</Text>;
    } else if (datasource === 'favorites') {
      url = 'products?is_favorite=true';
      table_title = () => <Text><Link
        onClick={() => this.props.history.push('/purchase/categories')}><ArrowLeftOutlined/> 返回
      </Link>&nbsp; 商品收藏夹</Text>;
    }

    if (url) {
      this.setState({loading: true});
      axios.get(url).then((res) => {
        const {data} = res.data;
        this.setState({
          loading: false,
          table_title: table_title,
          products: data
        });
      });
    }
  }

  handleSetFavorite = (id, is_favorite) => {
    const values = {id, is_favorite};
    axios.post('products/set-favorite', values).then((res) => {
      const {code} = res.data;
      if (!code) message.success(is_favorite ? '收藏成功' : '已取消收藏');
    });
  }

  render() {
    return <>
      <div className='purchase__product-box'>
        <Alert type='info' showIcon message={<Text>系统支持站内下单和API下单，API下单请参考<Link onClick={() => window.open('/api-doc/', '_blank')}>接口文档</Link></Text>}/>
        <Table title={this.state.table_title} rowKey='id' dataSource={this.state.products}
               loading={this.state.loading} className='mt-2 mb-4' size='small' pagination={false}>
          <Table.Column title='商品' dataIndex='name' key='name' style={{minHeight: 400}}
                        render={(value, record) =>
                          <div className='flex items-center'>
                            <div>
                              <ProductTypeTag style={{fontSize: 24, marginRight: 12, padding: '2px 8px'}}
                                              value={record.type}/>
                            </div>
                            <div>
                              <div><Text type='primary'> {value}</Text></div>
                              <div><Text type='secondary'>商品编号：<Paragraph type='secondary'
                                                                          className='select-all'
                                                                          style={{display: 'inline'}}
                                                                          copyable>{record.id}</Paragraph></Text>
                              </div>
                            </div>
                          </div>
                        }/>
          <Table.Column title='价格' dataIndex='price' key='price' align='right' width={120}
                        render={(value) => <Money value={value}/>}/>
          <Table.Column title='价格变化' key='price_change' align='right' width={120}
                        render={(value) => {
                          return <>
                            <Text type='secondary'>-</Text>
                            {/*<div><Money prefix='↓' value={-0.2}/></div>*/}
                            {/*<div><Link>变化记录</Link></div>*/}
                          </>
                        }}/>
          <Table.Column title='收藏' dataIndex='is_favorite' key='is_favorite' align='center' width={120}
                        render={(value, record) => {
                          return <>
                            <Rate onChange={(number) => this.handleSetFavorite(record.id, number === 1)}
                                  defaultValue={value ? 1 : 0} count={1}/>
                          </>
                        }}/>
          <Table.Column title='购买' key='operations' align='center' width={150}
                        render={(value, record) =>
                          <>
                            {
                              record.type === '1' &&
                              <Button type='primary' className='round'
                                      onClick={() => this.props.history.push('/purchase/submit-order/' + record.id)}>充值</Button>
                            }
                            {
                              record.type === '2' && <Button type='primary' danger className='round '
                                                             onClick={() => this.props.history.push('/purchase/submit-order/' + record.id)}>购卡</Button>
                            }
                          </>
                        }/>
        </Table>
      </div>
    </>
  }
}

export default withRouter(Products);