import {Button, Form, Input, InputNumber, message, Row, Space, Radio, Spin, Typography, Checkbox} from 'antd';
import React from 'react';
import axios from '../../request/axios';

const {Text} = Typography;

class TaobaoGetUid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_loading: false,
      form_submitting: false,
      show_result: false,
      taobao_buyer_uid: null,
      taobao_buyer_fail_nick: null,
    };
  }

  componentDidMount() {
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    axios.post('customer-shops/get-uid', values).then((res) => {
      const {data} = res.data;
      this.setState({
        show_result: true,
        taobao_buyer_uid: data.uids?.join('\n'),
        taobao_buyer_fail_nick: data.fail_nicks?.join(',')
      });
      message.success('获取成功');
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  };

  render() {
    return (
      <Spin spinning={this.state.form_loading}>
        <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>
          <Form.Item
            name='nick_str'
            label='需要转换的淘宝昵称'
            extra='多个淘宝昵称，用逗号分隔'
            rules={[
              {required: true},
            ]}
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          {this.state.show_result &&
            <Form.Item
              label='转换后的Uid'
            >
              <Input.TextArea value={this.state.taobao_buyer_uid} style={{width: 450}}
                              autoSize={{minRows: 3, maxRows: 10}}/>
            </Form.Item>
          }

          {
            this.state.show_result &&
            <Form.Item
              label='转换失败的淘宝昵称'
            >
              <Input.TextArea value={this.state.taobao_buyer_fail_nick} style={{width: 450}}
                              autoSize={{minRows: 3, maxRows: 10}}/>
            </Form.Item>
          }

          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 10
            }}
          >
            <Space>
              <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                获取Uid
              </Button>
              <Button onClick={this.handleReturn}>
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

export default TaobaoGetUid;