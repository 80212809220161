import { Button, Form, Input, InputNumber, message, Row, Space, Spin, Typography } from 'antd';
import React from 'react';
import axios from '../../request/axios';

const {Text} = Typography;

class JingdongShopSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_loading: false,
      form_submitting: false,
      taobao_buyer_purchase_frequency_minutes: null,
      taobao_buyer_purchase_frequency_times: null
    };
  }

  componentDidMount() {
    this.setState({form_loading: true});
    axios.get('customer-shop-settings').then(res => {
      const {data} = res.data;

      data.jingdong_buyer_nick_black_list = data.jingdong_buyer_nick_black_list?.join('\n');
      data.jingdong_buyer_ip_black_list = data.jingdong_buyer_ip_black_list?.join('\n');
      data.jingdong_charge_account_black_list = data.jingdong_charge_account_black_list?.join('\n');

      this.formRef && this.formRef.setFieldsValue(data);
    }).finally(() => {
      this.setState({form_loading: false});
    });
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});

    axios.post('customer-shop-settings/jingdong', values).then(() => {
      message.success('保存成功');
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  };

  render() {
    return (
      <Spin spinning={this.state.form_loading}>
        <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 4}} wrapperCol={{span: 20}}>

          <Form.Item
              name='jingdong_buyer_nick_black_list'
              label='买家黑名单'
              extra='一行一个买家，处于黑名单的买家，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='jingdong_buyer_ip_black_list'
            label='买家IP黑名单'
            extra='一行一个IP，处于黑名单的买家IP，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            name='jingdong_charge_account_black_list'
            label='充值账号黑名单'
            extra='一行一个充值账号，处于黑名单的充值账号，订单失败处理'
          >
            <Input.TextArea style={{width: 450}} autoSize={{minRows: 3, maxRows: 10}}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 10
            }}
          >
            <Space>
              <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                保存
              </Button>
              <Button onClick={this.handleReturn}>
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

export default JingdongShopSettings;