import {Button, Card, Form, Input, message as antMessage, Modal, Radio, Select, Space, Spin, Typography} from 'antd';
import React from 'react';

const {Link, Text} = Typography;
const {confirm} = Modal;

class PasswordFormItem extends React.Component {
  render() {
    let label = this.props.label;
    let name = this.props.name;
    let has_old = !!this.props.has_old;
    let required = !!this.props.required;
    let prefixNew = has_old ? '新' : '';
    let inputAttrubite = {
      style:this.props.style,
      placeholder:this.props.placeholder
    };

    return (
      label && name &&
      <>
        {
          has_old && <Form.Item
            label={'原' + label}
            name={'old_' + name}
            rules={[
              {required: required},
            ]}
          >
            <Input.Password {...inputAttrubite} />
          </Form.Item>
        }
        <Form.Item
          label={prefixNew + label}
          name={name}
          extra='8-20个字符，为提高安全性，请使用字符、数字、符号的组合密码'
          dependencies={['old_' + name]}
          rules={[
            {required: required},
            {min: 8},
            {max: 20},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (has_old) {
                  let old_value = getFieldValue('old_' + name);
                  if (old_value) {
                    if (!value) {
                      return Promise.reject(new Error('请输入新' + label));
                    } else if (old_value === value) {
                      return Promise.reject(new Error('原' + label + '和新' + label + '不能相同'));
                    }
                  }
                }
                if (getFieldValue(name) !== value) {
                  return Promise.reject(new Error('两次输入密码必须一致'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password {...inputAttrubite}/>
        </Form.Item>

        <Form.Item
          label={'确认' + prefixNew + label}
          name={'confirm_' + name}
          dependencies={[name]}
          rules={[
            {required: required},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (getFieldValue(name) !== value) {
                  return Promise.reject(new Error('两次输入密码必须一致'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password {...inputAttrubite}/>
        </Form.Item>
      </>
    );
  }
}

export default PasswordFormItem;
