import { Space, Typography } from 'antd';

const {Paragraph, Text} = Typography;

let OrderChargeParams = (props) => {
  let {params} = props;
  if (!params || !params.length) {
    return <Text type='secondary'>-无参数-</Text>;
  } else {
    return <Space size={12} wrap>
      {
        params.map((item, index) => {
          return <span key={index}>
            <Text type='secondary'>{item.name}: </Text>
            <Text type='secondary' ellipsis={{tooltip: true}}
                  style={{maxWidth: 220}}>{(!item.value_desc || item.value_desc === '') ? item.value : item.value_desc}</Text>
          </span>;
        })
      }
    </Space>
  }
}

export default OrderChargeParams;