import React from 'react';
import {Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import OrderDetailsModal from '../../pages/order/OrderDetailsModal';

class OrderSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};
    }

    openModal = () => {
        if (this.state.value && this.state.value !== '')
            this.orderDetailsModal.openModal(this.state.value);
    }

    render() {
        return <>
            <Input
                prefix={<SearchOutlined style={{color: '#444'}}/>}
                value={this.state.value}
                onChange={e => this.setState({value: e.target.value})}
                onPaste={e => setTimeout(this.openModal, 10)}
                onPressEnter={this.openModal}
                onClick={e => e.target.select()}
                placeholder='粘贴订单号快速查单' style={{borderRadius: 15, height: 28}}/>
            <OrderDetailsModal onClose={() => this.setState({value: ''})}  ref={ref => this.orderDetailsModal = ref}/>
        </>
    }
}

export default OrderSearchInput;