import React from 'react';
import { Button, Empty, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import emptyImg from '../../../asserts/imgs/empty_img.png';

const {Link, Paragraph, Text} = Typography;


class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parent_category: null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {parent_category} = nextProps;
    if (parent_category !== prevState.parent_category) {
      return {
        parent_category,
      };
    }
    return null;
  }

  render() {
    if(this.state.parent_category === null)
      return <></>;
    return <>
      {
        this.state.parent_category.children.length === 0 && <Empty style={{marginTop: 80}}/>
      }

      {
        this.state.parent_category.children.length !== 0 &&
        <div className='purchase__layer2-box'>
          {
            this.state.parent_category.children.map(cat => {
              return <div key={cat.id} className='cat-card' onClick={() => this.props.history.push('/purchase/categories/' + cat.id)}>
                {
                  cat.img_url ? <img src={cat.img_url} alt=''/> : <img src={emptyImg} alt=''/>
                }
                <Text strong ellipsis={{tooltip: cat.name}}>{cat.name}</Text>
                <Paragraph strong type='secondary' ellipsis={{rows: 2, tooltip: cat.short_desc}}
                           className='short-desc'>{cat.short_desc}</Paragraph>
                <Button>查看商品</Button>
              </div>
            })
          }
        </div>
      }
    </>
  }
}

export default withRouter(Categories);