import axios from 'axios';
import {message as antMessage, Modal} from 'antd';
import React from "react";
import userAuth from '../auth/UserAuth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

// antMessage.config({
//   className: 'custom-name'
// });

instance.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV === 'development') {
    if (config.url.indexOf('?') === -1)
      config.url = config.url + '?XDEBUG_SESSION_START=PHPSTORM';
    else
      config.url = config.url + '&XDEBUG_SESSION_START=PHPSTORM';
  }
  config.headers.Ftoken = userAuth.token();
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(
  response => {
    let {code, message, data} = response.data;
    if (response.status === 200) {
      if (code === 1) {
        antMessage.error(message);
        return Promise.reject(response);
      }
      if (code === 2) {
        Modal.info({
          zIndex:2000,
          content: (<div><p>{message}</p></div>),
          onOk() {
          },
        });
        return Promise.reject(response);
      }

      if (code === 100) {
        if (data instanceof Object) {
          for (const key in data) {
            message = data[key][0];
          }
        }
        antMessage.error(message);
        return Promise.reject(response);
      }

      if (code === 201) {
        userAuth.clearAuth();
        userAuth.redirectToLoginPage();
        return Promise.reject(response);
      }

      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },

  error => {
    if (error.response.status) {
      antMessage.error('请求处理失败');
      return Promise.reject(error.response);
    }
  }
);

export default instance;