import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Pagination,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Tabs } from 'zent';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';
import DateRangePicker from '../../components/DateRangePicker';
import OrderSourceTypeTag from '../../components/OrderSourceTypeTag';
import ProductTypeTag from '../../components/ProductTypeTag';
import Money from '../../components/Money';
import OrderStatusTitle from '../../components/OrderStatusTitle';
import ProductImg from '../../components/ProductImg';
import OrderDetailsModal from './OrderDetailsModal';
import OrderStatusStyle from './components/OrderStatusStyle';
import OrderChargeParams from './components/OrderChargeParams';
import ApplyOrderComplaintModal from '../order-complaint/ApplyOrderComplaintModal';
import OrderComplaintDetailsModal from '../order-complaint/OrderComplaintDetailsModal';
import userAuth from '../../auth/UserAuth';

const {Option} = Select;
const {Text, Link} = Typography;

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_total_stat: false,
      loading_export: false,
      orders: [],
      order_status: null,
      pagination: {page: 1, page_size: 10, total: 0},
      total_stat: null,
      selected_row_keys: []
    };
  }

  componentDidMount() {
    this.loadData();
    if (this.props.location.query?.view_order_id) {
      this.orderDetailsModal.openModal(this.props.location.query?.view_order_id);
    }
  }

  loadData = (resetPagination = true) => {
    let {pagination} = this.state;
    if (resetPagination) {
      pagination.page = 1;
    }
    this.setState({loading: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {status: this.state.order_status, ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]}, ...pagination};
    axios.get('orders', {params: query_params}).then((res) => {
      const {data} = res.data;
      pagination.total = data.total;
      this.setState({
        loading: false,
        orders: data.data,
        pagination,
        total_stat: null,
        selected_row_keys: []
      });
    });
  };

  loadTotalStat = () => {
    this.setState({loading_total_stat: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {
      status: this.state.order_status, ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]},
      query_type: 'stat'
    };
    axios.get('orders', {params: query_params}).then((res) => {
      const {data} = res.data;
      this.setState({
        loading_total_stat: false,
        total_stat: data
      });
    });
  };

  export = () => {
    this.setState({loading_export: true});
    let values = this.formRef.getFieldsValue();
    let query_params = {
      status: this.state.order_status, ...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]},
      query_type: 'export'
    };
    axios.get('orders', {params: query_params, responseType: 'blob'}).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const file_name = `订单_${values.date_range[0].format('Y-M-D')}_${values.date_range[1].format('Y-M-D')}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    }).finally(() => {
      this.setState({
        loading_export: false
      });
    });
  }

  selectOrder = (id) => {
    let keys = [...this.state.selected_row_keys];
    const existsIndex = keys.indexOf(id);
    if (existsIndex === -1)
      keys.push(id);
    else
      keys.splice(existsIndex, 1);
    this.setState({selected_row_keys: keys});
  }

  selectAllRows = () => {
    if (this.state.orders.length === 0) return;

    if (this.state.orders.length === this.state.selected_row_keys.length) {
      this.setState({selected_row_keys: []});
    } else {
      this.setState({selected_row_keys: this.state.orders.map(item => item.id)});
    }
  }

  handleClearQueryField = (e) => {
    if (!e.target.value) this.loadData();
  }

  handleStatusTabChange = (key) => {
    this.setState({order_status: key}, this.loadData);
  }

  handlePaginationChange = (page, page_size) => {
    this.setState({
      pagination: {
        page: page,
        page_size: page_size,
        total: this.state.pagination.total
      }
    }, this.loadData.bind(this, false));
  }

  // 当某个订单状态发生变化时重新更新该订单数据，避免刷新整个列表导致的性能开销及定位问题
  handleOrderChange = (id) => {
    this.setState({
      loading: true
    });
    axios.get('/orders', {params: {id}}).then((res) => {
      const {data} = res.data;
      const order = data.data[0];
      let orders = [...this.state.orders];
      for (let i = 0; i < orders.length; ++i) {
        if (orders[i].id === order.id)
          orders[i] = order;
      }
      this.setState({
        loading: false,
        orders: orders
      });
    });
  }

  pageStat = () => {
    const {orders} = this.state;
    const count = orders.length;
    let quantity = 0;
    let total_price = 0;
    let total_profit = 0;
    orders.forEach(item => {
      quantity += parseInt(item.quantity);
      total_price += parseFloat(item.total_price);
      total_profit += parseFloat(item.total_profit ?? 0);
    })
    return {count, quantity, total_price, total_profit};
  }

  resetForm = () => {
    this.formRef && this.formRef.resetFields();
    this.loadData();
  }

  render() {
    const {loading_total_stat, orders, total_stat} = this.state;
    const page_stat = this.pageStat();

    // 表格操作
    const operations = <div className='flex justify-between'>
      <Space>
        <Text> </Text>
        <Checkbox
          onChange={this.selectAllRows}
          checked={orders.length !== 0 && orders.length === this.state.selected_row_keys.length}
          indeterminate={orders.length !== 0 && this.state.selected_row_keys.length > 0 && orders.length !== this.state.selected_row_keys.length}/>
        <Text>&nbsp;当页全选&nbsp;{this.state.selected_row_keys.length ?
          <Text style={{fontSize: '0.875em'}}>已选订单{this.state.selected_row_keys.length}个</Text> : null}&nbsp;</Text>
        <Dropdown overlay={<Menu>
          <Menu.Item key='3' disabled>
            无可用操作
          </Menu.Item>
        </Menu>}>
          <Button>
            批量操作 <DownOutlined/>
          </Button>
        </Dropdown>
      </Space>
      <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                  onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                  total={this.state.pagination.total} pageSizeOptions={[15, 25, 50, 100]}/>
    </div>;

    return (
      <Card title='订单查询'>
        <div className='query-form-container'>
          <Form className='query-form' ref={ref => this.formRef = ref}
                initialValues={{date_range: DateRangePicker.today()}} onFinish={this.loadData}>
            <div className='query-form__inputs_container'>
              <Form.Item label='订单编号' name='id'>
                <Input placeholder='订单编号' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleClearQueryField}/>
              </Form.Item>
              <Form.Item label='充值账号' name='charge_account'>
                <Input placeholder='充值账号' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleClearQueryField}/>
              </Form.Item>
              <Form.Item label='商家订单编号' name='out_order_id'>
                <Input placeholder='商家订单编号' style={{width: 200}}
                       onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleClearQueryField}/>
              </Form.Item>
            </div>
            <div className='query-form__inputs_container'>
              <Form.Item label='商品查询' name='product_key'>
                <Input placeholder='商品编号/商品名称' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleClearQueryField}/>
              </Form.Item>
              <Form.Item label='商品类型' name='product_type'>
                <Select placeholder='选择商品类型' style={{width: 160}} onChange={this.loadData} allowClear>
                  <Option value='1'>自动充值</Option>
                  <Option value='2'>官方卡密</Option>
                </Select>
              </Form.Item>
            </div>
            <div className='query-form__inputs_container'>
              <Form.Item label='下单日期' name='date_range'>
                <DateRangePicker onChange={this.loadData}/>
              </Form.Item>
            </div>
            <div className='query-form__operations'>
              <Space>
                <Button type='primary' htmlType='submit'>筛选</Button>
                <Button onClick={this.export} loading={this.state.loading_export}>导出</Button>
                <Link onClick={this.resetForm}>重置筛选条件</Link>
              </Space>
            </div>
          </Form>
        </div>
        <Tabs activeId={this.state.order_status} type='card' className='mt-4' onChange={this.handleStatusTabChange}>
          <Tabs.TabPanel key={1} tab='全部' id={null}>
          </Tabs.TabPanel>,
          <Tabs.TabPanel key={2} tab='正在处理' id={100}>
          </Tabs.TabPanel>,
          <Tabs.TabPanel key={3} tab='交易成功' id={200}>
          </Tabs.TabPanel>,
          <Tabs.TabPanel key={4} tab='交易失败' id={500}>
          </Tabs.TabPanel>,
          <Tabs.TabPanel key={5} tab='可疑订单' id={300}>
          </Tabs.TabPanel>
        </Tabs>
        <div>
          {operations}

          {/*表头*/}
          <div className='mt-4 flex border bg-header'>
            <div className='p-3 border-r' style={{width: 500}}>商品</div>
            <div style={{flexBasis: '0%'}} className='flex-grow p-3 border-r'>总价</div>
            <div style={{flexBasis: '0%'}} className='flex-grow p-3 text-right'>状态</div>
          </div>

          {/*表体*/}
          <Spin spinning={this.state.loading}>
            {/*空状态*/}
            {
              orders.length === 0 && <div className='mt-4'><Empty description='暂无订单'/></div>
            }

            {
              orders.length !== 0 &&
              orders.map(order => {
                return <div key={order.id}>
                  <div className='mt-3'>
                    <div className='border hover:border-selected'>

                      {/*标题*/}
                      <div className='flex justify-between p-2.5 bg-header'>
                        <div>
                          <Checkbox checked={this.state.selected_row_keys.indexOf(order.id) !== -1}
                                    onChange={this.selectOrder.bind(this, order.id)}/>
                          <span className='ml-2'><OrderSourceTypeTag value={order.source_type}/></span>
                          <Text>订单编号：<Text copyable>{order.id}</Text></Text>
                          <Text className='ml-3'
                                type='secondary'>{moment(order.created_at).format('Y-MM-DD HH:mm:ss')}</Text>
                          {
                              order.out_order_id && <Text className='ml-2'>商家订单编号：<Text
                                  copyable>{order.out_order_id}</Text></Text>
                          }
                        </div>
                        <div>
                        {/*  订单卡片的右上角内容放在这里 */}
                        </div>
                      </div>


                      {/*内容*/}
                      <div className='flex'>
                        <div className='flex justify-between items-center p-2.5 border-r' style={{width: 500}}>
                          <div style={{width: 80}}>
                            <ProductImg style={{width: 58, height: 58}} img_url={order.product_img_url}/>
                          </div>
                          <div className='flex-grow'><ProductTypeTag
                            value={order.product_type}/> {order.product_name}（{order.product_id}）
                          </div>
                          <div style={{width: 80}} className='w-auto'>
                            <div>x{order.quantity}</div>
                            <div><Money value={order.product_price}/></div>
                          </div>
                        </div>
                        <div style={{flexBasis: '0%'}} className='flex-grow flex items-center p-2.5 border-r'>
                          <Money value={order.total_price}/>
                        </div>
                        <div
                          style={{flexBasis: '0%', ...OrderStatusStyle.statusWrapperStyle(order.status)}}
                          className='flex-grow flex flex-col justify-center items-end p-2.5 pr-4 cursor-pointer'
                          onClick={() => this.orderDetailsModal.openModal(order.id)}>
                          <h1 style={{fontSize: 16, ...OrderStatusStyle.statusTitleStyle(order.status)}}>
                            <OrderStatusTitle status={order.status}/></h1>
                          <div><Text type='secondary'>查看详情</Text></div>
                        </div>
                      </div>

                      {/*脚部*/}
                      <div className='flex justify-between ml-4 border-t py-2.5 pr-1'>
                        <div><OrderChargeParams params={order.charge_params}/></div>
                        <div className='pr-3'>
                          {
                            (userAuth.hasPermission('order_complaints') && order.has_complaint === '0')
                              ? <Link onClick={() => this.applyOrderComplaintModal.openModal(order)}>申请售后</Link>
                              : <Link onClick={() => this.orderComplaintDetailsModal.openModal(order.id)}>查看售后</Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })
            }
            {
              orders.length !== 0 && <div className='mt-4 px-4 py-2.5 flex border'>
                <div>
                  <div><Text type='secondary'>本页统计数据</Text></div>
                  <div className='mt-0.5'>
                    <Text>订单合计：{page_stat.count}笔 &nbsp; 购买数量：{page_stat.quantity}个 &nbsp; 订单金额：<Money postfix='元'
                      value={page_stat.total_price}/></Text></div>
                </div>
                <div className='ml-10'>
                  <div><Text type='secondary'>全部统计数据</Text></div>
                  <div className='mt-0.5'>
                    {
                      total_stat
                        ?
                        <Text>订单合计：{total_stat.count ?? 0}笔 &nbsp; 购买数量：{total_stat.quantity ?? 0}个 &nbsp; 订单金额：<Money
                          postfix='元' value={total_stat.total_price ?? 0}/></Text>
                        :
                        <>
                          {
                            loading_total_stat
                              ? <LoadingOutlined/>
                              : <Link onClick={this.loadTotalStat}>点击查看</Link>
                          }
                        </>
                    }
                  </div>
                  {/*<div><Text>购买数量：26 &nbsp; 订单金额：<Money value={965.654}/></Text> &nbsp; 利润合计：<Money value={23.2}/></div>*/}
                </div>
              </div>
            }
          </Spin>
        </div>
        <div className='mt-4'>{operations}</div>
        <OrderDetailsModal onChange={this.handleOrderChange} ref={ref => this.orderDetailsModal = ref}/>
        <ApplyOrderComplaintModal onChange={this.handleOrderChange} ref={ref => this.applyOrderComplaintModal = ref}/>
        <OrderComplaintDetailsModal onChange={this.handleOrderChange}
                                    ref={ref => this.orderComplaintDetailsModal = ref}/>
      </Card>
    );
  }
}

export default Orders;
