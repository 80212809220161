import React from 'react';
import { Button, Modal, Space, Table } from 'antd';
import Money from '../../components/Money';
import moment from 'moment';
import axios from '../../request/axios';
import CustomerBalanceRecordTypeTag from '../../components/CustomerBalanceRecordTypeTag';

class OrderRefundRecordsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      loading: false,
      visible: false
    }
  }

  openModal = (id) => {
    this.setState({
      id,
      visible: true,
      records: [],
      loading: true
    }, () => {
      axios.get('customer-balance-records', {params: {tran_no: id, order: 'asc'}}).then((res) => {
        const {data} = res.data;
        this.setState({
          loading: false,
          records: data.data
        });
      });
    });
  }

  closeModal = () => {
    this.setState({id: null, visible: false});
  }

  render() {
    const footer = <Space>
      <Button onClick={this.closeModal}>关闭</Button>
    </Space>

    return <>
      <Modal
        title='订单退款'
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={600}
        keyboard
      >
        <div>
          <Table
            dataSource={this.state.records}
            bordered
            size='small'
            pagination={false}
          >
            <Table.Column title='交易类型' dataIndex='type' key='type' align='center'
                          render={(value) => <CustomerBalanceRecordTypeTag value={value}/>}/>
            <Table.Column title='交易前' dataIndex='balance_before' key='balance_before' align='center'
                          render={(value) => <Money value={value}/>}/>
            <Table.Column title='金额' dataIndex='amount' key='amount' align='center'
                          render={(value) => <Money signed value={value}/>}/>
            <Table.Column title='交易后' dataIndex='balance_after' key='balance_after' align='center'
                          render={(value) => <Money value={value}/>}/>
            <Table.Column title='交易时间' dataIndex='created_at' key='created_at' align='center'
                          render={(value) => moment(value).format('Y-MM-DD HH:mm:ss')}/>
          </Table>
        </div>
      </Modal>
    </>
  }
}

export default OrderRefundRecordsModal;