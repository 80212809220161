import React from 'react';
import { Alert, Button, Form, Input, message, Modal, Select, Space, Spin, Typography } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import axios from '../../request/axios';
import ProductSelector from '../../components/ProductSelector';
import ModelConstants from '../../constants/ModelConstants';

const {Link} = Typography;

class EditTianmaoMappingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      is_creation_mode: true,
      is_update_mode: false,
      visible: false,
      loading: false,
      set_default_charge_params: false,
      form_submitting: false
    }
  }

  openModal = (id = null) => {
    if (this.formRef)
      this.formRef && this.formRef.resetFields();

    this.setState({
      id,
      is_creation_mode: id === null,
      is_update_mode: id !== null,
      visible: true,
      set_default_charge_params: false
    }, () => {
      if (this.state.is_update_mode) {
        this.setState({loading: true});
        axios.get('tianmao-products/' + id).then(res => {
          const {data} = res.data;
          this.formRef && this.formRef.setFieldsValue({...data, ...data.default_charge_params});
        }).finally(() => {
          this.setState({loading: false});
        });
      }
    });
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    values.set_default_charge_params = this.state.set_default_charge_params;
    if (this.state.is_creation_mode) {
      axios.post('tianmao-products', values).then(() => {
        message.success('保存成功');
        this.props.onChange?.();
        this.closeModal();
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    } else if (this.state.is_update_mode) {
      axios.put('tianmao-products/' + this.state.id, values).then(() => {
        message.success('保存成功');
        this.props.onChange?.();
        this.closeModal();
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    }
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  render() {
    const footer = <Space>
      <Button onClick={this.closeModal}>取消</Button>
      <Button type='primary' onClick={() => this.formRef.submit()} loading={this.state.form_submitting}>保存</Button>
    </Space>;

    return <>
      <Modal
        title={this.state.is_creation_mode ? '添加天猫商品关联' : '编辑天猫商品关联'}
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={400}
        keyboard
        destroyOnClose
        zIndex={1001}
      >
        <div className='mt-4'>
          <Spin spinning={this.state.loading}>
            <Form className='form-compact' ref={ref => this.formRef = ref} labelCol={{span: 8}} wrapperCol={{span: 16}}
                  onFinish={this.onFinish} loading={this.state.form_submitting} requiredMark={false}>
              <Form.Item
                name='item_tsc'
                label='天猫商品TSC'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input bordered={this.state.is_creation_mode} readOnly={this.state.is_update_mode} style={{width: 130}}/>
              </Form.Item>

              <Form.Item
                  name='item_outer_id'
                  label='天猫商家编码'
                  rules={[
                    {
                      //required: true
                    }
                  ]}
              >
                <Input bordered={this.state.is_creation_mode} readOnly={this.state.is_update_mode} style={{width: 130}}/>
              </Form.Item>

              <Form.Item
                name='item_face_value'
                label='天猫商品面值'
                rules={[
                  {
                    required: true
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || (!isNaN(value) && parseFloat(value) > 0.01 && parseFloat(value) < 99999.99)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('请输入正确的面值'));
                    }
                  })
                ]}
              >
                <Input style={{width: 130, textAlign: 'right'}} addonBefore='￥' suffix='元'/>
              </Form.Item>

              <Form.Item
                name='product_id'
                label='关联本平台商品'
                rules={[
                  {
                    required: true,
                    message: '请选择商品'
                  }
                ]}
              >
                <ProductSelector style={{width: 220}} />
              </Form.Item>

              {
                this.state.set_default_charge_params && <div>
                  <Alert type='info' message='通过默认参数可以指定对接商品的充值参数，以平台购买页面的名称为准，没有可以不填'/>
                  <div className='mt-4'>
                    <Form.Item
                      name='game_name'
                      label='游戏'
                    >
                      <Input style={{width: 220}}/>
                    </Form.Item>

                    <Form.Item
                      name='charge_mode_name'
                      label='充值方式'
                    >
                      <Input style={{width: 220}}/>
                    </Form.Item>

                    <Form.Item
                      name='charge_account_type_name'
                      label='账号类型'
                    >
                      <Input style={{width: 220}}/>
                    </Form.Item>

                    <Form.Item
                      name='area_name'
                      label='区域'
                    >
                      <Input style={{width: 220}}/>
                    </Form.Item>

                    <Form.Item
                      name='server_name'
                      label='服务器'
                    >
                      <Input style={{width: 220}}/>
                    </Form.Item>
                  </div>
                </div>
              }

              <div className='text-right'>
                <Link style={{fontSize: 12}}
                      onClick={() => this.setState({set_default_charge_params: !this.state.set_default_charge_params})}>
                  {this.state.set_default_charge_params ? <UpOutlined/> : <DownOutlined/>} 默认参数设置
                </Link>
              </div>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  }
}

export default EditTianmaoMappingModal;