import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Divider } from 'antd';
import all_menus from './menus';
import { connect } from 'react-redux';
import userAuth from '../auth/UserAuth';
import ModelConstants from '../constants/ModelConstants';

class AsideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: []
    };
  }

  componentDidMount() {
    let menus = [];
    if (userAuth.user().type === ModelConstants.USER_TYPE.MAIN) {
      menus = [...all_menus];
    } else {
      menus = this.filterMenus();
    }
    this.setState({menus: menus});
  }

  filterMenus() {
    let menus = [...all_menus];
    let is_main = userAuth.user().type === ModelConstants.USER_TYPE.MAIN;
    menus.forEach(parent_menu => {
      if (parent_menu.children) {
        for (let i = parent_menu.children.length - 1; i >= 0; --i) {
          let menu = parent_menu.children[i];
          if (menu.require_permission && !userAuth.hasPermission(menu.require_permission)) {
            parent_menu.children.splice(i, 1);
          } else if (menu.only_main && !is_main) {
            parent_menu.children.splice(i, 1);
          }
        }
      }
    });
    // 清理没有子菜单的parent menu
    for(let i = menus.length - 1; i >= 0; --i) {
      if(menus[i].children && menus[i].children.length === 0) {
        menus.splice(i, 1);
      }
    }
    return menus;
  }

  render() {
    return (
      <nav className='side-nav'>
        {
          this.state.menus.map((cat, index) => {
            if (cat.to) {
              return <div className='nav-item-group' key={cat.name}>
                <li className={'nav-item' + (cat.to === this.props.location.pathname ? ' active' : '')}><Link
                  to={cat.to}><span
                  className='nav-item-text'>{cat.name}</span></Link></li>
              </div>
            } else {
              return <div key={cat.name}>
                <Divider style={{margin: '6px 0 0 0'}}/>
                <div className='nav-item-group'>
                  <div className='nav-item-group-title'>
                    <img src={cat.icon} width='16' height='16' alt=''/>
                    <span>{cat.name}</span>
                    <div className='pdd-left-nav-arrow-collapsed'/>
                  </div>
                  <ul className='nav-item-group-content pdd-left-nav-slide-enter-done'>
                    {
                      cat.children && cat.children.length !== 0 && cat.children.map(menu => {
                        return <li
                          className={'nav-item' + (menu.to === this.props.location.pathname ? ' active' : '')}
                          key={menu.name}><Link to={menu.to}><span
                          className='nav-item-text'>{menu.name}</span></Link></li>
                      })
                    }
                  </ul>
                </div>
              </div>
            }
          })
        }
      </nav>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    unconfirmed_orders_count: state.unconfirmed_orders_count,
    waiting_for_approval_customers_count: state.waiting_for_approval_customers_count,
    out_of_stock_products_count: state.out_of_stock_products_count,
    unhandled_order_complaint_count: state.unhandled_order_complaint_count
  }
}

export default connect(mapStateToProps)(withRouter(AsideNav));