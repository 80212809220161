import { createStore } from 'redux';

function reducer(state, action) {
  switch (action.type) {
    case 'INIT':
      return {
        balance: null,
        frozen_balance: null
      };
    case 'UPDATE_BALANCE':
      return {...state, ...{balance: action.value}};
    case 'UPDATE_FROZEN_BALANCE':
      return {...state, ...{frozen_balance: action.value}};
    default:
      return state;
  }
}

let store = createStore(reducer);
store.dispatch({type: 'INIT'});

export default store;