import React from 'react';
import { Button, Input, InputNumber, Typography } from 'antd';

import './QuantityInput.less';

const {Text} = Typography;

class QuantityInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      default_value: null
    }
  }

  componentDidMount() {
    let default_value = 1;
    const rule_arr = this.props.rule.split(',');
    if (rule_arr.length !== 0) {
      default_value = parseInt(rule_arr[0].split('-')[0]);
    }
    this.setState({value: default_value, default_value}, this.handleValueChange);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({value: nextProps.value});
  }

  handleIncrease = () => {

    let {value} = this.state;
    if (isNaN(value))
      value = this.state.default_value;
    else
      ++value;
    this.setState({value}, this.handleValueChange);
  }

  handleDecrease = () => {
    let {value} = this.state;
    if (isNaN(value))
      value = this.state.default_value;
    else
      value = value <= 1 ? 1 : --value;
    this.setState({value}, this.handleValueChange);
  }

  handleValueChange = () => {
    this.props.onChange?.(this.state.value);
  }

  handleInputChange = (e) => {
    this.setState({value: e.target.value}, this.handleValueChange);
  }

  handleInputKeyDown = (e) => {
    if (e.keyCode === 38) {
      this.handleIncrease();
      e.preventDefault()
    }
    if (e.keyCode === 40) {
      this.handleDecrease();
      e.preventDefault()
    }
  }

  render() {
    return <Input.Group compact>
      <Button className='quantity-input-btn' onClick={this.handleDecrease}>-</Button>
      <Input className='text-money' value={this.state.value}
             onChange={this.handleInputChange}
             onKeyDown={this.handleInputKeyDown}
             disabled={this.props.disabled}
             style={{width: 80, textAlign: 'center', fontWeight: 'bold'}}/>
      <Button className='quantity-input-btn' onClick={this.handleIncrease}>+</Button>

    </Input.Group>
  }
}

export default QuantityInput;