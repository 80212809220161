import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  message as antMessage,
  Modal,
  Pagination,
  Space,
  Table,
  Typography
} from 'antd';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';
import ProductTypeTag from '../../components/ProductTypeTag';
import Money from '../../components/Money';
import YouyuUtils from '../../utils/YouyuUtils';
import EditJingdongMappingModal from './EditJingdongMappingModal';


const {Paragraph, Text, Link} = Typography;

class JingdongMappings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: [],
      pagination: {page: 1, page_size: 15}
    };
  }

  componentDidMount() {
    this.loadData();
  }

  handleInputClear = (e) => {
    if (!e.target.value) this.loadData();
  }

  handlePaginationChange = (page, page_size) => {
    this.setState({
      pagination: {
        page: page,
        page_size: page_size
      }
    }, this.loadData.bind(this, false));
  }

  loadData = (resetPagination = true) => {
    let {pagination} = this.state;
    if (resetPagination) {
      pagination.page = 1;
      this.setState({pagination});
    }
    this.setState({
      loading: true
    });
    let query_params = {...this.formRef.getFieldsValue(true), ...pagination};
    axios.get('jingdong-products', {params: query_params}).then((res) => {
      const {data} = res.data;
      console.log(data.data);
      this.setState({
        loading: false,
        products: data.data,
        total: data.total,
        selected_row_keys: []
      });
    });
  };

  resetForm = () => {
    this.formRef && this.formRef.resetFields();
    this.loadData();
  }

  handleDelete = (id) => {
    const self = this;
    Modal.confirm({
      title: '确认要删除所选商品关联吗？',
      ...YouyuUtils.confirmModalDefaultProps,
      onOk() {
        axios.delete('jingdong-products/' + id).then((res) => {
          if (!res.data.code) {
            antMessage.success('删除成功');
            self.loadData();
          } else {
            antMessage.error(res.data.message);
          }
        });
      }
    });
  }

  render() {
    // 表格操作
    const operations = <div className='text-right'>
      <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                  onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                  total={this.state.total} pageSizeOptions={[15, 25, 50, 100]}/>
    </div>;

    return (
      <div>
        <div>
          <Alert
            message='京东店铺对接是企业级定制方案，具体使用方式请联系客服。'
            type='info' showIcon/>
        </div>
        <div className='query-form-container mt-4'>
          <Form className='query-form' ref={(form) => {
            this.formRef = form;
          }} onFinish={this.loadData}>
            <div className='query-form__inputs_container'>
              <Form.Item label='京东商品编号' name='id'>
                <Input placeholder='京东商品编号' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleInputClear}/>
              </Form.Item>
              <Form.Item label='平台商品' name='key'>
                <Input placeholder='商品编号/商品名称' style={{width: 160}} onPaste={() => setTimeout(this.loadData, 10)}
                       onChange={this.handleInputClear}/>
              </Form.Item>
            </div>
            <div className='query-form__operations'>
              <Space>
                <Button type='primary' htmlType='submit'>筛选</Button>
                <Button onClick={() => this.editJingdongMappingModal.openModal()}>添加关联</Button>
                <Link onClick={this.resetForm}>重置筛选条件</Link>
              </Space>
            </div>
          </Form>
        </div>
        <Table className='mt-4' rowKey='jingdong_product_id' dataSource={this.state.products} loading={this.state.loading}
               size='small' pagination={false} scroll={{scrollToFirstRowOnChange: true}}>
          <Table.Column title='京东商品编号' dataIndex='jingdong_product_id' key='jingdong_product_id' width={120}/>
          <Table.Column title='京东商品名称' dataIndex='jingdong_product_name' key='jingdong_product_name' width={200}/>
          <Table.Column title='京东商品面值' dataIndex='jingdong_product_face_value' key='jingdong_product_face_value'
                        align='right' width={100}
                        render={(value, record) => <Money value={value}/>}/>
          <Table.Column title='平台商品' dataIndex='name' key='name' width={200} render={(value, record) =>
            <>
              <div><ProductTypeTag value={record.type}/>&nbsp;
                {value}（{record.id}）
              </div>
            </>
          }/>
          <Table.Column title='平台商品面值' dataIndex='face_value' key='face_value' align='right' width={100}
                        render={(value, record) => <Money value={value}/>}/>
          <Table.Column title='进货价格' dataIndex='price' key='price' align='right' width={100}
                        render={(value, record) => <Money value={value}/>}/>
          <Table.Column title='操作' dataIndex='action' key='action' align='right' width={120} render={(value, record) =>
            <>
              <Link onClick={() => this.editJingdongMappingModal.openModal(record.jingdong_product_id)}>编辑</Link>
              <Divider type='vertical'/>
              <Link onClick={() => this.handleDelete(record.jingdong_product_id)}>删除</Link>
            </>
          }/>
        </Table>
        <div className='mt-4'>{operations}</div>
        <EditJingdongMappingModal onChange={() => this.loadData()} ref={ref => this.editJingdongMappingModal = ref}/>
      </div>
    );
  }
}

export default JingdongMappings;
