import { Tag } from 'antd';

const CustomerBalanceRecordTypeTag = (props) => {
  const value = props.value;
  if(value === '1')
    return <Tag color='red'>财务加款</Tag>;
  else if(value === '2')
    return <Tag color='green'>财务扣款</Tag>;
  else if(value === '3')
    return <Tag color='green'>购买商品</Tag>;
  else if(value === '4')
    return <Tag color='red'>订单退款</Tag>;
  else if(value === '5')
    return <Tag color='green'>冻结资金</Tag>;
  else if(value === '6')
    return <Tag color='red'>资金解冻</Tag>;
  else
    return <></>
}
export default CustomerBalanceRecordTypeTag;