const menus = [
  {
    name: '商家首页',
    to: '/dashboard'
  },
  {
    name: '采购商品',
    icon: 'https://commimg.pddpic.com/mms_static/2019-11-15/e28bf79a-91ca-4101-94d7-c598ffd0f523.png',
    children: [
      {
        name: '我要采购',
        to: '/purchase/categories',
        require_permission: 'products.purchase'
      },
      {
        name: '商品收藏夹',
        to: '/purchase/favorites',
        require_permission: 'products.purchase'
      }
    ]
  },
  {
    name: '订单管理',
    icon: 'https://commimg.pddpic.com/mms_static/2019-11-15/24c8b43e-37b1-4967-bdec-d6aabfaf3935.png',
    children: [
      {
        name: '我的订单',
        to: '/orders',
        require_permission: 'orders'
      },
      {
        name: '卡密锁定',
        to: '/locked-cards',
        require_permission: 'orders'
      },
      {
        name: '售后记录',
        to: '/order-complaints',
        require_permission: 'order_complaints'
      }
    ]
  },
  {
    name: '网店对接',
    icon: 'https://commimg.pddpic.com/mms_static/2019-11-15/24c8b43e-37b1-4967-bdec-d6aabfaf3935.png',
    children: [
      {
        name: '网店订单',
        to: '/customer-shop-orders',
        require_permission: 'shops.orders'
      },
      {
        name: '商品关联',
        to: '/customer-shop-mappings',
        require_permission: 'shops.mappings'
      },
      {
        name: '网店管理',
        to: '/customer-shops',
        require_permission: 'shops'
      },
      {
        name: '交易设置',
        to: '/customer-shop-settings/taobao',
        require_permission: 'shops.settings'
      }
    ]
  },
  {
    name: '财务管理',
    icon: 'https://commimg.pddpic.com/mms_static/2019-11-15/b61dd2ec-e9d8-41eb-971a-35957520e6ca.png',
    children: [
      {
        name: '账户充值',
        to: '/charge-balance',
        require_permission: 'finance.charge_balance'
      },
      {
        name: '财务记录',
        to: '/balance-records',
        require_permission: 'finance.balance_records'
      }
    ]
  },
  {
    name: '账户管理',
    icon: 'https://commimg.pddpic.com/mms_static/2019-11-15/a0b6afda-b18c-4bf9-b4c3-f48a3396ff36.png',
    children: [
      {
        name: '员工管理',
        to: '/customer-users',
        only_main: true
      },
      {
        name: '安全设置',
        to: '/security/login_psw'
      }
    ]
  }
];

export default menus;