const ProductTypeTag = (props) => {
  const value = props.value;

  if (value) {
    if (value === '1')
      return <span style={{fontWeight: 'bold', color: '#2d8cf0', backgroundColor: '#F0FAFF', ...props.style}}>充</span>;
    else
      return <span style={{fontWeight: 'bold', color: '#ed4014', backgroundColor: '#ffefe6', ...props.style}}>卡</span>;
  } else {
    return (
      <span>-</span>
    );
  }
}
export default ProductTypeTag;