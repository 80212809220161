import {Typography} from 'antd';

const {Text} = Typography;

const YouyuUtils = {
  parseAmount: function (value) {
    if (value === null || value === undefined) {
      return null;
    }

    value = Number.parseFloat(value);
    if (isNaN(value)) {
      return null;
    }

    value = parseFloat(value.toFixed(4)).toString();
    const pointIndex = value.indexOf('.');
    if (pointIndex === -1)
      value = value + '.00';
    else if ((value.length - pointIndex) === 2)
      value = value + '0';

    return value;
  },
  parsePurchaseQuantityRule: function (value) {
    let arr = value.split(",");
    let result = [];
    arr.forEach(item => {
      let numArr = item.split('-');
      if (numArr.length === 1) {
        result.push(numArr[0]);
      } else if (numArr.length === 2) {
        const min = parseInt(numArr[0]);
        const max = parseInt(numArr[1]);
        for (let i = min; i <= max; i++) {
          result.push(i);
        }
      }
    })
    return result;
  },
  parsePurchaseQuantitySelectOptions: function (value) {
    const arr = this.parsePurchaseQuantityRule(value);
    let options = [];
    arr.forEach(number => {
      options.push({
        label: number,
        value: number
      })
    })
    return options;
  },
  copyTextToClipboard: function (text) {
    let textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    textArea.style.width = '2em';
    textArea.style.height = '2em';

    textArea.style.padding = 0;

    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
    }
    document.body.removeChild(textArea);
  },
  confirmModalDefaultProps: {
    content: <Text type='secondary' style={{fontSize: '0.875em'}}>
      <Text type='secondary' style={{fontSize: '0.875em'}} code>空格</Text>快速确认，
      <Text type='secondary' style={{fontSize: '0.875em'}} code>esc</Text>快速取消</Text>
  }
}

export default YouyuUtils;