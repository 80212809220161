import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Card, Input, message, Spin, Typography } from 'antd';
import axios from '../../request/axios';
import Categories from './components/Categories';
import Products from './components/Products';
import './style.less';
import OrderDetailsModal from "../order/OrderDetailsModal";

const {Link, Paragraph, Text} = Typography;
const {Search} = Input;

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // 分类页面和商品列表页面不会共存，所以用同一个loading
      categories: [],
      products: [],
      selected_parent_category: null,
      selected_category: null
    }
  }

  componentDidMount() {
    this.loadData();
    if (this.props.location.query?.view_order_id) {
      this.orderDetailsModal.openModal(this.props.location.query?.view_order_id);
    }
  }

  loadData() {
    this.setState({
      loading: true
    });
    axios.get('product-categories').then((res) => {
      const {data} = res.data;
      let newState = {
        loading: false,
        categories: data
      };
      if(data.length > 0)
        newState.selected_parent_category = data[0];
      this.setState(newState);
    });
  }

  selectCategory = (category) => {
    this.props.history.push('/purchase/categories');
    this.setState({selected_parent_category: category});
  }

  onSearch = (keyword) => {
    if (keyword)
      this.props.history.push('/purchase/search/' + keyword);
    else
      message.error('请输入搜索关键字');
  }

  render() {
    return <Card title='我要采购' style={{minHeight: 500}}>
      <Spin spinning={this.state.loading}>
        <div className='purchase__layer1-box'>
          <div className='label'>
            选择分类：
          </div>
          <div className='cats'>
            <ul>
              {
                this.state.categories.map(cat => {
                  return <li key={cat.id}><Link
                    className={this.state.selected_parent_category != null && this.state.selected_parent_category.id === cat.id ? 'active' : ''}
                    onClick={this.selectCategory.bind(this, cat)}>{cat.name}</Link></li>
                })
              }
            </ul>
          </div>
          <div className='search'>
            <Search placeholder='搜索商品' defaultValue={this.props.match.params.keyword} onSearch={this.onSearch} style={{width: 200, borderRadius: 15}}/>
          </div>
        </div>

        <Switch>
          <Route path='/purchase/categories' exact render={() => {
            return <Categories parent_category={this.state.selected_parent_category}/>
          }}/>
          <Route path='/purchase/categories/:id' exact render={() => {
            const selected_category = this.findCategoryById(this.props.match.params.id);
            if (selected_category !== null)
              return <Products datasource='in_category' category={selected_category}/>
          }}/>
          <Route path='/purchase/search/:keyword' exact render={() => {
            return <Products datasource='search' keyword={this.props.match.params.keyword}/>
          }}/>
          <Route path='/purchase/favorites' exact render={() => {
            return <Products datasource='favorites'/>
          }}/>
        </Switch>
      </Spin>
      <OrderDetailsModal onChange={this.handleOrderChange} ref={ref => this.orderDetailsModal = ref}/>
    </Card>
  }

  findCategoryById = (id) => {
    let result = null;
    this.state.categories.forEach(parent_cat => {
      if (parent_cat.id === id)
        result = parent_cat;
      parent_cat.children.forEach(cat => {
        if (cat.id === id)
          result = cat;
      })
    })
    return result;
  }
}

export default Purchase;