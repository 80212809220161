import { Button, Card, Divider, message, Modal, Space, Table, Typography } from 'antd';
import React from 'react';
import axios from '../../request/axios';
import ResetPasswordModal from './components/ResetPasswordModal';
import moment from 'moment';
import YouyuUtils from '../../utils/YouyuUtils';

const { Text, Link } = Typography;

class CustomerUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: []
    };
  }

  handleCreate = () => {
    this.props.history.push('/customer-users/edit');
  };

  handleEdit = (id) => {
    this.props.history.push('/customer-users/edit/' + id);
  };

  handleDelete = (record) => {
    const self = this;
    Modal.confirm({
      title: '确认要删除用户“' + record.username + '”吗？',
      ...YouyuUtils.confirmModalDefaultProps,
      onOk() {
        axios.delete('customer-users/' + record.id).then((res) => {
          if (!res.data.code) {
            message.success('删除成功');
            self.loadData();
          } else {
            message.error(res.data.message);
          }
        });
      }
    });
  };

  handleUnbindCode = (record) => {
    const self = this;
    Modal.confirm({
      title: '确认要解绑用户“' + record.username + '”身份验证器吗？',
      ...YouyuUtils.confirmModalDefaultProps,
      onOk() {
        axios.post('customer-users/unbind-code',{id: record.id}).then((res) => {
          if (!res.data.code) {
            message.success('身份验证器解绑成功');
            self.loadData();
          } else {
            message.error(res.data.message);
          }
        });
      }
    });
  };

  handleResetPasswordFinish = () => {
    this.setState({
      reset_password_modal_visible: false,
    });
  }

  loadData = () => {
    let self = this;
    this.setState({
      loading: true
    });
    axios.get('customer-users').then(function(res) {
      const { data } = res.data;
      self.setState({
        loading: false,
        data: data
      });
    });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <Card title='员工列表' className='narrow'>
        <div>
          <Space>
            <Button type='primary' onClick={this.handleCreate}>
              + 添加员工
            </Button>
          </Space>
        </div>
        <Table style={{ marginTop: '24px' }} rowKey='id' dataSource={this.state.data} loading={this.state.loading} bordered>
          <Table.Column title='用户名' dataIndex='username' key='username' />
          <Table.Column title='状态' dataIndex='status' key='status' render={(value) =>
            <>{value === '1' ? <Text type="success">启用</Text> : <Text type="danger">停用</Text>}</>
          } />
          <Table.Column title='身份验证器' dataIndex='secret_code' key='secret_code' render={(value) =>
            <>{value && value>0 ? '已绑定': '未绑定'}</>
          } />
          <Table.Column title='创建时间' dataIndex='created_at' key='created_at' render={(value) => {
            return moment(value).format('YYYY-MM-DD');
          }} />
          <Table.Column title='操作' dataIndex='action' key='action' align='left' render={(value, record) =>
            <>
              <Link onClick={() => this.resetPasswordModal.openModal(record)}>重置密码</Link>
              <Divider type='vertical' />
              <Link onClick={this.handleUnbindCode.bind(this, record)}>解绑身份验证器</Link>
              <Divider type='vertical' />
              <Link onClick={this.handleEdit.bind(this, record.id)}>编辑</Link>
              <Divider type='vertical' />
              <Link onClick={this.handleDelete.bind(this, record)}>删除</Link>
            </>
          } />
        </Table>
        <ResetPasswordModal ref={ref => this.resetPasswordModal = ref}/>
      </Card>
    );
  }
}

export default CustomerUsers;
