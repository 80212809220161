import {Tag} from 'antd';
import ModelConstants from '../constants/ModelConstants';

const OrderComplaintStatusTag = (props) => {
  const value = props.value;
  if (value === ModelConstants.ORDER_COMPLAINT_STATUS.UNHANDLED)
    return <Tag color='red'>待受理</Tag>;
  else if (value === ModelConstants.ORDER_COMPLAINT_STATUS.IN_HANDLE)
    return <Tag color='blue'>受理中</Tag>;
  else if (value === ModelConstants.ORDER_COMPLAINT_STATUS.FINISH)
    return <Tag color='green'>已处理</Tag>;
  else
    return <></>
}
export default OrderComplaintStatusTag;
