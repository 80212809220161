import React from 'react';
import axios from '../request/axios';
import { Cascader } from 'antd';

class ProductCategorySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_categories: [],
      cascader_value: null
    };
  }

  componentDidMount() {
    axios.get('product-categories').then((res) => {
      const {data} = res.data;
      data.map(item => {
        if (item.children && item.children.length === 0) {
          item.children = [{
            id: -1,
            name: '无子目录',
            disabled: true
          }];
        }
        return item;
      });
      this.setState({
        product_categories: data
      }, () => {
        this.setCascaderValue(null);
      });
    });
  }

  componentWillReceiveProps(props) {
    this.setCascaderValue(props);
  }

  setCascaderValue = (props) => {
    if (props === null)
      props = this.props;
    if (props.value) {
      this.state.product_categories.forEach(parent => {
        parent.children.forEach(cat => {
          if (cat.id === this.props.value) {
            this.setState({
              cascader_value: [parent.id, cat.id]
            });
          }
        })
      })
    } else {
      this.setState({
        cascader_value: null
      });
    }
  }

  onChange = (value) => {
    this.setState({cascader_value: value});
    this.props.onChange?.(value ? value[1] : value);
  }

  render() {
    return <div className='mt-0' style={this.props.style}>
      <Cascader options={this.state.product_categories}
                fieldNames={{label: 'name', value: 'id'}}
                value={this.state.cascader_value}
                onChange={this.onChange} showSearch={true}
                placeholder='请选择商品分类'/>
    </div>
  }
}

export default ProductCategorySelector;