import ModelConstants from '../constants/ModelConstants';

const TOKEN_KEY = 'token';

class UserAuth {
  _user = null;

  init = (history) => {
    this.history = history;
  }

  user = () => {
    return this._user;
  }

  setUser = (user) => {
    this._user = user;
  }

  saveAuth = (user, token) => {
    this.setUser(user);
    localStorage.setItem(TOKEN_KEY, token);
  }

  clearAuth = () => {
    localStorage.removeItem(TOKEN_KEY);
  }

  token = () => {
    return localStorage.getItem(TOKEN_KEY);
  }

  timeout = () => {
    return !this.token();
  }

  redirectToLoginPage = () => {
    if (this.history)
      this.history.push('/');
  }

  hasPermission = (code) => {
    let user = this.user();
    if (user === null) {
      return false;
    } else if (user.type === ModelConstants.USER_TYPE.MAIN) {
      return true;
    } else {
      return user.permissions.indexOf(code) !== -1;
    }
  }
}

let userAuth = new UserAuth();

export default userAuth;
