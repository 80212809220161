import React from 'react';
import {Form, Input, Typography} from 'antd';
import ChargeAccountsImportModal from "./ChargeAccountsImportModal";

const {Text, Link} = Typography;

class ChargeAccountFormItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enable_import: false,
      imported_result: null,
    }
  }

  componentDidMount() {
  }

  handleCancelImport = () => {
    this.setState({
      enable_import: false,
      imported_result: null
    });
    this.props?.onImportedChargeAccounts(null);
  }

  handleImportedChargeAccounts = (result) => {
    this.setState({
      enable_import: true,
      imported_result: result
    });
    this.props?.onImportedChargeAccounts(result);
  }

  render() {
    const {enable_import, imported_result} = this.state;
    let {label, disabled} = this.props;

    label = label ? label : '充值账号';
    label = (label.trim() === '') ? '充值账号' : label

    const formLabel = <Text>{label} {!disabled &&
        <Link onClick={() => this.chargeAccountsImportModal.openModal()}>
          {imported_result ? '重新导入' : '批量导入'}
        </Link>}
    </Text>;

    return <>
      {
        enable_import
            ?
            <>
              <Form.Item label={formLabel} extra={this.props.extra}>
                <div className='px-2.5 py-1 border flex justify-between'
                     style={{backgroundColor: '#e5e5e560', width: 200}}>
                  <Text type='secondary'>已导入{imported_result.charge_accounts_total}个账号</Text>
                  {!disabled && <Link onClick={this.handleCancelImport}>取消</Link>}
                </div>
              </Form.Item>
            </>
            :
            <>
              <Form.Item
                  label={formLabel}
                  name='charge_account'
                  extra={this.props.extra}
                  rules={[{required: true, message: '请输入' + label}]}
              >
                <Input disabled={disabled} style={{width: 200}}/>
              </Form.Item>
            </>
      }
      <ChargeAccountsImportModal onImported={this.handleImportedChargeAccounts}
                                 ref={ref => this.chargeAccountsImportModal = ref}/>
    </>;
  }
}

export default ChargeAccountFormItem;