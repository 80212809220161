import React from 'react';
import { Button, Descriptions, Form, Input, message, Modal, Radio, Select, Space, Steps, Typography } from 'antd';
import axios from '../../request/axios';
import Money from '../../components/Money';
import ModelConstants from '../../constants/ModelConstants';
import MultiImgUpload from '../../components/MultiImgUpload';
import ProductTypeTag from '../../components/ProductTypeTag';

const {Link, Text} = Typography;
const {Item} = Descriptions;

const init_state = {
  order: null,
  apply_refund_type: '1',
  visible: false,
  form_submitting: false
};

class ApplyOrderComplaintModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = init_state;
  }

  openModal = (order) => {
    if (this.formRef)
      this.formRef && this.formRef.resetFields();
    this.setState({...init_state, order, visible: true});
  }

  onFinish = (values) => {
    values.order_id = this.state.order.id;
    this.setState({form_submitting: true});
    axios.post('order-complaints/apply', values).then(() => {
      message.success('已申请售后，请等待客服处理');
      this.props.onChange?.(this.state.order.id);
      this.closeModal();
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  handleRefundTypeChange = (e) => {
    this.setState({apply_refund_type: e.target.value});
  }

  handleQuantityChanged = (e) => {
    const {order} = this.state;
    let quantity = parseInt(e.target.value);
    let apply_part_refund_amount = '';
    if (!isNaN(quantity)) {
      apply_part_refund_amount = parseFloat((order.product_price * quantity).toFixed(4));
    }
    this.formRef && this.formRef.setFieldsValue({apply_part_refund_amount});
  }

  render() {
    const {order} = this.state;
    if (!order)
      return <></>;

    const footer = <Space>
      <Button onClick={this.closeModal}>取消</Button>
      <Button type='primary' onClick={() => this.formRef.submit()} loading={this.state.form_submitting}>提交申请</Button>
    </Space>;

    let reasons;
    if (order.product_type === ModelConstants.PRODUCT_TYPE.CHARGE) {
      reasons = ['长时间未充值', '充值成功实际未到账', '充值成功实际部分到账', '可疑订单未处理']
    } else {
      reasons = ['长时间未发卡', '提卡前被充值', '提卡后被充值', '卡号或密码错误', '卡已过期'];
    }

    return <>
      <Modal
        title={'申请售后'}
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={600}
        keyboard
        zIndex={1001}
      >
        <div className='mx-10'>
          <Steps current={0}>
            <Steps.Step key='1' title='申请售后'/>
            <Steps.Step key='2' title='客服受理'/>
            <Steps.Step key='3' title='处理结果'/>
          </Steps>

          <Descriptions className='mt-6 mb-3' column={1} bordered size='small'>
            <Item label='订单号' contentStyle={{width: 300}}><Text copyable>{order.id}</Text></Item>
            <Item label='商品'><ProductTypeTag
              value={order.product_type}/> {order.product_name} ({order.product_id})</Item>
            <Item label='单价|数量|总价' contentStyle={{width: 300}}>
              <Money value={order.product_price} postfix=' | '/>
              <Money remove_float value={order.quantity} postfix=' | '/>
              <Money value={order.total_price}/>
            </Item>
          </Descriptions>
        </div>

        <div className='mt-4 mx-10'>
          <Form className='form-compact' ref={ref => this.formRef = ref} labelCol={{span: 4}} wrapperCol={{span: 18}}
                onFinish={this.onFinish} requiredMark={false}>
            <Form.Item
              name='reason'
              label='售后类型'
              rules={[
                {
                  required: true,
                  message: '请选择售后类型'
                }
              ]}
            >
              <Select style={{width: 200}} placeholder='请选择售后类型'>
                {
                  reasons.map(item => <Select.Option key={item} value={item}>{item}</Select.Option>)
                }
                <Select.Option value='其他'>其他</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='message'
              label='补充说明'
              style={{marginBottom: 0}}
            >
              <Input.TextArea className='mt-2' maxLength={1000} showCount
                              autoSize={{minRows: 3, maxRows: 6}}/>
            </Form.Item>
            <Form.Item
              name='img_url_list'
              wrapperCol={{
                offset: 4,
                span: 18
              }}
              initialValue={[]}
            >
              <MultiImgUpload/>
            </Form.Item>
            <Form.Item
              name='apply_refund_type'
              label='退款申请'
              initialValue={this.state.apply_refund_type}
              rules={[
                {
                  required: true,
                  message: '请选择售后类型'
                }
              ]}
            >
              <Radio.Group onChange={this.handleRefundTypeChange}>
                <Radio value={ModelConstants.APPLY_REFUND_TYPE.NO_REFUND}>不需要退款</Radio>
                <Radio value={ModelConstants.APPLY_REFUND_TYPE.WHOLE_REFUND}>全部退款</Radio>
                <Radio value={ModelConstants.APPLY_REFUND_TYPE.PART_REFUND}>部分退款</Radio>
              </Radio.Group>
            </Form.Item>
            {
              this.state.apply_refund_type === '3' && <>
                <Form.Item
                  name='apply_part_refund_amount'
                  label='退款金额'
                  style={{marginBottom: 4}}
                  rules={[
                    {
                      required: true
                    },
                    ({getFieldValue}) => ({
                      validator(_, value) {
                        if (!value || (!isNaN(value) && parseFloat(value) >= 0.0001 && parseFloat(value) < 90000000)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('请输入正确的金额'));
                      }
                    })
                  ]}
                >
                  <Input style={{width: 130, textAlign: 'right'}} addonBefore='￥' suffix='元'/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 4}}>
                  <Link>按数量填写</Link>
                  <Input size='small' onChange={this.handleQuantityChanged}
                         style={{display: 'inline-block', width: 40, marginLeft: 4, textAlign: 'center'}}/>
                </Form.Item>
              </>
            }
          </Form>
        </div>
      </Modal>
    </>
  }
}

export default ApplyOrderComplaintModal;