import {Button, Card, Form, Input, message, Modal, Pagination, Select, Space, Table, Typography, Spin} from 'antd';
import 'zent/css/tabs.css';
import React from 'react';
import axios from '../../request/axios';
import Money from '../../components/Money';
import moment from 'moment';
import DateRangePicker from '../../components/DateRangePicker';
import YouyuUtils from "../../utils/YouyuUtils";

const {Link} = Typography;

class TaobaoBuyerBlacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            records: [],
            pagination: {page: 1, page_size: 15, total: 0},
            isModalOpen: false,
            isImportModalOpen: false,
            taobao_buyer_id: '',
            add_loading: false
        };
    }

    inputRef = React.createRef();

    componentDidMount() {
        this.loadData();
    }

    handleInputClear = (e) => {
        if (!e.target.value) this.loadData();
    }

    handlePaginationChange = (page, page_size) => {
        this.setState({
            pagination: {
                page: page,
                page_size: page_size,
                total: this.state.pagination.total
            }
        }, this.loadData.bind(this, false));
    }

    openModal = () => {
        this.setState({isModalOpen: true});
        setTimeout(() => {
            console.log(this.inputRef);
            if (this.inputRef && this.inputRef.current) {
                this.inputRef.current.focus();
            }
        }, 100);
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    openImportModal = () => {
        this.setState({isImportModalOpen: true});
        setTimeout(() => {
            console.log(this.inputRef);
            if (this.inputRef && this.inputRef.current) {
                this.inputRef.current.focus();
            }
        }, 100);
    }

    closeImportModal = () => {
        this.setState({isImportModalOpen: false});
    }

    addBuyerBlack = () => {
        // 触发这个操作的时候，把axios返回的promis return，确定按钮会有一个加载等待的效果
        if (!this.state.taobao_buyer_id) {
            message.error('请输入买家ID')
            return;
        }
        this.setState({add_loading: true});
        let values = {taobao_buyer_id: this.state.taobao_buyer_id};
        axios.post('taobao-buyer-blacks', values).then(() => {
            message.success('操作成功');
            this.loadData();
            this.closeModal();
        }).finally(() => {
            this.setState({add_loading: false});
        });
    }

    importBuyerBlack = () => {
        this.setState({add_loading: true});
        axios.post('taobao-buyer-blacks/store-from-tb-tm').then(() => {
            message.success('操作成功');
            this.loadData();
            this.closeImportModal();
        }).finally(() => {
            this.setState({add_loading: false});
        });
    }

    handleCardInputKeyPress = (e) => {
        if (e.key.toLowerCase() === "enter") {
            // this.closeModal();
            this.addBuyerBlack();
        }
    }

    loadData = (resetPagination = true) => {
        let {pagination} = this.state;
        if (resetPagination) {
            pagination.page = 1;
        }
        this.setState({loading: true});
        let values = this.formRef.getFieldsValue();
        let query_params = {...values, ...{date_range: [values.date_range[0].format('Y-M-D'), values.date_range[1].format('Y-M-D')]}, ...pagination};
        axios.get('taobao-buyer-blacks', {params: query_params}).then((res) => {
            const {data} = res.data;
            pagination.total = data.total;
            this.setState({
                loading: false,
                records: data.data,
                pagination,
                total_stat: null
            });
        });
    };

    handleDelete = (record) => {
        console.log(record);
        const self = this;
        Modal.confirm({
            title: '确认要删除黑名单买家ID“' + record.taobao_buyer_id + '”吗？',
            ...YouyuUtils.confirmModalDefaultProps,
            onOk() {
                axios.delete('taobao-buyer-blacks/' + record.id).then((res) => {
                    if (!res.data.code) {
                        message.success('删除成功');
                        self.loadData();
                    } else {
                        message.error(res.data.message);
                    }
                });
            }
        });
    };

    render() {
        const {records} = this.state;

        // 表格操作
        const operations = <div className='text-right'>
            <Pagination current={this.state.pagination.page} pageSize={this.state.pagination.page_size}
                        onChange={this.handlePaginationChange} showTotal={(total) => '共' + total + '条记录'}
                        total={this.state.pagination.total} pageSizeOptions={[15, 25, 50, 100]}/>
        </div>;

        return (
            <div>
                <div className='query-form-container'>
                    <Form className='query-form' ref={ref => this.formRef = ref} onFinish={this.loadData}
                          initialValues={{date_range: DateRangePicker.today()}}>
                        <div className='query-form__inputs_container'>
                            <Form.Item label='买家ID' name='taobao_buyer_id'>
                                <Input placeholder='' style={{width: 160}}
                                       onPaste={() => setTimeout(this.loadData, 10)}
                                       onChange={this.handleInputClear}/>
                            </Form.Item>

                            <Form.Item label='创建者' name='creator'>
                                <Input placeholder='' style={{width: 160}}
                                       onPaste={() => setTimeout(this.loadData, 10)}
                                       onChange={this.handleInputClear}/>
                            </Form.Item>
                        </div>
                        <div className='query-form__inputs_container'>
                            <Form.Item label='创建日期' name='date_range'>
                                <DateRangePicker onChange={this.loadData}/>
                            </Form.Item>
                        </div>
                        <div className='query-form__operations'>
                            <Space>
                                <Button type='primary' htmlType='submit'>筛选</Button>
                                <Button type='danger' onClick={() => this.openModal()}>添加</Button>
                                <Button type='danger' onClick={() => this.openImportModal()}>一键导入</Button>
                            </Space>
                        </div>
                    </Form>
                </div>
                <Table className='mt-4' rowKey='id' dataSource={records} loading={this.state.loading}
                       pagination={false}>
                    <Table.Column title='买家ID' dataIndex='taobao_buyer_id' key='taobao_buyer_id' align='center'
                                  render={value => value ? value : '-'}/>
                    <Table.Column title='创建人' dataIndex='creator' key='creator' align='center'
                                  render={value => value ? value : '-'}/>
                    <Table.Column title='创建时间' dataIndex='created_at' key='created_at' align='center'
                                  render={(value) => moment(value).format('Y-MM-DD HH:mm:ss')}/>
                    <Table.Column title='操作' key='action' align='right' width={200} render={(value, record) =>
                        <>
                            <Space>
                                <Link onClick={this.handleDelete.bind(this, record)}>删除</Link>
                            </Space>
                        </>
                    }/>
                </Table>
                <div className='mt-4'>{operations}</div>

                <Modal title="添加买家共享黑名单" onOk={this.addBuyerBlack} visible={this.state.isModalOpen}
                       onCancel={() => this.closeModal()}
                       confirmLoading={this.state.add_loading}
                       width={300} okText='添加' okButtonProps={{type: 'danger'}}>
                    <Input placeholder='输入买家黑名单' ref={this.inputRef} onKeyPress={this.handleCardInputKeyPress}
                           onPaste={() => setTimeout(this.loadData, 10)}
                           value={this.state.taobao_buyer_id}
                           onChange={e => this.setState({taobao_buyer_id: e.target.value})}/>
                </Modal>

                <Modal title="一键导入淘宝天猫黑名单" onOk={this.importBuyerBlack} visible={this.state.isImportModalOpen}
                       onCancel={() => this.closeImportModal()}
                       confirmLoading={this.state.add_loading}
                       width={300} okText='确定导入' okButtonProps={{type: 'danger'}}>
                </Modal>
            </div>
        );
    }
}

export default TaobaoBuyerBlacks;
