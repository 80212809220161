import {Button, Card, Form, Input, message, Radio, Select, Space, Spin} from 'antd';
import React from 'react';
import axios from '../../request/axios';
import PasswordFormItem from "../../components/PasswordFormItem";
import CustomerUserPermissionSelector from './components/CustomerUserPermissionSelector';

class EditCustomerUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      is_creation_mode: !this.props.match.params.id,
      is_update_mode: !!this.props.match.params.id,
      form_loading: false,
      form_submitting: false,
      form_validating_username: true,
    };
  }

  componentDidMount() {
    if (this.state.is_creation_mode) {
      this.formRef && this.formRef.setFieldsValue({
        status: '1'
      });
    }
    if (this.state.is_update_mode) {
      this.setState({form_loading: true});
      axios.get('customer-users/' + this.state.id).then(res => {
        this.formRef && this.formRef.setFieldsValue(res.data.data);
      }).finally(() => {
        this.setState({form_loading: false});
      });
    }
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    if (this.state.is_creation_mode) {
      axios.post('customer-users', values).then(() => {
        message.success('保存成功');
        this.props.history.push('/customer-users');
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    } else if (this.state.is_update_mode) {
      axios.put('customer-users/' + this.state.id, values).then(() => {
        message.success('保存成功');
        this.props.history.push('/customer-users');
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    }
  };

  handleReturn = () => {
    this.props.history.push('/customer-users');
  };

  render() {
    return (
      <Card title={this.state.is_creation_mode ? '添加员工' : '编辑员工'} className='narrow'>
        <Spin spinning={this.state.form_loading}>
          <Form ref={ref => this.formRef = ref} onFinish={this.onFinish} labelCol={{span: 4}} wrapperCol={{span: 6}}>
            <Form.Item
              label='用户名'
              name='username'
              extra={this.state.is_creation_mode ? '5-20个字符，可以是字母、数字或下划线' : ''}
              hasFeedback={this.state.is_creation_mode}
              rules={[
                {required: true},
                {min: 5},
                {max: 20},
                ({getFieldValue}) => {
                  let _self = this;
                  return {
                    async validator(_, value) {
                      if (value && value.length >= 5 && _self.state.is_creation_mode) {
                        return axios.get('customer-users/' + getFieldValue('username') + '/exists').then((res) => {
                          const {data} = res.data;
                          if (data)
                            return Promise.reject(new Error('用户名“' + value + '”已存在'))
                          return Promise.resolve();
                        });
                      }
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <Input disabled={this.state.is_update_mode}/>
            </Form.Item>

            {
              this.state.is_creation_mode &&
              <PasswordFormItem label='登录密码' name='login_password' has_old={false} required={true}/>
            }

            {
              this.state.is_creation_mode &&
              <PasswordFormItem label='交易密码' name='deal_password' has_old={false} required={true}/>
            }

            <Form.Item
              name='status'
              label='状态'>
              <Radio.Group>
                <Radio value='1'>启用</Radio>
                <Radio value='2'>停用</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name='permissions'
              label='权限'
              valuePropName='permissions'
              wrapperCol={{span: 12}}>
              <CustomerUserPermissionSelector/>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 10
              }}
            >
              <Space>
                <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                  保存
                </Button>
                <Button onClick={this.handleReturn}>
                  返回
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    );
  }
}

export default EditCustomerUser;
