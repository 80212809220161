import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import Layout from './layouts/Layout';
import AuthLayout from './pages/auth/AuthLayout';
import { Provider } from 'react-redux';
import reduxStore from './redux/reduxStore';
import DetectMobile from './components/DetectMobile';
import userAuth from './auth/UserAuth';

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Provider store={reduxStore}>
        <Router>
          <Switch>
            <Route path='/' exact component={AuthLayout}/>
            <Route path='/login' exact component={AuthLayout}/>
            <Route path='/register' exact component={AuthLayout}/>
            <Route render={(props) => {
              if (userAuth.timeout()) {
                props.history.push('/');
              } else {
                return <Layout/>;
              }
            }}/>
          </Switch>
        </Router>
        <DetectMobile/>
      </Provider>
    </ConfigProvider>

  );
}

export default App;