import React from 'react';
import { Button, Modal, Space } from 'antd';
import styles from './AuthLayout.module.less';

class AgreementModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      company: ''
    }
  }

  componentDidMount() {
    const company = (window.website_basic_info_settings.company && window.website_basic_info_settings.company !== '') ? window.website_basic_info_settings.company : window.website_basic_info_settings.title;
    this.setState({company});
  }

  openModal = () => {
    this.setState({
      visible: true
    });
  }

  closeModal = () => {
    this.setState({id: null, visible: false});
  }

  agree = () => {
    this.props.onAccept?.();
    this.setState({visible: false});
  }

  notAgree = () => {
    this.props.onNotAccept?.();
    this.setState({visible: false});
  }

  render() {
    const footer = <Space>
      <Button onClick={this.notAgree}>不同意</Button>
      <Button type='primary' onClick={this.agree}>我已阅读并同意</Button>
    </Space>

    return <>
      <Modal
        title={<div className='agreement-title'>销售系统用户注册协议</div>}
        centered
        className='agreement-modal'
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={635}
        keyboard
        closable={false}
      >
        <div className={[styles.agreementContent, 'overflow-y-auto'].join(' ')}>
          <p>欢迎您（下又称“用户”）与{this.state.company}（下简称“本公司”或“{this.state.company}销售系统”）签署《{this.state.company}销售系统用户注册协议》（下称“本协议”）并使用{this.state.company}销售系统服务！为维护您自身权益，建议您仔细阅读各条款具体表述。各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。</p>
          <p>【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。
            请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。 如您对协议有任何疑问，可向平台客服咨询。</p>
          <p>【签约动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与{this.state.company}销售系统达成一致，成为{this.state.company}销售系统“用户”。
            阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</p>
          <dl>
            <dt>一、 定义</dt>
            <dd>{this.state.company}销售系统：指域名为[域名]的网站及客户端。</dd>
            <dd>{this.state.company}销售系统服务：本公司基于互联网，以包含{this.state.company}销售系统网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。</dd>
            <dd>{this.state.company}销售系统规则：包括在所有{this.state.company}销售系统内已经发布及后续发布的全部规则、实施细则、公告等内容。</dd>
            <dd>同一用户：使用同一身份认证信息或经{this.state.company}销售系统排查认定多个{this.state.company}销售系统账户的实际控制人为同一人或同一企业的，均视为同一用户。</dd>
          </dl>
          <dl>
            <dt>二、 协议范围</dt>
            <dd>2.1 签约主体</dd>
            <dd>本协议由您与本公司共同缔结，本协议对您与本公司均具有合同效力，自您完成注册程序后，即意味着您与本公司均受本协议的约束与管辖。您同意并理解，本公司有权根据{this.state.company}销售系统的运营需要，在提前30日以{this.state.company}销售系统在线公告的形式，将{this.state.company}销售系统的运营权限委托给其他关联公司。</dd>
            <dd>2.2补充协议</dd>
            <dd>由于互联网高速发展，本协议列明的条款并不能完整罗列并覆盖您与本公司的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，{this.state.company}销售系统发布的各项应用服务协议、法律声明及隐私权政策、{this.state.company}销售系统规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用{this.state.company}销售系统服务，视为您同意上述补充协议。</dd>
          </dl>
          <dl>
            <dt>三、 账户注册与使用</dt>
            <dd>3.1 用户资格</dd>
            <dd>您确认，在您开始注册程序使用{this.state.company}销售系统服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。<span
              className='improtspan'>若您未满18周岁或为其他限制民事行为能力人，应在法定代理人监护、指导下阅读本协议和使用本服务，且由法定代理人代表您完成注册和使用{this.state.company}销售系统服务，并代为您承担由此产生的法律责任和一切后果。若您是企业或其他组织的授权代表人，应提供经您所在企业或组织加盖公章或其他有效印鉴的书面授权文件，同时提供您所代表企业或组织的资质证照等资料完成注册和使用{this.state.company}销售系统服务，并由您所代表企业或组织承担由此产生的法律责任和一切后果。</span>
            </dd>
            <dd>3.2 用户行为规范</dd>
            <dd>3.2.1您理解并同意，{this.state.company}销售系统一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用{this.state.company}销售系统制作、复制、发布、传播如下干扰{this.state.company}销售系统正常运营，以及侵犯其他用户或第三方合法权益的内容：</dd>
            <dd>3.2.1.1发布、传送、传播、储存、销售违反国家法律法规禁止的内容：</dd>
            <dd>（1）违反宪法确定的基本原则的；</dd>
            <dd>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</dd>
            <dd>（3）损害国家荣誉和利益的；</dd>
            <dd>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</dd>
            <dd>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</dd>
            <dd>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</dd>
            <dd>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</dd>
            <dd>（8）侮辱或者诽谤他人，侵害他人合法权益的；</dd>
            <dd>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</dd>
            <dd>（10）以非法民间组织名义活动的；</dd>
            <dd>（11）含有法律、行政法规禁止的其他内容的。</dd>
            <dd>3.2.1.2 发布、传送、传播、储存、销售侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容和产品；</dd>
            <dd>3.2.1.3 涉及他人隐私、个人信息或资料的内容和产品；</dd>
            <dd>3.2.1.4 发表、传送、传播骚扰信息、广告信息及垃圾信息或含有任何性或性暗示的内容和产品；</dd>
            <dd>3.2.1.5 发布、传送、传播谣言、虚假信息或其他含有不实信息的内容和产品；</dd>
            <dd>3.2.1.6 其他违反法律法规、政策及公序良俗、社会公德或干扰{this.state.company}销售系统正常运营和侵犯其他用户或第三方合法权益内容的信息和产品。</dd>
            <dd>3.3 账户说明</dd>
            <dd>3.3.1账户获得</dd>
            <dd>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得{this.state.company}销售系统账户并成为{this.state.company}销售系统用户。</dd>
            <dd><span
              className='improtspan'>{this.state.company}销售系统只允许每位用户使用一个{this.state.company}销售系统账户。如有证据证明或{this.state.company}销售系统根据{this.state.company}销售系统规则判断您存在不当注册或不当使用多个{this.state.company}销售系统账户的情形，{this.state.company}销售系统可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给{this.state.company}销售系统及相关方造成损失的，您还应承担赔偿责任。</span>
            </dd>
            <dd>3.3.2账户使用</dd>
            <dd>您有权使用您设置或确认的手机号码（下称“账户名称”）及的密码（账户名称及密码合称“账户”）登录{this.state.company}销售系统。</dd>
            <dd>由于您的{this.state.company}销售系统账户关联您的个人、企业或其他组织信息及{this.state.company}销售系统商业信息，您的{this.state.company}销售系统账户仅限您本人或企业或其他组织的授权代表人使用。未经{this.state.company}销售系统同意，您直接或间接授权第三方使用您{this.state.company}销售系统账户或获取您账户项下信息的行为无效。如{this.state.company}销售系统根据{this.state.company}销售系统规则判断您{this.state.company}销售系统账户的使用可能危及您的账户安全及/或{this.state.company}销售系统信息安全的，{this.state.company}销售系统可拒绝提供相应服务或终止本协议。</dd>
            <dd>3.3.3账户转让</dd>
            <dd><span className='improtspan'>由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经{this.state.company}销售系统同意，并符合{this.state.company}销售系统规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。 除此外，您的账户不得以任何方式转让，否则{this.state.company}销售系统有权追究您的违约责任，且由此产生的一切责任均由您承担。</span>
            </dd>
            <dd>3.4注册信息管理</dd>
            <dd>3.4.1 实名认证</dd>
            <dd>作为{this.state.company}销售系统经营者，为使您更好地使用{this.state.company}销售系统的各项服务，保障您的账户安全，完善网络平台秩序的规范管理，{this.state.company}销售系统要求您在完成用户注册后必须按平台要求及我国法律规定完成实名认证，若您未按要求完成实名认证或提交的认证资料未通过审核，您将无法通过{this.state.company}销售系统进行任何交易。平台用户分个人用户和企业用户，用户实名认证的具体要求如下：</dd>
            <dd>3.4.1.1个人用户实名认证必须提交的审核材料：</dd>
            <dd>3.4.1.1 a)用户本人有效身份证件的正反面扫描件或拍照件；</dd>
            <dd>3.4.1.1 b)用户本人持本人有效身份证件的清晰拍照相片；</dd>
            <dd>3.4.1.1 c)联系方式等与开展本次业务相关的需要审核的信息。</dd>
            <dd>3.4.1.1 d)需要提供结算账号的，账号持有人信息必须与用户登记材料名称保持一致。</dd>
            <dd>3.4.1.2企业用户实名认证必须提交的审核材料：</dd>
            <dd>3.4.1.2 a)企业相关有效证件（营业执照，税务登记证，组织机构代码证、或三证合一的证件）的扫描件；</dd>
            <dd>3.4.1.2 b)与互联网交易、发行、运营相关的公司需提供有效的网络文化经营许可证复印件；</dd>
            <dd>3.4.1.2 c)与电信增值业务相关的需提供有效的增值电信业务经营许可证复印件；</dd>
            <dd>3.4.1.2 d)涉及到资金流转的，使用的银行账号名称须与注册主体名称保持一致。注册后需要变更的则需要提交该公司出具的变更申请书并加盖公司章的原件；</dd>
            <dd>3.4.1.2 e)经该企业或组织加盖公章或其他有效印鉴的授权书，以及授权代理人的身份证正反面扫描件或拍照件。</dd>
            <dd>3.4.2信息真实</dd>
            <dd>在使用{this.state.company}销售系统服务时，您应当按{this.state.company}销售系统页面的提示准确完整地提供您的信息（包括您的姓名、代表的企业名称、电子邮件地址、联系电话、联系地址等），以便{this.state.company}销售系统或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。</dd>
            <dd>3.4.3更新维护</dd>
            <dd>您应当及时更新您提供的信息，在法律有明确规定要求{this.state.company}销售系统作为平台服务提供者必须对部分用户（如平台卖家等）的信息进行核实的情况下，{this.state.company}销售系统将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</dd>
            <dd><span
              className='improtspan'>如{this.state.company}销售系统按您最后一次提供的信息与您联系未果、您未按{this.state.company}销售系统的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及{this.state.company}销售系统造成的全部损失与不利后果。{this.state.company}销售系统可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部{this.state.company}销售系统服务，{this.state.company}销售系统对此不承担责任。</span>
            </dd>
            <dd>3.5 账户开立与结算</dd>
            <dd>3.5.1 向第三方支付机构的授权</dd>
            <dd><span className='improtspan'>您通过注册{this.state.company}销售系统开展商品交易和资金收支服务的，您理解并同意授权{this.state.company}开发平台可以通过指定的第三方支付机构为您提供账户开立、账户充值、账户提现、资金划付、资金结算与清算、查询、对账等支付结算类服务。 为避免重复授权之不便，上述授权表明该第三方支付机构在使用或向有关机构提供您的信息时，可以依据上述授权径行使用或向源数据机构提供您的相关信息，而无需再逐一向您另行获取授权。</span>
            </dd>
            <dd>为了维护您的合法权益，请您在充分理解本授权的全部内容后确认本协议。{this.state.company}销售系统将依法对信息进行保密，履行信息安全保障义务。若您不接受本授权任何条款，请您立即停止授权。</dd>
            <dd><span
              className='improtspan'>本授权经您点击确认立即生效，且效力具有独立性，不因相关业务合同或条款无效或被撤销而无效或失效，本授权一经做出，便不可撤销。以上授权内容，您已经充分理解并同意，一经您点击确认或勾选时即视为签署。您自愿做出以上授权，并愿意承担由此所产生的一切法律后果。</span>
            </dd>
            <dd>3.5.2 账户开立</dd>
            <dd>您根据{this.state.company}销售系统的规则在平台注册个人账户时，您应如实的、完整的填写和提交您的身份信息，并保证您提交的信息的真实性和有效性。您理解并同意，{this.state.company}销售系统有权对您的身份信息进行任何形式的核验，对于不真实的身份信息，{this.state.company}销售系统有权拒绝提供任何服务。</dd>
            <dd>3.5.3 账户结算</dd>
            <dd>如您需要在{this.state.company}销售系统购买商品或服务，您应先对您在{this.state.company}销售系统的授权账户预存一定款项后方能完成订单交易。具体的预存流程请按{this.state.company}销售系统的页面提示进行操作。</dd>
            <dd>如您需要对授权账户余额申请退还预存款，请联系{this.state.company}销售系统客服并按客服提示进行操作。您需确保提交的提现账户账号持有人信息必须与您实名认证的名称保持一致，否则{this.state.company}销售系统有权不予办理退还。</dd>
            <dd>3.6账户安全规范</dd>
            <dd>3.6.1账户安全保管义务</dd>
            <dd>您的账户为您自行设置并由您保管，{this.state.company}销售系统任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开{this.state.company}销售系统。</dd>
            <dd><span
              className='improtspan'>账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，{this.state.company}销售系统并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。 </span></dd>
            <dd>3.6.2账户行为责任</dd>
            <dd><span
              className='improtspan'>除{this.state.company}销售系统存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）承担责任。</span>
            </dd>
            <dd>您充分了解并同意，您必须为自己注册帐号下的一切行为及由此产生的任何后果负责。您应对{this.state.company}销售系统上的内容自行加以判断，并承担因使用相关服务而引起的所有风险，包括因对服务内容的正确性、完整性或实用性的依赖而产生的风险。{this.state.company}销售系统无法且不会对因前述风险而导致的任何损失或损害承担责任。</dd>
            <dd>您充分了解并同意，不对平台、技术接口、本服务数据进行反向工程、反向编译或反汇编；不对平台、技术接口、本服务数据进行内容、功能、逻辑及界面等任何方面的修改；不得将平台、技术接口、本服务数据或业务用于本协议以外目的。</dd>
            <dd>3.6.3日常维护须知</dd>
            <dd>如发现任何未经授权使用您账户登录{this.state.company}销售系统或其他可能导致您账户遭窃、遗失的情况，建议您立即通知{this.state.company}销售系统。您理解{this.state.company}销售系统对您的任何请求采取行动均需要合理时间，且{this.state.company}销售系统应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除{this.state.company}销售系统存在法定过错外，{this.state.company}销售系统不承担责任。</dd>
          </dl>
          <dl>
            <dt>四、 {this.state.company}销售系统服务及规则</dt>
            <dd>4.1服务范围</dd>
            <dd>{this.state.company}销售系统为您提供虚拟充值商品的采购、销售、接口充值、代理及电商代运营等服务，请您根据您具体的业务需求在{this.state.company}销售系统的“应用管理”功能区申请相应的应用申请，并按照应用申请规则如实提交和填写相关信息。您理解并同意，{this.state.company}销售系统有权对您提交的应用申请及相关信息进行审核，并有权决定是否给您开通相应的应用权限和提供相应的服务。</dd>
            <dd>4.2服务内容、服务方式及结算</dd>
            <dd>{this.state.company}销售系统为您提供的应用服务模块包含“我要供货”、“充值API”、“批充批采”、“虚拟商城”、“电商商品对接”及“物流服务平台”等后期新增的服务。您理解并同意，根据您申请的不同应用，{this.state.company}销售系统会为您提供不同的服务内容、服务方式和结算方式，具体服务内容、服务方式和结算方式等内容以您在申请应用时与{this.state.company}销售系统签订的《平台应用服务协议》中的约定为准。</dd>
            <dd>4.3、服务规则</dd>
            <dd>4.3.1<span
              className='improtspan'>您理解并同意，{this.state.company}销售系统提供的商品为虚拟充值商品，您在接收到该虚拟充值商品时，应第一时间对商品的名称/内容、价格、数量、形式进行确认。如在收到虚拟充值商品后的24小时内未向{this.state.company}销售系统提出异议，则视为您对收到的商品验收通过。除因{this.state.company}销售系统自身原因导致您不能按照前述规定进行验收外，{this.state.company}销售系统将不承担您未及时对该虚拟充值商品进行确认和验收的任何风险和损失。</span>
            </dd>
            <dd>4.3.2您应确保其提供的充值账号信息真实准确，且满足虚拟充值商品官方对于充值账号的管理规则和要求，
              {this.state.company}销售系统提供的线上直充服务一旦充值成功，{this.state.company}销售系统不接受任何形式的退换货要求。您理解并同意，如因您提供的充值账号有误或不满足官方要求导致的充值失败、错充等情况，{this.state.company}销售系统已提供的充值服务将无法撤回，亦不能退换货，由此产生的全部损失由您自行承担。
            </dd>
            <dd>4.3.3您通过{this.state.company}销售系统提取虚拟充值商品为卡密商品的，
              您应在该卡密商品规定的有效期内使用该卡密信息，若您未在有效期内使用导致该卡密商品过期无法使用的，{this.state.company}销售系统将不会承担责任，且{this.state.company}销售系统无义务为您续期或更换新的卡密商品。
            </dd>
            <dd>4.3.4<span className='improtspan'>您理解并同意，您通过{this.state.company}销售系统提取虚拟充值商品为卡密商品的，该卡密商品一经提出其泄露、失效等风险将由您承担。</span>如{this.state.company}销售系统提供的卡密商品在使用中出现卡密失效或异常的问题，您有权向{this.state.company}销售系统进行核实，若该卡密商品在{this.state.company}销售系统交付给您之前就已经失效或异常（以{this.state.company}销售系统记载的卡密商品提取时间为准），{this.state.company}销售系统将根据不同的卡密商品给予您退换货、重新激活或退款等不同标准的售后质保服务。{this.state.company}销售系统为您提供一定期限的售后质保期，售后质保期自您提取卡密数据之日起算，如您未在售后质保期内对卡密质量问题提出异议，则视为{this.state.company}销售系统交付您的卡密数据符合约定的要求且无任何质量问题。如您在售后质保期后主张任何卡密质量问题，{this.state.company}销售系统将不予处理。
            </dd>
            <dd>4.3.5您理解并同意，若因{this.state.company}销售系统系统自身故障原因造成实际交易金额与订单金额不符，{this.state.company}销售系统有权对交易数据进行复核（复核可由您在提供合法有效凭证的前提下发起，或由{this.state.company}销售系统在发现数据异常后直接发起），并根据用户交易情况作出以下处理措施：</dd>
            <dd>4.3.5 a)因充值系统自身故障原因，造成系统交易金额小于用户实际充值额的，{this.state.company}销售系统予以补其差额。</dd>
            <dd>4.3.5 b)因充值系统自身故障原因，造成系统交易金额大于用户实际充值额的，{this.state.company}销售系统有权要求您支付差额费用。</dd>
            <dd>4.3.6<span
              className='improtspan'>您理解并同意，因上游供货商或商品发行商、运营商对商品的销售政策进行调整和管控，导致{this.state.company}销售系统方无法按您的订单指令提供服务的，{this.state.company}销售系统不承担责任，您可就未完成订单的金额申请退款。 </span>
            </dd>
            <dd>4.3.7<span
              className='improtspan'>您理解并同意，如您对{this.state.company}销售系统交付的商品进行二次交易的，涉及您与其消费客户之间的售后服务、客户投诉等与交易行为相关的事项均由您自行负责，{this.state.company}销售系统不承担由此导致的责任和风险。</span>
            </dd>
            <dd>4.3.8<span
              className='improtspan'>您理解并同意，根据交易类型的不同，{this.state.company}销售系统对每笔交易的开票规则亦不相同，如您有开具发票的需求，请联系{this.state.company}销售系统客服并按其提示进行开票申请操作。</span>
            </dd>
            <dd>4.4 平台禁止事项</dd>
            <dd>4.4.1<span
              className='improtspan'>如您需对{this.state.company}销售系统交付的商品进行二次交易的，必须严格遵守{this.state.company}销售系统及商品上游供应商或发行商制定的价格管控体系和其他限制性要求。因您违反本协议约定，导致{this.state.company}销售系统被上游供应商、发行商或其他第三方追责或索赔的，由您全权负责处理，且您应赔偿{this.state.company}销售系统由此造成的损失。</span>
            </dd>
            <dd>4.4.2<span
              className='improtspan'>未经{this.state.company}销售系统书面许可，您不得以任何形式表明自己是{this.state.company}销售系统、本协议所涉信息服务产品上游供应商、发行商的代理商或加盟商，不得使用{this.state.company}销售系统、本协议所涉信息服务产品供应商、发行商的商标或商业标识。 </span>
            </dd>
            <dd>4.4.3<span
              className='improtspan'>您不得利用{this.state.company}销售系统基于本协议交付的商品或服务直接或间接从事或涉及任何洗钱、销赃、隐瞒犯罪所得、赌博、恶意囤货等法律明文禁止的行为，因此给{this.state.company}销售系统造成的一切损失均由您负责赔偿。</span>
            </dd>
            <dd>4.4.4<span className='improtspan'>您不得从事违反虚拟商品充值服务市场交易规则以及违反{this.state.company}销售系统交易规则和服务规则的事宜。</span></dd>
            <dd>4.5责任限制</dd>
            <dd><span
              className='improtspan'>{this.state.company}销售系统依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，{this.state.company}销售系统并不承担相应的违约责任：</span>
            </dd>
            <dd>（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素； ；</dd>
            <dd>（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</dd>
            <dd>（三）在{this.state.company}销售系统已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</dd>
          </dl>
          <dl>
            <dt>五、 用户信息的保护及授权</dt>
            <dd>{this.state.company}销售系统非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用{this.state.company}销售系统提供的服务时，您同意{this.state.company}销售系统按照在{this.state.company}销售系统上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。{this.state.company}销售系统希望通过隐私权政策向您清楚地介绍{this.state.company}销售系统对您个人信息的处理方式，因此{this.state.company}销售系统建议您完整地阅读{this.state.company}销售系统规则中的隐私权政策，以帮助您更好地保护您的隐私权。</dd>
          </dl>
          <dl>
            <dt>六、 用户的违约及处理</dt>
            <dd>6.1 违约认定</dd>
            <dd>发生如下情形之一的，视为您违约：</dd>
            <dd>（一）使用{this.state.company}销售系统服务时违反有关法律法规规定的；</dd>
            <dd>（二）违反本协议或本协议补充协议约定的。</dd>
            <dd>为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意， {this.state.company}销售系统可在{this.state.company}销售系统规则中约定违约认定的程序和标准。您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。
            </dd>
            <dd>6.2 违约处理措施</dd>
            <dd><span
              className='improtspan'>您在{this.state.company}销售系统上实施的行为，或虽未在{this.state.company}销售系统上实施但对{this.state.company}销售系统及其用户产生影响的行为构成违约的，{this.state.company}销售系统可依据相应规则对您执行中止向您提供部分或全部服务、从您账户余额划扣违约金等处理措施。如您的行为构成根本违约的，{this.state.company}销售系统可查封您的账户，终止向您提供服务。 </span>
            </dd>
            <dd>6.3赔偿责任</dd>
            <dd><span
              className='improtspan'>如您的行为使{this.state.company}销售系统及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿{this.state.company}销售系统及/或其关联公司的上述全部损失。 </span>
            </dd>
            <dd><span
              className='improtspan'>如您的行为使{this.state.company}销售系统及/或其关联公司遭受第三人主张权利，由您直接向第三人承担责任，如{this.state.company}销售系统及/或其关联公司已向第三人承担金钱给付等义务，您应就{this.state.company}销售系统及/或其关联公司的全部损失进行赔偿。</span>
            </dd>
            <dd>6.4反商业贿赂条款</dd>
            <dd>如您向{this.state.company}销售系统及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。
              发生上述情形的，{this.state.company}销售系统可立即终止与您的所有合作并向您收取违约金及/或赔偿金， 该等金额以{this.state.company}销售系统因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
            </dd>
          </dl>
          <dl>
            <dt>七、 协议的变更</dt>
            <dd>{this.state.company}销售系统可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过{this.state.company}销售系统不定时的系统公告方式通知您。</dd>
            <dd>如您不同意变更事项，您有权于变更事项确定的生效日前联系{this.state.company}销售系统反馈意见。如反馈意见得以采纳，{this.state.company}销售系统将酌情调整变更事项。</dd>
            <dd>如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用{this.state.company}销售系统服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用{this.state.company}销售系统服务，则视为您同意已生效的变更事项。</dd>
          </dl>
          <dl>
            <dt>八、 通知</dt>
            <dd>8.1有效联系方式</dd>
            <dd>您在注册成为{this.state.company}销售系统用户，并接受{this.state.company}销售系统服务时，您应该向{this.state.company}销售系统提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</dd>
            <dd>{this.state.company}销售系统将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</dd>
            <dd>{this.state.company}销售系统将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</dd>
            <dd>8.2 通知的送达</dd>
            <dd>{this.state.company}销售系统通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在{this.state.company}销售系统公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</dd>
            <dd>对于在{this.state.company}销售系统上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在{this.state.company}销售系统注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</dd>
            <dd>您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</dd>
            <dd>您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</dd>
            <dd>你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</dd>
          </dl>
          <dl>
            <dt>九、 协议的终止</dt>
            <dd>9.1 终止的情形</dd>
            <dd>9.1.1用户发起的终止</dd>
            <dd>您有权通过以下任一方式终止本协议：</dd>
            <dd>（一）在满足{this.state.company}销售系统网公示的账户注销条件时您通过网站自助服务注销您的账户的；</dd>
            <dd>（二）变更事项生效前您停止使用并明示不愿接受变更事项的；</dd>
            <dd>（三）您明示不愿继续使用{this.state.company}销售系统服务，且符合{this.state.company}销售系统终止条件的。</dd>
            <dd>9.1.2{this.state.company}销售系统发起的终止</dd>
            <dd>出现以下情况时，{this.state.company}销售系统可以本协议第八条的所列的方式通知您终止本协议：</dd>
            <dd>（一）您违反本协议约定，{this.state.company}销售系统依据违约条款终止本协议的；</dd>
            <dd>（二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，{this.state.company}销售系统依据{this.state.company}销售系统规则对您的账户予以查封的；</dd>
            <dd>（三）您的账户被{this.state.company}销售系统依据本协议回收的；</dd>
            <dd>（四）其它应当终止服务的情况。</dd>
            <dd>9.2 协议终止后的处理</dd>
            <dd>9.2.1本协议终止后，{this.state.company}销售系统仍享有下列权利：</dd>
            <dd>（一）继续保存您留存于{this.state.company}销售系统的本协议第五条所列的各类信息；</dd>
            <dd>（二）对于您过往的违约行为，{this.state.company}销售系统仍可依据本协议向您追究违约责任。</dd>
            <dd>9.2.2 本协议终止后，除法律有明确规定外，{this.state.company}销售系统无义务向您或您指定的第三方披露您账户中的任何信息。</dd>
          </dl>
          <dl>
            <dt>十、 法律适用、管辖与其他</dt>
            <dd>10.1本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</dd>
            <dd>10.2您因使用{this.state.company}销售系统服务所产生及与{this.state.company}销售系统服务有关的争议，由{this.state.company}销售系统与您协商解决。协商不成时，任何一方均可向湖北省武汉市东湖新技术开发区人民法院提起诉讼。</dd>
            <dd>10.3本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</dd>
          </dl>
        </div>
      </Modal>
    </>
  }
}

export default AgreementModal;