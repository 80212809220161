import React from 'react';
import { Button, Card, Col, Row, Space, Statistic, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import youyuUtils from '../../utils/YouyuUtils';
import { Column } from '@ant-design/charts';

import ordersImg from './asserts/orders.png';
import balanceRecords from './asserts/balance-records.png';
import safeImg from './asserts/safe.png';
import chargeImg from './asserts/charge.png';
import axios from '../../request/axios';

const {Link, Text} = Typography;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats_loading: true,
      stats: []
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({stats_loading: true});
    axios.get('orders-stats-by-day').then((res) => {
      const {data} = res.data;
      this.setState({stats: data});
    }).finally(() => {
      this.setState({stats_loading: false});
    });
  }

  render() {
    return <div>
      <Card
        title={<Text>我的钱包</Text>}>
        <Row gutter={16}>
          <Col span={3}>
            <Statistic title='账号余额（元）' groupSeparator='' value={youyuUtils.parseAmount(this.props.balance) ?? '-'}
                       valueStyle={{color: 'rgba(0, 0, 0, 0.7)', fontWeight: 'bold'}}/>
          </Col>
          <Col span={4}>
            <div className='h-full flex items-end' style={{paddingBottom: 5}}>
              <Button size='small' type='primary' onClick={() => this.props.history.push('/charge-balance')}>充值</Button>
            </div>
          </Col>
          <Col span={7}>
            <Statistic title={<Text type='secondary'>冻结资金（元）<Tooltip title={'冻结资金无法使用，需解冻转为余额后才可以消费'}><Text
              className='cursor-pointer' type='secondary'><QuestionCircleOutlined/></Text></Tooltip></Text>}
                       groupSeparator='' value={youyuUtils.parseAmount(this.props.frozen_balance) ?? '-'}
                       valueStyle={{color: 'rgba(0, 0, 0, 0.7)', fontWeight: 'bold'}}/>
          </Col>
          <Col span={7}>
            <Statistic title='可用授信（元）' groupSeparator='' value={0} precision={2}
                       valueStyle={{color: 'rgba(0, 0, 0, 0.7)', fontWeight: 'bold'}}/>
          </Col>
        </Row>
      </Card>

      <Card title={<Text>消费趋势</Text>} style={{marginTop: 20, height: 500}}>
        <div className='mt-4 p-4'>
          <Column
            height={300}
            data={this.state.stats ?? []}
            xField='date'
            yField='total_price'
            loading={this.state.stats_loading}
            yAxis={{
              visible: true,
              title: {
                visible: false
              }
            }}
            title={{
              visible: true,
              text: '销售趋势',
              style: {
                fontSize: 14
              }
            }}
            meta={{
              total_price: {
                alias: '销售额'
              }
            }}
          />
        </div>
      </Card>

      <Card title='常用功能' style={{marginTop: 20}}>
        <Space size={48} style={{justifyContent: 'center', width: '100%'}}>
          <div className='cursor-pointer hover:text-primary' onClick={() => this.props.history.push('/orders')}>
            <img style={{width: 64}} src={ordersImg} alt='消费记录'/>
            <div className='mt-2 text-center'>消费记录</div>
          </div>
          <div className='cursor-pointer hover:text-primary' onClick={() => this.props.history.push('/charge-balance')}>
            <img style={{width: 64}} src={chargeImg} alt='余额充值'/>
            <div className='mt-2 text-center'>余额充值</div>
          </div>
          <div className='cursor-pointer hover:text-primary' onClick={() => this.props.history.push('/charge-balance')}>
            <img style={{width: 64}} src={balanceRecords} alt='财务记录'/>
            <div className='mt-2 text-center'>财务记录</div>
          </div>
          <div className='cursor-pointer hover:text-primary'
               onClick={() => this.props.history.push('/security/login_psw')}>
            <img style={{width: 64}} src={safeImg} alt='安全设置'/>
            <div className='mt-2 text-center'>安全设置</div>
          </div>
        </Space>
      </Card>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    balance: state.balance,
    frozen_balance: state.frozen_balance
  }
}

export default connect(mapStateToProps)(Dashboard);