const OrderStatusTitle = (props) => {
  if(props.status === '100')
    return <span>等待发货</span>
  else if(props.status === '200')
    return <span>交易成功</span>
  else if(props.status === '500')
    return <span>交易失败</span>
  else if(props.status === '300')
    return <span>可疑订单</span>
  else
    return <span>-</span>
}
export default OrderStatusTitle;