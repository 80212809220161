import React from 'react';
import { DatePicker, Radio, Space } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';

const {RangePicker} = DatePicker;

class DateRangePicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date_range: 'today'
    };
  }

  static today() {
    const today = moment().startOf('day');
    return [today, today];
  }

  static yesterday() {
    const yesterday = moment().subtract(1, 'days').startOf('day');
    return [yesterday, yesterday];
  }

  static lastWeek() {
    return [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'weeks').endOf('week')];
  }

  static thisWeek() {
    return [moment().startOf('week'), moment().startOf('day')];
  }

  static lastMonth() {
    return [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')];
  }

  static thisMonth() {
    return [moment().startOf('month'), moment().startOf('day')];
  }

  static recent7Days() {
    return [moment().subtract(6, 'days').subtract(-1, 'milliseconds'), moment().startOf('day')];
  }

  static recent30Days() {
    return [moment().subtract(29, 'days').subtract(-1, 'milliseconds'), moment().startOf('day')];
  }

  onChange = (value) => {
    this.props.onChange?.(value);
  }

  onRadioButtonChange = (e) => {
    const value = e.target.value;
    let date;
    if(value === 'today') {
      date = DateRangePicker.today();
    } else if(value === 'yesterday') {
      date = DateRangePicker.yesterday();
    } else if(value === 'lastWeek') {
      date = DateRangePicker.lastWeek();
    } else if(value === 'thisWeek') {
      date = DateRangePicker.thisWeek();
    } else if(value === 'lastMonth') {
      date = DateRangePicker.lastMonth();
    } else if(value === 'thisMonth') {
      date = DateRangePicker.thisMonth();
    } else if(value === 'recent7Days') {
      date = DateRangePicker.recent7Days();
    } else if(value === 'recent30Days') {
      date = DateRangePicker.recent30Days();
    }
    this.props.onChange?.(date);
    this.setState({date_range: value});
  }

  render() {
    const radioButtonStyle = {paddingLeft: 8, paddingRight: 8};
    return <Space size='middle'>
      <RangePicker allowClear={false} value={this.props.value} onChange={this.onChange} style={{width: 220}}/>
      <Radio.Group buttonStyle='solid' value={this.state.date_range} onChange={this.onRadioButtonChange}>
        <Radio.Button style={radioButtonStyle} value='today'>今天</Radio.Button>
        <Radio.Button style={radioButtonStyle} value='yesterday'>昨天</Radio.Button>
      </Radio.Group>
      <Radio.Group buttonStyle='solid' value={this.state.date_range} onChange={this.onRadioButtonChange}>
        <Radio.Button style={radioButtonStyle} value='lastWeek'>上周</Radio.Button>
        <Radio.Button style={radioButtonStyle} value='thisWeek'>本周</Radio.Button>
        <Radio.Button style={radioButtonStyle} value='lastMonth'>上月</Radio.Button>
        <Radio.Button style={radioButtonStyle} value='thisMonth'>本月</Radio.Button>
      </Radio.Group>
      <Radio.Group buttonStyle='solid' value={this.state.date_range} onChange={this.onRadioButtonChange}>
        <Radio.Button style={radioButtonStyle} value='recent7Days'>近7天</Radio.Button>
        <Radio.Button style={radioButtonStyle} value='recent30Days'>近30天</Radio.Button>
      </Radio.Group>
    </Space>
  }
}

export default DateRangePicker;