const ModelConstants = {
  USER_TYPE: {
    MAIN: '1',
    STAFF: '2'
  },
  PRODUCT_TYPE: {
    CHARGE: '1',
    CARD: '2'
  },
  PRODUCT_PARAMS_TEMPLATE_TYPE: {
    USER_DEFINED: '1',
    PREDEFINED: '2'
  },
  ORDER_STATUS: {
    DEALING: '100',
    SUCCESS: '200',
    UNCONFIRMED: '300',
    FAIL: '500'
  },
  SUPPLY_MODE: {
    API: '1',
    VENDOR: '2',
    CARD_INVENTORY: '3',
    MS_SUP: '4'
  },
  ORDER_COMPLAINT_STATUS: {
    UNHANDLED: '1',
    IN_HANDLE: '2',
    FINISH: '3'
  },
  APPLY_REFUND_TYPE: {
    NO_REFUND: '1',
    WHOLE_REFUND: '2',
    PART_REFUND: '3'
  },
  CUSTOMER_SHOP_TYPE: {
    TAOBAO: '1',
    PINDUODUO: '2',
    TIANMAO: '3',
    JINGDONG: '4',
  },
  ONLINE_CHARGE_STATUS: {
    WAIT_FOR_CHARGE: '1',
    SUCCESS: '2',
    FAIL: '3'
  },
  CUSTOMER_STATUS: {
    ENABLE: '1',
    DISABLE: '2',
    WAITING_FOR_APPROVAL: '3'
  }
};

export default ModelConstants;