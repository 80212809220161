import {Button, Form, Input, message as antMessage, Modal, Popconfirm, Space, Spin, Typography} from 'antd';
import React from 'react';
import axios from '../../request/axios';

const {Text} = Typography;

class ApiKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form_submitting: false
    };
  }

  onFinish = (values) => {
    // this.setState({form_submitting: true});
    // axios.post('security/update-deal-password', values).then(res => {
    //   const {code, message, data} = res.data;
    //   if (!code) {
    //     antMessage.success('修改成功');
    //     this.formRef && this.formRef.resetFields();
    //   } else {
    //     antMessage.error(message);
    //   }
    // }).finally(() => {
    //   this.setState({form_submitting: false});
    // });
  };

  resetApiKey = () => {
    this.formRef.validateFields().then(value => {
      this.setState({form_submitting: true});
      return axios.post('security/api-key/reset', value).then((res) => {
        const {data} = res.data;
        if (!res.data.code) {
          Modal.info({
            content: <Text>API秘钥已重置为<Text copyable={{tooltips: ['复制', '复制成功']}}
                                          mark>{data}</Text>，请复制保存</Text>,
            okText: '确定'
          })
        } else {
          Modal.destroyAll();
          antMessage.error(res.data.message);
        }
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    });
  };

  getApiKey = () => {
    this.formRef.validateFields().then(value => {
      this.setState({form_submitting: true});
      return axios.post('security/api-key/get', value).then((res) => {
        const {data} = res.data;
        if (!res.data.code) {
          Modal.info({
            content: <Text>您的API秘钥为<Text copyable={{tooltips: ['复制', '复制成功']}}
                                          mark>{data}</Text>，请复制保存</Text>,
            okText: '确定'
          })
        } else {
          antMessage.error(res.data.message);
        }
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    });

  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form ref={ref => this.formRef = ref} labelCol={{span: 6}} wrapperCol={{span: 14}}>
          <Form.Item
            label='交易密码'
            name='deal_password'
            rules={[
              {required: true}
            ]}
          >
            <Input.Password style={{width: 200}}/>
          </Form.Item>

          <Form.Item wrapperCol={{
            offset: 6,
            span: 10
          }}>
            <Space>
              <Button type='primary' onClick={this.getApiKey} loading={this.state.form_submitting}>查看接口密钥</Button>
              <Popconfirm
                title="确认要重置API秘钥吗？"
                onConfirm={this.resetApiKey}
                okText="是"
                cancelText="否"
              >
                <Button type='primary' loading={this.state.form_submitting}>重置接口秘钥</Button>
              </Popconfirm>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

export default ApiKey;
