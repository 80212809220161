import React from 'react';
import { message, Typography, Upload } from 'antd';
import userAuth from '../auth/UserAuth';

const {Link, Text} = Typography;

class MultiImgUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      fileList: []
    }
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isJpgOrPng) {
      message.error('只能上传jpg或png格式的图片');
    } else if (!isLt5M) {
      message.error('图片必须小于5M');
    }
    if (isJpgOrPng && isLt5M)
      return true;
    else
      return Upload.LIST_IGNORE;
  };

  handleChange = (info) => {
    const {file, fileList} = info;

    if (file.status === 'uploading') {
      this.setState({uploading: true});
    }
    if (file.status === 'done') {
      this.setState({uploading: false});
      const {code} = info.file.response;
      if (code) {
        fileList.splice(fileList.indexOf(file), 1);
        message.error('上传失败，图片文件不合要求');
      }
    }

    this.setState({fileList: [...fileList]}, this.notifyChange);
  }

  notifyChange = () => {
    const {fileList} = this.state;
    let img_url_list = [];
    fileList.forEach(file => {
      if (file.status === 'done') {
        img_url_list.push(file.response.data.img_url);
      }
    })
    this.props.onChange(img_url_list);
  }

  render() {
    return <Upload
      name='img_file'
      headers={{Ftoken: userAuth.token()}}
      accept='image/*'
      listType='picture'
      maxCount={3}
      fileList={this.state.fileList}
      action={process.env.REACT_APP_API_BASE_URL + 'order-complaints/upload-image'}
      className='mt-1'
      multiple
      showUploadList={{showPreviewIcon: false}}
      beforeUpload={this.beforeUpload}
      onChange={this.handleChange}
    >
      {this.state.fileList.length >= 3 ? null : <Link>上传图片</Link>} <Text style={{fontSize: '0.875em'}}
                                                                         type='secondary'>最多上传3张</Text>
    </Upload>
  }
}

export default MultiImgUpload;