import React from 'react';
import {Button, Modal, Space} from 'antd';
import QRCode from 'qrcode.react';

import style from './asserts/WechatPayModal.module.less';
import paySuccessIcon from './asserts/pay_success_icon.svg';
import scanIcon from './asserts/scan_icon.svg';
import Money from '../../components/Money';
import axios from "../../request/axios";
import ModelConstants from "../../constants/ModelConstants";

class WechatPayModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      pay_amount: 0,
      url: '',
      loading: false,
      visible: false,
      status: '1'
    }
  }

  openModal = (data) => {
    this.setState({
      id: data.id,
      pay_amount: data.pay_amount,
      url: data.url,
      visible: true
    });
    this.detectStatus();
  };

  closeModal = () => {
    this.setState({id: null, visible: false});
    this.stopDetectStatus();
  };

  detectStatus = () => {
    if (!this.detect_status_interval_id) {
      this.detect_status_interval_id = setInterval(() => {
        axios.get('charge-balance/online-charge-record-status?id=' + this.state.id).then((res) => {
          const {data} = res.data;
          this.setState({status: data.status});
          if (data.status !== ModelConstants.ONLINE_CHARGE_STATUS.WAIT_FOR_CHARGE) {
            this.stopDetectStatus();
          }
        });
      }, 10 * 1000);
    }
  };

  stopDetectStatus = () => {
    clearInterval(this.detect_status_interval_id);
    this.detect_status_interval_id = null;
  };

  render() {
    const footer = <Space>
      <Button onClick={this.closeModal}>取消</Button>
    </Space>

    return <>
      <Modal
        title='请用微信扫码支付'
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={600}
        centered={true}
      >
        <div className='text-center'>
          <div className={style.title}>支付金额 <Money value={this.state.pay_amount}/> 元</div>
          <QRCode
            value={this.state.url}  //value参数为生成二维码的链接
            size={200} //二维码的宽高尺寸
            fgColor="#000000"  //二维码的颜色
            style={{margin: '4px auto'}}
          />
          {
            this.state.status === ModelConstants.ONLINE_CHARGE_STATUS.WAIT_FOR_CHARGE &&
            <>
              <div className={style.scanTip}><img style={{width: 32}} src={scanIcon} alt='scan'/><p>请使用微信扫描<br/>二维码完成支付</p></div>
              <div className={[style.payResult, 'animate-pulse'].join(' ')}>等待支付</div>
            </>
          }
          {
            this.state.status === ModelConstants.ONLINE_CHARGE_STATUS.SUCCESS &&
              <div className={style.payResult}><img src={paySuccessIcon} alt='success'/> 支付成功</div>
          }
        </div>
      </Modal>
    </>
  }
}

export default WechatPayModal;