const DetectMobile = (props) => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    if (window.confirm('是否跳转到手机版网站？')) {
      window.location = '/m#/login';
    }
  }
  return <></>
}

export default DetectMobile;
