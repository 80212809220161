import React from 'react';
import {Cascader, Form, Input} from 'antd';
import RadioTags from '../../../components/RadioTags';
import ChargeAccountFormItem from "./ChargeAccountFormItem";

class PredefinedTemplateFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      game_list: [],
      game_obj: null
    }
  }

  componentDidMount() {
    let game_list = this.props.template.game_list;
    game_list = game_list ? [...game_list] : [];

    // 设置game初始值
    this.setState({game_list}, () => {
      if (game_list.length > 0) {
        if (game_list[0].value)
          this.handleGameChanged(game_list[0].value);
        else
          this.setState({game_obj: game_list[0]});
      }
    });
  }

  getFieldsValue = () => {
    let values = this.formRef.getFieldsValue();
    const {area_server} = values;
    if (area_server) {
      values.area = area_server.length > 0 ? area_server[0] : null;
      values.server = area_server.length > 1 ? area_server[1] : null;
      delete values.area_server;
    }
    return values;
  }

  validateFields = () => {
    return this.formRef.validateFields();
  }

  resetFields = () => {
    this.formRef && this.formRef.resetFields();
  }

  handleGameChanged = (value) => {
    // 设置game且重置各项值
    this.formRef && this.formRef.setFieldsValue({
      game: value,
      charge_account_type: null,
      charge_mode: null,
      area_server: null
    });

    // 设置game_obj
    this.state.game_list.forEach(game => {
      if (game.value === value) {
        this.setState({
          game_obj: game
        }, () => this.setDefaultChargeParams(game));
      }
    })
  }

  // game改变后，将其下关联参数设置为默认参数
  setDefaultChargeParams = (game_obj) => {
    let charge_account_type = null;
    let charge_mode = null;
    let area_server = null;

    const {charge_account_type_list, charge_mode_list} = game_obj;

    if (game_obj) {
      // 默认选择唯一账号类型
      if (charge_account_type_list && charge_account_type_list.length === 1) {
        charge_account_type = charge_account_type_list[0].value;
      }

      // 默认选择唯一充值方式
      if (charge_mode_list && charge_mode_list.length === 1) {
        charge_mode = charge_mode_list[0].value;
      }

      // 辉煌提出要求修改默认区服：金山-剑侠世界设置默认充值剑世区
      if (game_obj.label === '剑侠世界' && game_obj.value === 'jxsj2') {
        area_server = ['z01'];
      }
    }

    this.formRef.setFieldsValue({
      charge_account_type: charge_account_type,
      charge_mode: charge_mode,
      area_server: area_server
    });
  }

  render() {
    let {game_obj, game_list} = this.state;
    const props = {disabled: this.props.disabled};

    return <Form ref={form => this.formRef = form} layout='vertical'
                 className='form-compact' requiredMark={false}>
      {
          game_list.length === 0 && this.chargeAccountFormItem()
      }
      {
          game_list.length === 1 &&
          <Form.Item name='game' value={this.state.game} hidden>
            <Input {...props}/>
          </Form.Item>
      }
      {
          game_list.length > 1 &&
          <>
            <Form.Item name='game' label='游戏' rules={[{required: true, message: '请选择游戏'}]}>
              <RadioTags options={game_list} onChange={this.handleGameChanged}/>
            </Form.Item>
          </>
      }
      {
          game_obj && game_obj.charge_account_type_list && game_obj.charge_account_type_list.length > 0 &&
          <Form.Item name='charge_account_type' label='账号类型' rules={[{required: true, message: '请选择账号类型'}]}>
            <RadioTags options={game_obj.charge_account_type_list}/>
          </Form.Item>
      }
      {
          game_obj && game_obj.charge_mode_list && game_obj.charge_mode_list.length > 0 &&
          <Form.Item name='charge_mode' label='充值方式' rules={[{required: true, message: '请选择充值方式'}]}>
            <RadioTags options={game_obj.charge_mode_list}/>
          </Form.Item>
      }
      {
          game_obj && game_obj.area_list && game_obj.area_list.length > 0 &&
          <Form.Item name='area_server' label='区服' rules={[{required: true, message: '请选择区服'}]}>
            <Cascader {...props} placeholder='输入区服名称搜索' showSearch options={game_obj.area_list}
                      fieldNames={{children: 'server_list'}} style={{width: 400}}/>
          </Form.Item>
      }
      {
          game_obj && this.chargeAccountFormItem(game_obj.charge_account_name)
      }
    </Form>
  }

  chargeAccountFormItem = (label = null) => {
    return <ChargeAccountFormItem
        label={label}
        onImportedChargeAccounts={this.props.onImportedChargeAccounts}
        disabled={this.props.disabled}/>
  }
}

export default PredefinedTemplateFields;