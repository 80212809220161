import React from 'react';
import {Button, Modal, Space, Table, Typography, message} from 'antd';
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import YouyuUtils from "../../../utils/YouyuUtils";

const {Text, Link} = Typography;

class ChargeAccountsImportModal extends React.Component {

  initState = {
    visible: false,
    result: null
  }

  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  openModal = (result) => {
    this.setState({
      ...this.initState,
      visible: true,
      result
    });
  };

  closeModal = () => {
    this.setState({...this.initState, visible: false});
    this.props?.onClose();
  };

  copyFailChargeAccount = withQuantity => {
    const {result} = this.state;
    let text = '';
    result.results.forEach(item => {
      if(!item.order_id) {
        text += item.charge_account;
        if(withQuantity) {
          text += '---' + item.quantity;
        }
        text += '\n';
      }
    })
    YouyuUtils.copyTextToClipboard(text);
    message.success('复制成功');
  }

  render() {
    const {result} = this.state;
    if (!result) {
      return <></>
    }

    const {success_count, fail_count, results} = result;

    const footer = <div className='flex justify-end'>
      <Space>
        <Link onClick={() => this.copyFailChargeAccount(false)}>复制失败账号</Link>
        <Link onClick={() => this.copyFailChargeAccount(true)}>复制失败账号(含数量)</Link> &nbsp;
        <Button type='primary' onClick={this.closeModal}>确认</Button>
      </Space>
    </div>;

    return <>
      <Modal
          title='批量提交结果'
          destroyOnClose
          footer={footer}
          visible={this.state.visible}
          onCancel={this.closeModal}
          width={600}
          keyboard
          zIndex={1000}
      >
        <div className='mb-3'>
          <Text><CheckCircleFilled style={{color: '#52C41A'}}/> 成功提交{success_count}笔订单</Text>
          {
              parseInt(fail_count) !== 0 &&
              <Text> &nbsp;&nbsp;&nbsp; <CloseCircleFilled style={{color: '#FF4D4F'}}/> {fail_count}笔订单提交失败</Text>
          }
        </div>
        <div className='overflow-y-auto' style={{height: 400}}>
          <Table rowKey='charge_account' dataSource={results} size='small' pagination={false}>
            <Table.Column title='账号' dataIndex='charge_account' key='charge_account'></Table.Column>
            <Table.Column title='数量' dataIndex='quantity' key='quantity'></Table.Column>
            <Table.Column title='结果' dataIndex='status_info' key='status_info' render={(value, record) => {
              if (record.order_id)
                return <Text type='success'>提交成功</Text>
              else
                return <Text type='danger'>{record.status_info}</Text>
            }}></Table.Column>
          </Table>
        </div>
      </Modal>
    </>
  }
}

export default ChargeAccountsImportModal;