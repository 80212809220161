import { Card } from 'antd';
import React from 'react';
import TaobaoMappings from './TaobaoMappings';
import TianmaoMappings from './TianmaoMappings';
import JingdongMappings from './JingdongMappings';

class CustomerShopOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: null
    };
  }

  componentDidMount() {
    let tab = localStorage.getItem('customer_shop_orders_default_tab');
    if (tab === null) {
      tab = 'taobao';
      localStorage.setItem('customer_shop_orders_default_tab', tab);
    }
    this.setState({tab: tab});
  }

  handleTabChange = key => {
    localStorage.setItem('customer_shop_orders_default_tab', key);
    this.setState({tab: key})
  }

  render() {
    const tabs = [
      {
        key: 'taobao',
        tab: '淘宝商品关联设置'
      },
      {
        key: 'tianmao',
        tab: '天猫商品关联设置'
      },
      {
        key: 'jingdong',
        tab: '京东商品关联设置'
      }
    ];

    const contentList = {
      taobao: <TaobaoMappings/>,
      pinduoduo: '待开发',
      tianmao: <TianmaoMappings/>,
      jingdong: <JingdongMappings/>,
    };

    return (
      <Card activeTabKey={this.state.tab} tabList={tabs} onTabChange={this.handleTabChange}>
        {contentList[this.state.tab]}
      </Card>
    );
  }
}

export default CustomerShopOrders;