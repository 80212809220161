import React from 'react';
import { Button, Form, Input, message, Modal, Select, Space, Spin } from 'antd';
import axios from '../../request/axios';
import ModelConstants from '../../constants/ModelConstants';

class EditTianmaoSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      form_submitting: false
    }
  }

  openModal = () => {
    if (this.formRef)
      this.formRef && this.formRef.resetFields();

    this.setState({visible: true, loading: true});
    axios.get('customer-shops/settings/' + ModelConstants.CUSTOMER_SHOP_TYPE.TIANMAO).then(res => {
      const {data} = res.data;
      this.formRef && this.formRef.setFieldsValue(data);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    axios.post('customer-shops/update-tianmao-settings', values).then(() => {
      message.success('保存成功');
      this.closeModal();
    }).finally(() => {
      this.setState({form_submitting: false});
    });
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  render() {
    const footer = <Space>
      <Button onClick={this.closeModal}>取消</Button>
      <Button type='primary' onClick={() => this.formRef.submit()} loading={this.state.form_submitting}>保存</Button>
    </Space>;

    return <>
      <Modal
        title={this.state.is_creation_mode ? '添加天猫店铺' : '编辑天猫店铺'}
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={400}
        keyboard
        zIndex={1001}
      >
        <div className='mt-4'>
          <Spin spinning={this.state.loading}>
            <Form ref={ref => this.formRef = ref} layout='vertical'
                  onFinish={this.onFinish} loading={this.state.form_submitting} requiredMark={false}>
              <Form.Item
                name='buyer_nick_black_list'
                label='买家昵称黑名单'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input.TextArea maxLength={1500} showCount autoSize={{minRows: 7, maxRows: 7}}/>
              </Form.Item>

              <Form.Item
                name='buyer_ip_black_list'
                label='买家IP黑名单'
                style={{marginTop: 20}}
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input.TextArea maxLength={1500} showCount autoSize={{minRows: 7, maxRows: 7}}/>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  }
}

export default EditTianmaoSettingsModal;