import React from 'react';
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  message as antMessage,
  Select,
  Skeleton,
  Space,
  Typography, Table
} from 'antd';
import axios from '../../request/axios';
import {Steps} from 'zent';
import 'zent/css/steps.css';
import ProductTypeTag from '../../components/ProductTypeTag';
import Money from '../../components/Money';
import QuantityInput from './components/QuantityInput';
import PredefinedTemplateFields from './components/PredefinedTemplateFields';
import UserDefinedTemplateFields from './components/UserDefinedTemplateFields';
import YouyuUtils from '../../utils/YouyuUtils';
import ModelConstants from '../../constants/ModelConstants';
import ChargeAccountsImportModal from "./components/BatchSubmitResultModal";

const {Text, Link} = Typography;

class SubmitOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: this.props.match.params.product_id,
      product: null,
      total_price: null,
      total_face_value: null,
      security_rules_settings: null,
      charge_accounts_imported_result: null,
      show_confirm_pay: false,
      form_submitting: false
    }
  }

  componentDidMount() {
    // 加载商品
    if (this.state.product_id) {
      axios.get('products/' + this.state.product_id).then((res) => {
        const {data} = res.data;
        this.setState({
          product: data
        }, this.initForm)
      });
    }

    // 加载安全规则
    axios.get('/security/security-rules-settings').then(res => {
      const {data} = res.data;
      this.setState({security_rules_settings: data});
    })
  }

  initForm = () => {
    const defaultQuantity = YouyuUtils.parsePurchaseQuantityRule(this.state.product.purchase_quantity_rule)[0];
    this.formRef && this.formRef.setFieldsValue({quantity: defaultQuantity});
    this.setState({show_confirm_pay: false}, this.computeTotal);
  }

  validateForm = (callback) => {
    this.formRef.validateFields().then(() => {
      if (this.chargeParamsFormRef) {
        this.chargeParamsFormRef.validateFields().then(() => {
          callback();
        })
      } else {
        callback();
      }
    })
  }

  showConfirmPay = () => {
    this.validateForm(() => {
      this.setState({show_confirm_pay: true}, () => {
        this.dealPasswordInput && this.dealPasswordInput.focus();
      });
    })
  }

  handleImportedChargeAccounts = (result) => {
    this.setState({
      charge_accounts_imported_result: result
    }, this.computeTotal)
  }

  submitOrder = () => {
    this.validateForm(() => {
      let values = this.formRef.getFieldsValue();
      values.deal_password = document.getElementById('deal_password')?.value;

      if (this.chargeParamsFormRef) {
        let charge_params = this.chargeParamsFormRef.getFieldsValue();
        values = {...values, charge_params: {...charge_params}};
        this.doSubmit(values);
      } else {
        this.doSubmit(values);
      }
    })
  }

  doSubmit = (params) => {
    const {charge_accounts_imported_result} = this.state;
    const is_batch = charge_accounts_imported_result != null;

    // 防重复提交
    if (this.state.form_submitting) {
      return;
    }
    this.setState({form_submitting: true}, () => {
      const url = charge_accounts_imported_result ? 'purchase/batch-submit-order' : 'purchase/submit-order';

      if (is_batch) {
        params.charge_accounts = charge_accounts_imported_result.charge_accounts;
      }

      axios.post(url, params).then((res) => {
        const {code, data, message} = res.data;
        if (code === 202) {
          Modal.confirm({
            title: '购买失败',
            content: <div>
              <div>余额不足，是否要充值？</div>
              {YouyuUtils.confirmModalDefaultProps.content}</div>,
            okText: '前往充值',
            onOk: () => {
              this.props.history.push('/charge-balance');
            }
          });
        } else if (code) {
          const title = is_batch ? '发生未知错误，请检查订单列表查看已提交订单，防止重复提交' : '购买失败';
          Modal.error({
            title: title,
            content: <div>
              <div>{is_batch ? null : message}</div>
              {YouyuUtils.confirmModalDefaultProps.content}</div>
          });
        } else {
          if (is_batch) {
            this.chargeAccountsImportModal.openModal(data);
          } else {
            antMessage.success(message ? message : '下单成功');
            this.backToCategory(data.order_id);
          }
        }
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    });
  }

  backToCategory = (order_id = null) => {
    this.props.history.push({
      pathname: '/purchase/categories/' + this.state.product.category_id,
      query: {view_order_id: order_id}
    });
  }

  computeTotal = () => {
    const {product, charge_accounts_imported_result} = this.state;
    let total_face_value;
    let total_price;
    if (!product || !this.formRef) {
      total_face_value = null
      total_price = null;
    } else {
      const {face_value, price} = product;
      const quantity = this.formRef.getFieldValue('quantity');

      if (charge_accounts_imported_result) {
        if (charge_accounts_imported_result.same_quantity) {
          if (!quantity || quantity === '') {
            total_face_value = null;
            total_price = null;
          } else {
            total_face_value = face_value * charge_accounts_imported_result.charge_accounts_total * quantity;
            total_price = price * charge_accounts_imported_result.charge_accounts_total * quantity;
          }
        } else {
          total_face_value = face_value * charge_accounts_imported_result.quantity_total;
          total_price = price * charge_accounts_imported_result.quantity_total;
        }
      } else {
        if (!quantity || quantity === '') {
          total_face_value = null;
          total_price = null;
        }
        total_face_value = face_value * quantity;
        total_price = price * quantity;
      }
    }
    this.setState({total_face_value, total_price});
  }


  render() {
    const {product, security_rules_settings, show_confirm_pay, total_face_value, total_price} = this.state;

    return <Card title='我要采购' className='narrow'>
      <Steps current={1} type='breadcrumb'>
        <Steps.Step title='填写表单'/>
        <Steps.Step title='确认支付'/>
        <Steps.Step title='等待发货'/>
        <Steps.Step title='交易完成'/>
      </Steps>
      {
        product ?
          <div className={(show_confirm_pay ? 'shadow-lg' : 'shadow-lg') + ' border px-8 py-4 mx-auto my-4'}
               style={{width: 650}}>
            <Form layout='vertical' className='mt-4 form-compact' requiredMark={false}>
              <Form.Item
                label='基本信息'
              >
                <Descriptions column={2} bordered size='small' labelStyle={{width: 120}}>
                  <Descriptions.Item label='商品' span={2}><ProductTypeTag
                    value={product.type}/> {product.name} ({product.id})</Descriptions.Item>
                  <Descriptions.Item label='售价(元)'><Money value={product.price}/></Descriptions.Item>
                  <Descriptions.Item
                    label='面值(元)'><Text>{parseFloat(product.face_value)}</Text> &nbsp;
                  </Descriptions.Item>
                  <Descriptions.Item label='购买数量限制'
                                     span={2}>{product.purchase_quantity_rule}</Descriptions.Item>
                  {
                      product.url_for_card_usage &&
                      <Descriptions.Item label='充值网址' span={2}>
                        <a target='_blank' href={product.url_for_card_usage}>{product.url_for_card_usage}</a>
                      </Descriptions.Item>
                  }
                  <Descriptions.Item label='注意事项'>
                    <div style={{maxHeight: 100, overflowY: 'auto'}}>
                      <Text>{product.description === '' ? '-' : product.description}</Text>
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </Form.Item>
            </Form>
            {
              product.params_template_type === ModelConstants.PRODUCT_PARAMS_TEMPLATE_TYPE.USER_DEFINED &&
              <UserDefinedTemplateFields disabled={show_confirm_pay}
                                         ref={ref => this.chargeParamsFormRef = ref}
                                         onImportedChargeAccounts={this.handleImportedChargeAccounts}
                                         template={product.params_template}/>
            }
            {
              product.params_template_type === ModelConstants.PRODUCT_PARAMS_TEMPLATE_TYPE.PREDEFINED &&
              <PredefinedTemplateFields disabled={show_confirm_pay}
                                        ref={ref => this.chargeParamsFormRef = ref}
                                        onImportedChargeAccounts={this.handleImportedChargeAccounts}
                                        template={product.params_template}/>
            }

            <Form ref={ref => this.formRef = ref} onFinish={this.submitOrder} layout='vertical'
                  className='form-compact' requiredMark={false}>
              <Form.Item name='product_id' hidden initialValue={this.state.product_id}>
                <Input/>
              </Form.Item>
              {
                this.renderQuantityInputFormItem()
              }
              {/*<Form.Item*/}
              {/*  label='来源标记'*/}
              {/*  name='source_flag'*/}
              {/*>*/}
              {/*  <Input style={{width: 200}}/>*/}
              {/*</Form.Item>*/}
            </Form>
            <Divider/>
            <div className='flex justify-between'>
              <div>
                <Text>总面值：</Text>
                {
                  total_face_value ?
                    <Money small_decimal style={{fontSize: 20}}
                           value={total_face_value}
                           prefix={<span style={{fontSize: '0.8em'}}>￥</span>}/>
                    :
                    <Text type='secondary'>-</Text>
                }
                <Text>&nbsp;&nbsp;&nbsp;&nbsp;需支付：</Text>
                {
                  total_price ?
                    <Money small_decimal style={{fontSize: 20}}
                           value={total_price}
                           prefix={<span style={{fontSize: '0.8em'}}>￥</span>}/>
                    :
                    <Text type='secondary'>-</Text>
                }
              </div>
              <div className='text-right'>
                {
                  show_confirm_pay && <Space>
                    {
                      security_rules_settings.use_deal_password_when_purchase &&
                      <Input.Password id='deal_password' ref={ref => this.dealPasswordInput = ref}
                                      placeholder='输入交易密码'
                                      style={{width: 130}}
                                      onPressEnter={() => this.formRef.submit()}/>
                    }
                    <Button type='primary' danger loading={this.state.form_submitting}
                            disabled={this.state.form_submitting}
                            onClick={() => this.formRef.submit()}>确认支付</Button>
                  </Space>
                }
                {
                  !show_confirm_pay && <Space>
                    <Button onClick={() => this.props.history.go(-1)}>
                      返回
                    </Button>
                    <Button type='primary' onClick={this.showConfirmPay}>
                      立即购买
                    </Button>
                  </Space>
                }
                {
                  show_confirm_pay && <div className='text-right mt-3'>
                    <Alert type='warning' message={<Text>请认真核对订单详情，避免错充 <Link
                      onClick={() => this.setState({show_confirm_pay: false})}>重新填写</Link></Text>}/>
                  </div>
                }
              </div>
            </div>
          </div>
          :
          <div style={{width: 800, margin: '24px auto', minHeight: 400}}>
            <Skeleton active paragraph={{rows: 8}}/>
          </div>
      }
      <ChargeAccountsImportModal ref={ref => this.chargeAccountsImportModal = ref}
                                 onClose={() => this.backToCategory()}/>
    </Card>
  }

  renderQuantityInputFormItem = () => {
    const {product, charge_accounts_imported_result, show_confirm_pay} = this.state;

    if (!product) {
      return null;
    }

    if (charge_accounts_imported_result && !charge_accounts_imported_result.same_quantity) {
      return <Form.Item
        label='购买数量'
      >
        <div className='px-2.5 py-1 border flex justify-between'
             style={{backgroundColor: '#e5e5e560', width: 200}}>
          <Text type='secondary'>合计：{charge_accounts_imported_result.quantity_total}</Text>
        </div>
      </Form.Item>
    }

    return product.purchase_quantity_input_mode === '2' ?
      <Form.Item
        label='购买数量'
        name='quantity'
        rules={[
          {
            required: true,
            message: '请输入购买数量'
          }
        ]}
      >
        <Select disabled={show_confirm_pay} style={{width: 80}} onChange={() => this.computeTotal()}
                options={YouyuUtils.parsePurchaseQuantitySelectOptions(product.purchase_quantity_rule)}/>
      </Form.Item>
      :
      <Form.Item
        label={<Text>购买数量<Text type='secondary'>（按<Text keyboard>↑</Text><Text
          keyboard>↓</Text>快速调整数量）</Text></Text>}
        name='quantity'
        rules={[
          {
            required: true,
            message: '请输入购买数量'
          }
        ]}
      >
        <QuantityInput disabled={show_confirm_pay} onChange={() => this.computeTotal()}
                       rule={product.purchase_quantity_rule}/>
      </Form.Item>
  }
}

export default SubmitOrder;
