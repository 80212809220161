import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Button, Descriptions, Modal, Skeleton, Space, Table, Tag, Tooltip, Typography} from 'antd';
import {CheckOutlined, LoadingOutlined} from '@ant-design/icons';
import axios from '../../request/axios';
import ProductTypeTag from '../../components/ProductTypeTag';
import Money from '../../components/Money';
import moment from 'moment';
import ModelConstants from '../../constants/ModelConstants';
import OrderStatusStyle from './components/OrderStatusStyle';
import OrderStatusTitle from '../../components/OrderStatusTitle';
import YouyuUtils from '../../utils/YouyuUtils';
import OrderRefundRecordsModal from './OrderRefundRecordsModal';
import ApplyOrderComplaintModal from '../order-complaint/ApplyOrderComplaintModal';
import userAuth from '../../auth/UserAuth';

const {Paragraph, Text, Link} = Typography;
const {Item} = Descriptions;

class OrderDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  openModal = (id = null) => {
    this.setState({
      id,
      order: null,
      time_spent_desc: '-',
      visible: true,
      all_cards_copied: false
    }, this.loadData);
  }

  closeModal = () => {
    this.stopDetectStatus();
    this.stopTimer();
    this.setState({visible: false});
    this.props.onClose?.();
  }

  loadData = () => {
    axios.get('orders/' + this.state.id).then((res) => {
      const {data} = res.data;
      const order = data;
      this.setState({order}, () => {
        if (order.status === ModelConstants.ORDER_STATUS.DEALING || order.status === ModelConstants.ORDER_STATUS.UNCONFIRMED) {
          // 监控订单状态
          this.detectStatus();
          // 订单处理耗时timer
          this.startTimer();
        } else if (order.finished_at) {
          this.setState({time_spent_desc: this.computeTimeDiff(moment(order.created_at), moment(order.finished_at))});
        }
      });
    });
  }

  detectStatus = () => {
    if (!this.detect_status_interval_id) {
      this.detect_status_interval_id = setInterval(() => {
        axios.get('orders/' + this.state.id + '/status').then((res) => {
          const {data} = res.data;
          if (data.status !== ModelConstants.ORDER_STATUS.DEALING && data.status !== ModelConstants.ORDER_STATUS.UNCONFIRMED) {
            this.stopDetectStatus();
            this.stopTimer();
            this.loadData();
          }
        });
      }, 10 * 1000);
    }
  }

  stopDetectStatus = () => {
    clearInterval(this.detect_status_interval_id);
    this.detect_status_interval_id = null;
  }

  startTimer = () => {
    if (!this.timer_interval_id) {
      this.setState({time_spent_desc: this.computeTimeDiff(moment(this.state.order.created_at), moment())});
      this.timer_interval_id = setInterval(() => {
        this.setState({time_spent_desc: this.computeTimeDiff(moment(this.state.order.created_at), moment())});
      }, 1000);
    }
  }

  stopTimer = () => {
    clearInterval(this.timer_interval_id);
    this.timer_interval_id = null;
  }

  handleOrderChanged = () => {
    this.loadData();
    this.props.onChange?.(this.state.id);
  }

  handleCopyAllCards = () => {
    let text = '';
    this.state.order.cards.forEach(card => {
      if (card.type === '1')
        text += card.card_no + ' ';
      text += card.card_password + '\n';
    })
    YouyuUtils.copyTextToClipboard(text);
    this.setState({all_cards_copied: true})
  }

  render() {
    const {order} = this.state;

    const footer = <Space>
      <Button onClick={this.closeModal}>关闭</Button>
      <RouterLink to={'/purchase/submit-order/' + order?.product_id}><Button>再买一单</Button></RouterLink>
      {
        order && (
          (userAuth.hasPermission('order_complaints') && order.has_complaint === '0')
            ? <Button type='primary' onClick={() => this.applyOrderComplaintModal.openModal(order)}>申请售后</Button>
            : <Button type='primary' onClick={() => alert('无操作权限')}>查看售后</Button>
        )
      }
    </Space>

    const descLabelStyle = {width: 150, textAlign: 'center'};
    return <>
      <Modal
        title={'订单详细 - ' + this.state.id}
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={800}
        keyboard
        bodyStyle={{height: 600, overflowY: 'auto'}}
      >
        {
          order ?
            <div>
              <Text strong>订单状态</Text>
              <Descriptions className='mt-2 mb-3' column={2} bordered size='small' labelStyle={descLabelStyle}>
                <Item label='订单号' contentStyle={{width: 225}}><Text copyable>{order.id}</Text></Item>
                <Item label='订单状态' contentStyle={OrderStatusStyle.statusWrapperStyle(order.status)}><span
                  style={OrderStatusStyle.statusTitleStyle(order.status)}><OrderStatusTitle
                  status={order.status}/></span></Item>
                <Item label='提交时间'>{moment(order.created_at).format('Y-MM-DD HH:mm:ss')}</Item>
                <Item label='完成时间'>{order.finished_at ? moment(order.finished_at).format('Y-MM-DD HH:mm:ss') :
                  <div style={{width: 120}}>-</div>}</Item>
                <Item label='处理耗时'>
                  <Text code>{order.finished_at ? null :
                    <LoadingOutlined style={{fontSize: 10, marginRight: 3}}/>}{this.state.time_spent_desc}</Text>
                </Item>
                <Item label='退款记录'>{parseFloat(order.refund_amount) === 0 ? <Text>无退款记录</Text> :
                  <Link onClick={() => this.orderRefundRecordsModal.openModal(order.id)}>有退款记录</Link>}</Item>
                {
                  order.status_info &&
                  <Item label='状态信息' span={2}>{order.status_info}</Item>
                }
              </Descriptions>

              <Text strong>购买内容</Text>
              <Descriptions className='mt-2 mb-2' column={2} bordered size='small' labelStyle={descLabelStyle}>
                <Item label='商品' span={2}><ProductTypeTag
                  value={order.product_type}/> {order.product_name} ({order.product_id})</Item>
                <Item label='单价|数量|总价' contentStyle={{width: 225}}>
                  <Money value={order.product_price} postfix=' | '/>
                  <Money remove_float value={order.quantity} postfix=' | '/>
                  <Money value={order.total_price}/>
                </Item>
                <Item label='客户区域'>
                  {
                    (!order.end_customer_ip && !order.end_customer_region)
                      ? '-'
                      :
                      <Text code>{order.end_customer_ip} {order.end_customer_region}</Text>
                  }
                </Item>
                <Item label='下单IP'>
                  {
                    (!order.client_ip && !order.client_region)
                      ? '-'
                      :
                      <Text code>{order.client_ip} {order.client_region}</Text>
                  }
                </Item>
                {
                  order.url_for_card_usage &&
                    <Item label='充值网址' span={2}>
                      <a target='_blank' href={order.url_for_card_usage}>{order.url_for_card_usage}</a>
                    </Item>
                }
              </Descriptions>

              {
                order.product_type === ModelConstants.PRODUCT_TYPE.CHARGE && order.charge_params && <>
                  <Text strong>充值信息</Text>
                  <Descriptions className='mt-2 mb-3' column={2} bordered size='small' labelStyle={descLabelStyle}>

                    {
                      order.charge_params.map((item, index) => {
                        return <Item key={index} span={(item.value?.length) > 15 ? 2 : 1}
                                     label={item.name}>
                          <Text
                            copyable={item.key === 'charge_account'}>{(!item.value_desc || item.value_desc === '') ? item.value : item.value_desc}</Text></Item>
                      })
                    }
                  </Descriptions>
                </>
              }

              {
                userAuth.hasPermission('orders.view_cards')
                && order.product_type === ModelConstants.PRODUCT_TYPE.CARD
                && order.status === ModelConstants.ORDER_STATUS.SUCCESS
                && order.cards && order.cards.length > 0 && <>
                  <Text strong>卡密信息</Text>
                  <Table
                    dataSource={order.cards}
                    bordered
                    className='mt-2 mb-3'
                    size='small'
                    title={() => <div className='flex justify-between'>
                      <Text>共计{order.cards.length}张卡密</Text>
                      <Link onClick={this.handleCopyAllCards}>{this.state.all_cards_copied ? <span><CheckOutlined
                        className='ant-typography ant-typography-success'/> 已复制</span> : '全部复制'}</Link>
                    </div>}
                    pagination={order.cards.length > 100 ? {defaultPageSize: 100} : false}
                  >
                    {
                      order.cards[0].type === '1' &&
                      <Table.Column title={order.cards[0].card_no_alias ? order.cards[0].card_no_alias : '卡号'}
                                    dataIndex='card_no' key='card_no' align='center'
                                    render={(value, record) => <Text copyable>{value}</Text>}/>
                    }
                    <Table.Column title={order.cards[0].card_password_alias ? order.cards[0].card_password_alias : '密码'}
                                  dataIndex='card_password' key='card_password' align='center'
                                  render={(value, record) => <Paragraph style={{margin: 0}}
                                                                        copyable>{value}</Paragraph>}/>
                  </Table>
                </>
              }
            </div>
            :
            <div style={{width: 650, margin: '24px auto 0', height: 500}}><Skeleton active paragraph={{rows: 14}}/>
            </div>
        }
        <OrderRefundRecordsModal ref={ref => this.orderRefundRecordsModal = ref}/>
        <ApplyOrderComplaintModal onChange={this.handleOrderChanged} ref={ref => this.applyOrderComplaintModal = ref}/>
      </Modal>
    </>
  }

  computeTimeDiff = (start, end) => {
    let
      days = end.diff(start, 'days');
    let
      hours = end.diff(start, 'hours') % 24;
    let
      mins = end.diff(start, 'minutes') % 60;
    let
      seconds = end.diff(start, 'seconds') % 60;

    let
      desc = '';

    if (days

      !==
      0
    ) {
      desc = days + '天' + hours + '小时' + mins + '分钟';
    } else if (hours !== 0) {
      desc = hours + '小时' + mins + '分钟' + seconds + '秒';
    } else if (mins !== 0) {
      desc = mins + '分钟' + seconds + '秒';
    } else if (seconds !== 0) {
      desc = seconds + '秒';
    } else {
      desc = end.diff(start, 'b') + '毫秒';
    }
    return desc;
  }
}

export default OrderDetailsModal;