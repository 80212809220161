import React from 'react';
import {Form, Input, Typography} from 'antd';
import RadioTags from '../../../components/RadioTags';
import ChargeAccountFormItem from "./ChargeAccountFormItem";
const {Text, Link} = Typography;

class UserDefinedTemplateFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getFieldsValue = () => {
        return this.formRef.getFieldsValue();
    }

    validateFields = () => {
        return this.formRef.validateFields();
    }

    resetFields = () => {
        this.formRef && this.formRef.resetFields();
    }

    render() {
        let {template} = this.props;
        const props = {disabled: this.props.disabled};

        return <Form ref={form => this.formRef = form} layout='vertical'
                     className='form-compact' requiredMark={false}>
            {
                template.map((item, index) => {
                    if (item.type === 'charge_account') {
                        return this.chargeAccountFormItem(item.name, item.comment);
                    } else if (item.type === 'text') {
                        return <Form.Item
                            key={index}
                            label={item.name}
                            name={(!item.key || item.key === '') ? item.name : item.key}
                            extra={item.comment}
                            rules={[{required: true}]}
                        >
                            <Input {...props} style={{width: 200}}/>
                        </Form.Item>
                    } else if (item.type === 'password') {
                        return <Form.Item
                            key={index}
                            label={item.name}
                            name={(!item.key || item.key === '') ? item.name : item.key}
                            extra={item.comment}
                            rules={[{required: true}]}
                        >
                            <Input.Password {...props} style={{width: 200}}/>
                        </Form.Item>
                    } else if (item.type === 'select') {
                        return <Form.Item
                            key={index}
                            label={item.name}
                            name={(!item.key || item.key === '') ? item.name : item.key}
                            extra={item.comment}
                            rules={[{required: true}]}
                        >
                            <RadioTags options={this.parseOptions(item.options)}/>
                        </Form.Item>
                    } else {
                        return <div key={index}/>
                    }
                })
            }
        </Form>
    }

    parseOptions = (options) => {
        let optionsParsed = [];
        options.forEach(item => {
            optionsParsed.push({
                label: item,
                value: item
            })
        })
        return optionsParsed;
    }

    chargeAccountFormItem = (label, comment) => {
        return <ChargeAccountFormItem
            label={label}
            extra={comment}
            onImportedChargeAccounts={this.props.onImportedChargeAccounts}
            disabled={this.props.disabled}/>
    }
}

export default UserDefinedTemplateFields;