import React from 'react';
import { Button, Card, Empty, message as antMessage, Modal, Space, Spin, Tooltip, Typography } from 'antd';
import { ApiOutlined, LoadingOutlined, LockOutlined, SettingOutlined } from '@ant-design/icons';
import axios from '../../request/axios';
import CreateShopModal from './CreateShopModal';
import ModelConstants from '../../constants/ModelConstants';
import EditTianmaoShopModal from './EditTianmaoShopModal';
import EditTianmaoSettingsModal from './EditTianmaoSettingsModal';
import YouyuUtils from '../../utils/YouyuUtils';
import EditJingdongShopModal from './EditJingdongShopModal';
import shopLogo from './components/ShopLogo';

const {Link, Paragraph, Text} = Typography;

class CustomerShops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loading_customer: true,
      customer: [],
      shops: [],
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true
    });

    this.setState({loading_customer: true});
    axios.get('customer-shops/customer-statistics?customer_id=' + this.state.customer_id).then(res => {
      const {data} = res.data;
      this.setState({customer: data, loading_customer: false});
    });

    axios.get('customer-shops').then((res) => {
      const {data} = res.data;
      let newState = {
        loading: false,
        shops: data
      };
      this.setState(newState);
    });
  }

  deleteShop = (id) => {
    const self = this;
    Modal.confirm({
      title: '确认解除授权并删除店铺吗？',
      ...YouyuUtils.confirmModalDefaultProps,
      onOk() {
        self.setState({loading: true});
        axios.delete('customer-shops/' + id).then(res => {
          const {code, message} = res.data;
          if (!code) {
            antMessage.success('解除授权成功');
          } else {
            antMessage.error(message);
          }
        }).finally(() => {
          self.loadData();
        });
      }
    });
  }

  render() {
    const cardStyle = {padding: 10, width: 150, textAlign: 'center'};
    const imgStyle = {width: 120, margin: '0 auto'};
    const titleClass = 'mt-1 px-1';
    const {customer} = this.state;

    return <Card title='网店管理' style={{minHeight: 500}}>
      <div className='flex justify-between'>
        <Space>
          <Button type='primary' onClick={() => this.createShopModal.openModal()}>添加店铺</Button>
          <Button onClick={this.loadData}>刷新</Button>
        </Space>
        <Space>
          <Text>
            授权淘宝店：
            {
              !this.state.loading_customer && customer
                ? <Text>{customer.taobao_shops}/{customer.max_taobao_shops}</Text>
                : <LoadingOutlined/>
            }
          </Text>
          <Text>
            授权天猫店：
            {
              !this.state.loading_customer && customer
                ? <Text>{customer.tianmao_shops}/{customer.max_tianmao_shops}</Text>
                : <LoadingOutlined/>
            }
          </Text>
          <Text>
            授权京东店：
            {
              !this.state.loading_customer && customer
                ? <Text>{customer.jingdong_shops}/{customer.max_jingdong_shops}</Text>
                : <LoadingOutlined/>
            }
          </Text>
        </Space>
      </div>
      <div className='mt-5'>
        <Spin spinning={this.state.loading}>
          {
            this.state.shops.length === 0 && <Empty/>
          }
          {
            this.state.shops.length !== 0 &&
            <div className='p-6 border border-dashed dash text-center'><Space size={15} wrap>
              {
                this.state.shops.map(item => {
                  let img_url = '';
                  let actions = [];
                  const deleteShopButton = <Tooltip title='解除授权'><ApiOutlined
                    onClick={this.deleteShop.bind(this, item.id)}/></Tooltip>;
                  if (item.type === ModelConstants.CUSTOMER_SHOP_TYPE.TAOBAO) {
                    img_url = shopLogo.taobao;
                    actions = [
                      <Tooltip title='重新授权'><LockOutlined/></Tooltip>,
                      deleteShopButton
                    ];
                  } else if (item.type === ModelConstants.CUSTOMER_SHOP_TYPE.PINDUODUO) {
                    img_url = shopLogo.pinduoduo;
                    actions = [
                      <Tooltip title='重新授权'><LockOutlined/></Tooltip>,
                      deleteShopButton
                    ];
                  } else if (item.type === ModelConstants.CUSTOMER_SHOP_TYPE.TIANMAO) {
                    img_url = shopLogo.tianmao;
                    actions = [
                      <Tooltip title='基本资料'><SettingOutlined
                        onClick={() => this.editTianmaoShopModal.openModal(item.id)}/></Tooltip>,
                      deleteShopButton
                    ]
                  } else if (item.type === ModelConstants.CUSTOMER_SHOP_TYPE.JINGDONG) {
                    img_url = shopLogo.jingdong;
                    actions = [
                      <Tooltip title='基本资料'><SettingOutlined
                        onClick={() => this.editJingdongShopModal.openModal(item.id)}/></Tooltip>,
                      deleteShopButton
                    ]
                  }
                  return <Card key={item.id} bodyStyle={cardStyle} actions={actions}>
                    <img style={imgStyle} src={img_url} alt='店铺logo'/>
                    <div className={titleClass}><Text ellipsis={{tooltip: item.name}}>{item.name}</Text></div>
                  </Card>
                })
              }</Space></div>
          }
        </Spin>
      </div>
      <CreateShopModal ref={ref => this.createShopModal = ref} onChange={this.loadData}/>
      <EditTianmaoShopModal ref={ref => this.editTianmaoShopModal = ref} onChange={this.loadData}/>
      <EditTianmaoSettingsModal ref={ref => this.editTianmaoSettingsModal = ref} onChange={this.loadData}/>
      <EditJingdongShopModal ref={ref => this.editJingdongShopModal = ref} onChange={this.loadData}/>
    </Card>
  }
}

export default CustomerShops;