import React from 'react';
import { Select } from 'antd';
import axios from '../../../request/axios';

class CustomerShopSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false
    }
  }

  handleSelect = key => {
    this.props.onChange?.(key);
  }

  componentDidMount() {
    this.setState({loading: true});
    axios.get('customer-shops/select-options-data', {params: {type: this.props.shop_type}}).then((res) => {
      const {data} = res.data;
      const options = data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      this.setState({
        loading: false,
        options: options
      });
    });
  }

  render() {
    return <Select onSelect={this.handleSelect} allowClear
                   style={this.props.style} placeholder={this.props.placeholder} options={this.state.options}
                   showSearch/>
  }
}

CustomerShopSelector.defaultProps = {
  type: null
}

export default CustomerShopSelector;