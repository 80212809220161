import React from 'react';
import emptyImg from '../asserts/imgs/empty_img.png';

class ProductImg extends React.Component {
  render() {
    let img_url = this.props.img_url;
    if (!img_url)
      img_url = emptyImg;

    return <img style={{width: 45, height: 45, marginLeft: 'auto', marginRight: 'auto', border: '1px solid #eee', ...this.props.style}}
                src={img_url} alt=''/>
  }
}

export default ProductImg;