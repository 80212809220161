import {Button, Form, message as antMessage, Input, Modal, Space, Spin, Typography, Alert, List, Row, Col} from 'antd';
import {DownOutlined, LoadingOutlined} from '@ant-design/icons';
import React from 'react';
import QRCode from 'qrcode.react';
import axios from '../../request/axios';

const {Text} = Typography;

class BindSecretCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            has_bind: false,
            secret: null,
            form_submitting: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({loading: true});
        axios.get('security/secret/get').then(res => {
            const {data} = res.data;
            this.setState({has_bind: data.has_bind, secret: data.secret});
            this.formRef && this.formRef.setFieldsValue(res.data.data);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    onFinish = (values) => {
        this.setState({form_submitting: true});
        // values.secret = this.state.secret;
        axios.post('security/secret/bind', values).then(res => {
            const {code, message, data} = res.data;
            if (!code) {
                antMessage.success('绑定成功');
                this.loadData();
            } else {
                antMessage.error(message);
            }
        }).finally(() => {
            this.setState({form_submitting: false});
        });
    };

    render() {
        return (
            <Spin spinning={this.state.loading}>
                {!this.state.has_bind &&
                    <Form ref={ref => this.formRef = ref} labelCol={{span: 6}} wrapperCol={{span: 14}}
                          onFinish={this.onFinish}>
                        <div className='mb-4'>
                            <Alert type='info' message={
                                <Text>
                                    <div>使用步骤：</div>
                                    <ol>
                                        <li style={{listStyleType: 'decimal'}}>在应用市场中搜索”Google身份验证器“或”Google Authenticator“，下载安装；</li>
                                        <li style={{listStyleType: 'decimal'}}>打开APP，创建账号，选择”输入设置密钥“，输入”账号“（可以随意填写一个助记名称，如”有鱼网销售系统“），输入下方”密钥“（可使用电脑版微信复制该密钥至手机），最后”添加“即可；</li>
                                        <li style={{listStyleType: 'decimal'}}>在下方输入APP中显示的”动态码“，点击”确认绑定“。</li>
                                    </ol>
                                </Text>}/>
                        </div>

                        <Form.Item
                            label='密钥'
                            name='secret'
                        >
                            <Text type='danger' copyable={this.state.secret}>
                                {
                                    this.state.secret ?? <Text type='secondary'><LoadingOutlined/> 加载中...</Text>
                                }
                            </Text>
                        </Form.Item>

                        <Form.Item
                            label='动态码'
                            name='code'
                            rules={[
                                {required: true}
                            ]}
                        >
                            <Input style={{width: 200}}/>
                        </Form.Item>

                        <Form.Item wrapperCol={{
                            offset: 6,
                            span: 10
                        }}>
                            <Space>
                                <Button type='primary' htmlType='submit' loading={this.state.form_submitting}>
                                    确认绑定
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                }
                {
                    this.state.has_bind &&
                    <Space>您已绑定过身份验证器，如需解绑，请联系管理员</Space>
                }
            </Spin>
        );
    }
}

export default BindSecretCode;
