import React from 'react';
import { Button, Card, Result, Typography } from 'antd';

const {Text} = Typography;

class BatchOrders extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Card title='批量采购' className='narrow'>
      <Result
        status='403'
        title='权限未开通'
        subTitle={<Text>
          如有需要请向客服申请开通。
        </Text>}
        extra={<Button onClick={() => this.props.history.go(-1)} type='primary'>返回</Button>}
      />
    </Card>
  }
}

export default BatchOrders;