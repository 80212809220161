import React from 'react';
import { Typography } from 'antd';
import ModelConstants from '../../../constants/ModelConstants';

const {Text} = Typography;

const ApplyRefundDesc = (props) => {
  const {apply_refund_type, apply_part_refund_amount} = props;
  if (apply_refund_type === ModelConstants.APPLY_REFUND_TYPE.NO_REFUND)
    return <Text>不退款</Text>
  else if (apply_refund_type === ModelConstants.APPLY_REFUND_TYPE.WHOLE_REFUND)
    return <Text strong type='danger'>全部退款</Text>
  else if (apply_refund_type === ModelConstants.APPLY_REFUND_TYPE.PART_REFUND)
    return <Text strong type='danger'>部分退款：{parseFloat(apply_part_refund_amount)}元</Text>
  else
    return '-'
}
export default ApplyRefundDesc;