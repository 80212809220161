import React from 'react';
import { Space, Typography, message as antMessage, Alert } from 'antd';

import styles from './AuthLayout.module.less';
import Login from './Login';
import Register from './Register';
import userAuth from '../../auth/UserAuth';
import InfoModal from '../../components/InfoModal';

const {Text, Link} = Typography;

class AuthLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_submitting: false,
      tab_key: null,
      pathname: null
    }
  }

  componentDidMount() {
    document.querySelector('body').classList.add(styles.body);
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove(styles.body);
  }

  goInside = (url) => {
    if (!userAuth.token()) {
      antMessage.warn('请登录后操作');
    } else {
      this.props.history.push(url);
    }
  }

  render() {
    return <div className='h-screen flex flex-col justify-between'>
      <header style={{backgroundColor: '#fff'}}>
        <div style={{minWidth: 600, maxWidth: 1100, height: 80}} className='flex justify-between items-center mx-auto'>
          <a href='/' className='logo-link'><img style={{height: 55}} src={window.website_basic_info_settings.logo}
                                                 alt='logo'/></a>
          <nav>
            <Space size={16} className='mt-2'>
              <Link style={{fontSize: 15, color: 'rgba(0,0,0,.85)'}}
                    onClick={this.goInside.bind(this, '/dashboard')}>控制台</Link>
              {
                (window.website_basic_info_settings.kf != null && window.website_basic_info_settings.kf !== '') &&
                <Link style={{fontSize: 15, color: 'rgba(0,0,0,.85)'}}
                      onClick={() => this.kfModal.openModal()}>联系客服</Link>
              }
              <Link style={{fontSize: 15, color: 'rgba(0,0,0,.85)'}} href='/api-doc/' target='_blank'>开发文档</Link>
            </Space>
          </nav>
        </div>
        {
          window.operation_settings.login_page_alert && window.operation_settings.login_page_alert !== '' &&
          <Alert message={<div style={{textAlign: 'center'}}>{window.operation_settings.login_page_alert}</div>}
                 type='warning'/>
        }
      </header>
      {
        (this.props.location.pathname === '/' || this.props.location.pathname === '/login') && <Login/>
      }
      {
        this.props.location.pathname === '/register' && <Register/>
      }
      <footer className={styles.footer}>
        <Text type='secondary'>Copyright© 2021&nbsp;
          {(window.website_basic_info_settings.company && window.website_basic_info_settings.company !== '') ? window.website_basic_info_settings.company : window.website_basic_info_settings.title} 版权所有
          ALL RIGHTS RESERVED
          ·<Link type='secondary' href='http://beian.miit.gov.cn/'
                 target='_blank'>ICP备案:{window.website_basic_info_settings.icp}</Link>
        </Text>
      </footer>
      <InfoModal title='客服信息' content={window.website_basic_info_settings.kf}
                 ref={ref => this.kfModal = ref}/>
    </div>
  }
}

export default AuthLayout;