import React from 'react';
import { Button, Form, Input, message, Modal, Select, Space, Spin } from 'antd';
import axios from '../../request/axios';

class EditTianmaoShopModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      is_creation_mode: true,
      is_update_mode: false,
      visible: false,
      loading: false,
      form_submitting: false
    }
  }

  openModal = (id = null) => {
    if (this.formRef)
      this.formRef && this.formRef.resetFields();

    this.setState({id, is_creation_mode: id === null, is_update_mode: id !== null, visible: true}, () => {
      if (this.state.is_update_mode) {
        this.setState({loading: true});
        axios.get('customer-shops/' + id).then(res => {
          const {data} = res.data;
          this.formRef && this.formRef.setFieldsValue({...data, ...data.data});
        }).finally(() => {
          this.setState({loading: false});
        });
      }
    });
  }

  onFinish = (values) => {
    this.setState({form_submitting: true});
    if (this.state.is_creation_mode) {
      axios.post('customer-shops/store-tianmao-shop', values).then(() => {
        message.success('保存成功');
        this.props.onChange?.();
        this.closeModal();
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    } else if (this.state.is_update_mode) {
      values.id = this.state.id;
      axios.post('customer-shops/update-tianmao-shop', values).then(() => {
        message.success('保存成功');
        this.props.onChange?.();
        this.closeModal();
      }).finally(() => {
        this.setState({form_submitting: false});
      });
    }
  }

  closeModal = () => {
    this.setState({visible: false});
  }

  render() {
    const footer = <Space>
      <Button onClick={this.closeModal}>取消</Button>
      <Button type='primary' onClick={() => this.formRef.submit()} loading={this.state.form_submitting}>保存</Button>
    </Space>;

    return <>
      <Modal
        title={this.state.is_creation_mode ? '添加天猫店铺' : '编辑天猫店铺'}
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={400}
        keyboard
        zIndex={1001}
      >
        <div className='mt-4'>
          <Spin spinning={this.state.loading}>
            <Form className='form-compact' ref={ref => this.formRef = ref} labelCol={{span: 8}} wrapperCol={{span: 16}}
                  onFinish={this.onFinish} loading={this.state.form_submitting} requiredMark={false}>
              <Form.Item
                name='name'
                label='天猫店铺名称'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                name='coop_id'
                label='天猫合作商编号'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                name='app_key'
                label='天猫AppKey'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                name='secret'
                label='天猫Secret'
                rules={[
                  {
                    required: this.state.is_creation_mode
                  }
                ]}
              >
                <Input placeholder={this.state.is_creation_mode ? '' : '<敏感信息已隐藏>'}/>
              </Form.Item>

              <Form.Item
                name='seller_nick'
                label='卖家昵称'
                extra='可为空，用于识别买家昵称黑名单'
              >
                <Input/>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  }
}

export default EditTianmaoShopModal;