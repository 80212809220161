import React from 'react';
import {Button, Checkbox, Input, List, message, Modal, Space, Table, Typography} from 'antd';

const {Text, Link} = Typography;

class ChargeAccountsImportModal extends React.Component {

  STEP_INPUT = 1;
  STEP_CONFIRM = 2;

  initState = {
    step: this.STEP_INPUT,
    accounts_text: '',
    accounts: [],
    filter_repeated_account: true,
    same_quantity: null,
    repeated_count: 0,
    visible: false,
  }

  constructor(props) {
    super(props);
    this.state = this.initState;
  }

  goStepConfirm = () => {
    const filter_repeated_account = this.state.filter_repeated_account;
    const original_arr = this.state.accounts_text.split('\n');
    let raw_arr = [];
    original_arr.forEach(item => {
      if (item && item.trim() !== '') {
        raw_arr.push(item.trim());
      }
    })

    if (raw_arr.length === 0) {
      return message.error('请填写充值账号');
    }

    const split_string_arr = ['---', ' ', '\t']
    // 判断是否为统一购买数量
    // const same_quantity = raw_arr[0].indexOf('---') === -1;
    let same_quantity = true;
    for (let i = 0; i < split_string_arr.length; i++) {
      if (raw_arr[0].indexOf(split_string_arr[i]) !== -1) {
        same_quantity = false;
        break;
      }
    }
    if (same_quantity) {
      if(this.state.filter_repeated_account) {
        let set = [...new Set(raw_arr)];

        const accounts = set.map(item => {
          return {charge_account: item, quantity: null}
        });

        this.setState({
          repeated_count: raw_arr.length - set.length,
          same_quantity,
          accounts,
          step: this.STEP_CONFIRM
        });
      }
      else {
        const accounts = raw_arr.map(item => {
          return {charge_account: item, quantity: null}
        });
        this.setState({
          repeated_count: 0,
          same_quantity,
          accounts,
          step: this.STEP_CONFIRM
        });
      }
    } else {
      let accounts = [];

      // 检查格式是否正确
      for (let i = 0; i < raw_arr.length; ++i) {
        const item = raw_arr[i];

        // 是否正好分割为2个字符串
        // let arr = item.split('---');
        let arr = [];
        for (let j = 0; j < split_string_arr.length; j++) {
          arr = item.split(split_string_arr[j]);
          let new_arr = [];
          arr.forEach((value) => {
            if (value) {
              new_arr.push(value);
            }
          })
          arr = new_arr;
          if(arr.length === 2){
            break;
          }
        }

        if (arr.length !== 2) {
          return this.showFormatError(i);
        }

        let [account, quantity] = arr;
        account = account.trim();
        quantity = quantity.trim();

        // 账号格式是否正确
        if (!account || account === '') {
          return this.showFormatError(i);
        }

        // 数量格式是否正确
        if (!quantity || quantity === '' || isNaN(quantity) || quantity <= 0) {
          return this.showFormatError(i);
        }

        accounts.push({
          charge_account: account,
          quantity
        })
      }

      if(filter_repeated_account && this.hasDuplicateChargeAccount(accounts)) {
        alert('含有重复账号');
        return;
      }

      this.setState({
        repeated_count: 0, // todo
        same_quantity,
        accounts: accounts,
        step: this.STEP_CONFIRM
      });
    }
  }

  hasDuplicateChargeAccount = (arr) => {
    const seenAccounts = new Set();

    for (let i = 0; i < arr.length; i++) {
      const chargeAccount = arr[i].charge_account;

      if (seenAccounts.has(chargeAccount)) {
        return true; // 发现重复的 charge_account
      }

      seenAccounts.add(chargeAccount);
    }

    return false; // 没有重复的 charge_account
  }

  showFormatError = (index) => {
    message.error('第' + (index + 1) + '行格式错误');
  }

  confirmImport = () => {
    const {accounts, same_quantity} = this.state;
    const accounts_total = accounts.length;
    let quantity_total = 0;
    accounts.forEach(item => {
      if (item.quantity) {
        quantity_total += parseInt(item.quantity);
      }
    })

    this.props?.onImported({
      charge_accounts: accounts,
      charge_accounts_total: accounts_total,
      same_quantity,
      quantity_total
    });
    this.closeModal();
  }

  openModal = () => {
    this.setState({
      ...this.initState,
      visible: true
    });
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  render() {
    const {step, accounts, repeated_count, same_quantity} = this.state;
    const footer = <div className='flex justify-between'>
      {
        step === this.STEP_INPUT && <span></span>
      }
      {
        step === this.STEP_CONFIRM &&
        <Text>共计{accounts.length}个账号{repeated_count ? ('（已过滤' + repeated_count + '个重复账号）') : ''}</Text>
      }
      <Space>
        {
          step === this.STEP_INPUT && <Checkbox checked={this.state.filter_repeated_account} onChange={e => this.setState({filter_repeated_account: e.target.checked})}>过滤重复账号</Checkbox>
        }
        <Button onClick={this.closeModal}>取消</Button>
        {step === this.STEP_INPUT && <Button type='primary' onClick={() => this.goStepConfirm()}>下一步</Button>}
        {step === this.STEP_CONFIRM &&
          <Button type='primary' onClick={() => this.setState({step: this.STEP_INPUT})}>上一步</Button>}
        {step === this.STEP_CONFIRM &&
          <Button type='primary' onClick={() => this.confirmImport()}>确认导入</Button>}
      </Space></div>;

    const textareaPlaceholder =
      `相同数量导入格式：\n账号1\n账号2\n账号3\n\n` +
      `不同数量导入格式：\n账号1---购买数量\n账号2---购买数量\n账号3---购买数量\n账号4 空格 购买数量`;

    return <>
      <Modal
        title={'批量导入账号'}
        destroyOnClose
        footer={footer}
        visible={this.state.visible}
        onCancel={this.closeModal}
        width={600}
        keyboard
        zIndex={1000}
      >
        <div className='overflow-y-auto' style={{height: 370}}>
          {
            step === this.STEP_INPUT &&
            <Input.TextArea value={this.state.accounts_text}
                            onChange={e => this.setState({accounts_text: e.target.value})} rows={16}
                            placeholder={textareaPlaceholder}/>
          }
          {
            step === this.STEP_CONFIRM &&
            <Table rowKey='charge_account' dataSource={accounts} size='small' pagination={false}
                   showHeader={!same_quantity}>
              <Table.Column title='账号' dataIndex='charge_account' key='charge_account'></Table.Column>
              same_quantity &&
              <Table.Column title='购买数量' dataIndex='quantity' key='quantity'></Table.Column>
            </Table>
          }
        </div>
      </Modal>
    </>
  }
}

export default ChargeAccountsImportModal;