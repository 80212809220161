import React from 'react';
import axios from '../request/axios';
import { Cascader, Input, Select, Typography } from 'antd';
import ProductSelectorModal from './ProductSelectorModal';
import { LoadingOutlined } from '@ant-design/icons';

const {Text, Link} = Typography;

class ProductSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init_value_display: false,
      value: props.value,
      value_display: [],
      loading: false
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value && !this.state.init_value_display) {
      this.setState({init_value_display: true, loading: true});
      axios.get('products/' + nextProps.value).then(res => {
        const {data} = res.data;
        this.setState({
          value_display: data.name + '（' + data.id + '）',
          loading: false
        });
      });
    }
  }

  handleSelect = (product) => {
    const value_display = product.name + '（' + product.id + '）';
    this.setState({value_display, init_value_display: true}, () => this.props.onChange?.(product.id));
  }

  render() {
    return <>
      <Input readOnly value={this.state.value_display} style={this.props.style}
             suffix={this.state.loading ? <span><Text type='secondary'><LoadingOutlined/> 加载中</Text></span> : null}/>
      <div className='mt-1'>
        <Link onClick={() => this.productSelectorModal.openModal()}>选择商品</Link>
      </div>
      <ProductSelectorModal search_params={this.props.search_params} onSelect={this.handleSelect}
                            ref={ref => this.productSelectorModal = ref}/>
    </>
  }
}

export default ProductSelector;