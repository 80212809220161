import React from 'react';
import { Space } from 'antd';
import style from './RadioTags.module.css';

class RadioTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null
    }
  }

  handleSelect = (value) => {
    this.setState({value});
    this.props.onChange?.(value);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      value: nextProps.value
    });
  }

  render() {
    return <Space wrap>
      {
        this.props.options.map((item, index) => {
          return <div onClick={this.handleSelect.bind(this, item.value)} key={index}
                      className={style.item + (this.state.value === item.value ? (' ' + style.active) : '')}>
              <div className={style.item__label}>{item.label}</div>
          </div>
        })
      }
    </Space>
  }
}

export default RadioTags;