import React from 'react';
import { Button, Card, Result, Typography } from 'antd';

const {Text} = Typography;

class Error404 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Card title='页面没找到'>
      <Result
        status='500'
        title='页面没找到'
        subTitle={<Text>
          请刷新页面重试，如仍有此问题，请联系客服处理。
        </Text>}
        extra={<Button onClick={() => this.props.history.go(-1)} type='primary'>返回</Button>}
      />
    </Card>
  }
}

export default Error404;